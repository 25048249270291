import { useContext } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { PlanContext } from '../../components/common/context/PlanContext'
// comps
import ProgrammedWhatsappTable from '../../components/pages/communication/ProgrammedWhatsappTable'
import PanelDescriptionBox from '../../components/pages/communication/PanelDescriptionBox'
import WhatsappPreviewBox from '../../components/pages/communication/WhatsappPreviewBox'
import NoPackageView from '../../components/pages/communication/NoPackageView'

export default function Communication () {
    const { limits } = useContext(PlanContext)
    const whatsappEnabled = limits?.whatsapp_enabled
    return (
        <>
            {
                whatsappEnabled
                    ? <Grid
                    // p={["1em", "1em", "1em", "1em", "4em", "4em"]}
                    // w="100%"
                        width={{ base: '100%', md: '700px', lg: '800px', xl: '1080px', '2xl': '1300px' }}
                        h="85dvh"
                        templateRows="repeat(3, 1fr)"
                        templateColumns="repeat(2, 1fr)"
                        gap={6}
                        mt='1.5em'
                    >
                        <GridItem colSpan={1}>
                            <PanelDescriptionBox />
                        </GridItem>
                        <GridItem colSpan={1}>
                            <WhatsappPreviewBox />
                        </GridItem>
                        <GridItem rowSpan={2} colSpan={2} px="1em" bg="white" rounded="lg">
                            <ProgrammedWhatsappTable />
                        </GridItem>
                    </Grid>
                    : <NoPackageView/>
            }

        </>
    )
}
