import { useState, useEffect } from "react";
import { Flex, Text, Link, Divider, useMediaQuery, Skeleton, Tag } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
//fs
import { formatDecimals, parseDuration } from "../../../../common/functions";
//icon
import { FiExternalLink } from "react-icons/fi";
import { LuSparkle } from "react-icons/lu";
//types
import type { PaginatedResponse } from "../../../../../types/api";
import type { BusinessService } from "../../../../../types/business";
//comps
import AddServiceButton from "./AddServiceButton";
import PaginationMenu from "./PaginationMenu";
import BusinessServiceFilter from "./BusinessServiceFilter";
import SearchInput from "../../../../common/Tables/SearchInput";
import ServicesCart from "./ServicesCart";
//api
import { getServicesLiveView } from "../../../../../api/businessesApi";
import { useQuery } from "@tanstack/react-query";

interface ServicesSectionProps {
	planExpired?: boolean;
}

export default function ServicesSection({ planExpired }: ServicesSectionProps) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const { workshop } = useParams();
	const [selectedServices, setSelectedServices] = useState<BusinessService[]>([]);
	const [totalPrice, setTotalPrice] = useState<number>(0);
	const [page, setPage] = useState(1);
	const [filter, setFilter] = useState("all");
	const [searchQuery, setSearchQuery] = useState("");

	const { data, isLoading } = useQuery<PaginatedResponse<BusinessService>>({
		queryKey: ["landingLiveServices", page, filter, searchQuery],
		queryFn: () => getServicesLiveView({ slug: workshop, page: page, filter: filter, searchQuery: searchQuery })
	});
	const pageSize = 5;
	const shouldPaginate = data ? data?.count > pageSize : false;

	const handleToggleService = (service: BusinessService) => {
		setSelectedServices((prevSelected) => {
			if (prevSelected.some((s) => s.id === service.id)) {
				return prevSelected.filter((s) => s.id !== service.id);
			}
			return [...prevSelected, service];
		});
	};
	const resetCart = () => {
		setSelectedServices([]);
	};
	useEffect(() => {
		const newTotal = selectedServices.reduce((acc, service) => {
			return acc + Number.parseFloat(service.price);
		}, 0);
		setTotalPrice(newTotal);
	}, [selectedServices]);

	const generateSkeletonServices = (numRows: number) => {
		const rows = [];
		for (let i = 0; i <= numRows; i++) {
			rows.push(<Skeleton key={i} h='45px' rounded='md' mt='1.5rem' />);
		}
		return rows;
	};
	if (!isMobile) {
		return (
			<>
				<Flex direction='column' mt='2em' mb='1em'>
					<Text fontWeight='bold' fontSize='24px'>
						Nuestros servicios
					</Text>
					<Flex align='center' w='100%' mt='0.8rem' justify='space-between' flexWrap='wrap'>
						<BusinessServiceFilter filter={filter} setFilter={setFilter} />
						<SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
					</Flex>
					<Divider borderWidth='1px' color='#D9D9D9' my='1rem' />
					{data && !isLoading ? (
						<Flex direction='column' gap='1rem'>
							{data.count > 0 ? (
								data?.results?.map((service) => (
									<Flex key={service.id} direction='column' bg='white' rounded='lg' p='1rem' w='100%'>
										{service.is_featured && (
											<Flex
												align='center'
												gap='0.2rem'
												bg='blue.500'
												w='95px'
												px='0.5rem'
												color='white'
												rounded='xl'
												fontSize='10px'
												mt='-25px'
												ml='-1rem'
												mb='10px'
											>
												<LuSparkle size='18px' />
												DESTACADO
											</Flex>
										)}
										<Flex justify='space-between' w='100%'>
											<Text fontWeight='bold' maxW='60%'>
												{service.name}
											</Text>
											<Flex gap='1em'>
												<Flex direction='column' align='end'>
													<Text fontWeight='bold'>{formatDecimals(service.price)} €</Text>
													<Text fontSize='12px'>{parseDuration(service.duration)}</Text>
												</Flex>
												{!planExpired && (
													<AddServiceButton
														service={service}
														selectedServices={selectedServices}
														toggleService={handleToggleService}
													/>
												)}
											</Flex>
										</Flex>
										<Flex w='100%' justify={service.description ? "space-between" : "end"}>
											{service.description && (
												<Text color='grey' mt='0.5rem'>
													{service.description}
												</Text>
											)}
											{service.more_information_url && (
												<Link
													href={service.more_information_url}
													isExternal
													fontSize='14px'
													pt='1em'
													alignSelf='end'
												>
													<Flex align='center' gap='0.2em'>
														+Info
														<FiExternalLink />
													</Flex>
												</Link>
											)}
										</Flex>
									</Flex>
								))
							) : (
								<Text color='black'> Este negocio aun no ha registrado servicios</Text>
							)}
							{shouldPaginate && (
								<PaginationMenu
									pageSize={pageSize}
									total={data?.count}
									next={data?.next}
									prev={data?.previous}
									page={page}
									setPage={setPage}
								/>
							)}
						</Flex>
					) : (
						<>{generateSkeletonServices(5)}</>
					)}
				</Flex>
				<ServicesCart selectedServices={selectedServices} totalPrice={totalPrice} resetCart={resetCart} />
			</>
		);
	}
	return (
		<Flex direction='column' gap='1rem' pb='8dvh'>
			<BusinessServiceFilter filter={filter} setFilter={setFilter} />
			<SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
			{data && data.count > 0 ? (
				data.results?.map((service) => (
					<Flex key={service.id} direction='column' bg='white' rounded='lg' p='1em' w='100%'>
						{service.is_featured && (
							<Flex
								align='center'
								gap='0.2rem'
								bg='blue.500'
								w='95px'
								px='0.5rem'
								color='white'
								rounded='xl'
								fontSize='10px'
								mt='-25px'
								ml='-1rem'
								mb='10px'
							>
								<LuSparkle size='18px' />
								DESTACADO
							</Flex>
						)}
						<Flex justify='space-between' w='100%'>
							<Text fontWeight='bold' maxW='60%' fontSize='14px'>
								{service.name}
							</Text>
							<Flex gap='1em'>
								<Flex direction='column' align='end'>
									<Text fontWeight='bold' fontSize='12px'>
										{formatDecimals(service.price)} €
									</Text>
									<Text fontSize='10px'>{parseDuration(service.duration)}</Text>
								</Flex>
								{!planExpired && (
									<AddServiceButton
										service={service}
										selectedServices={selectedServices}
										toggleService={handleToggleService}
									/>
								)}
							</Flex>
						</Flex>
						<Flex w='100%' justify={service.description ? "space-between" : "end"}>
							{service.description && (
								<Text color='grey' mt='0.5em' fontSize='12px'>
									{service.description}
								</Text>
							)}
							{service.more_information_url && (
								<Link
									href={service.more_information_url}
									isExternal
									fontSize='14px'
									pt='1em'
									alignSelf='end'
								>
									<Flex align='center' gap='0.2em'>
										+Info
										<FiExternalLink />
									</Flex>
								</Link>
							)}
						</Flex>
					</Flex>
				))
			) : (
				<Text color='black'> Este negocio aun no ha registrado servicios</Text>
			)}
			{shouldPaginate && (
				<PaginationMenu
					total={data?.count || 0}
					next={data?.next}
					prev={data?.previous}
					page={page}
					pageSize={pageSize}
					setPage={setPage}
				/>
			)}
			<ServicesCart selectedServices={selectedServices} totalPrice={totalPrice} resetCart={resetCart} />
		</Flex>
	);
}
