import { useState } from "react";
import {
	Button,
	Heading,
	Flex,
	Text,
	InputGroup,
	InputRightElement,
	IconButton,
	useMediaQuery,
	useToast
} from "@chakra-ui/react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
// icons
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
// forms
import InputField from "../common/forms/InputField";
import * as Yup from "yup";
import { Formik } from "formik";
// api
import { register, googleLogin } from "../../api/authApi";
import { useMutation } from "@tanstack/react-query";
// SocialAuth
import { GoogleLogin } from "@react-oauth/google";
// auth
import { useSignIn } from "react-auth-kit";
import { userSignIn } from "../../utils";

export default function RegisterForm({ isBeta }) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const toast = useToast();
	const navigate = useNavigate();
	const signIn = useSignIn();
	const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

	const [show, setShow] = useState(false);

	const validationSchema = Yup.object({
		email: Yup.string().email("Formato de email inválido").required("Email es obligatorio"),
		name: Yup.string("Formato de nombre inválido").required("El nombre es obligatorio"),
		password: Yup.string()
			.min(8, "Contraseña demasiado corta - mínimo 8 caracteres")
			.matches(/[a-zA-Z]/, "Solo puede contener letras del abecedario")
			.required("Campo obligatorio")
	});

	const { data, isLoading, mutate, error } = useMutation(["register"], register, {
		onSuccess: () => {
			toast({ title: "Registro exitoso!", status: "success" });
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.response.data.email,
				status: "error"
			});
		}
	});
	const { mutate: gLogin } = useMutation(["googleLogin"], googleLogin, {
		onSuccess: (token) => {
			userSignIn(token, toast, signIn, navigate);
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.response?.data.message,
				status: "error"
			});
		}
	});
	const onGSuccess = async (res) => {
		const credentials = jwt_decode(res.credential);
		const payload = {
			email: credentials.email,
			name: credentials.name,
			plan: "FREE"
		};
		if (isBeta) {
			payload.plan = "BETA";
		}
		gLogin(payload);
	};

	const handleRegister = (values) => {
		const payload = {
			email: values.email.toLowerCase(),
			name: values.name,
			password: values.password,
			plan: "FREE"
		};
		if (isBeta) {
			payload.plan = "BETA";
		}
		mutate(payload);
	};

	if (data) {
		return (
			<>
				<Heading size='md' align='center' color='black'>
					¡Bienvenido a <span style={{ color: "#001234", fontWeight: "bold" }}>another</span>
					<span style={{ color: "#0258FF", fontWeight: "bold" }}>tool</span>!
				</Heading>
				<Text mt='2em' align='center'>
					Tu cuenta ha sido creada exitosamenta. <br />
					Hemos enviado un mail a <span style={{ fontWeight: "bold", color: "#0258FF" }}> {data.email} </span>
					con un enlace de activación.
				</Text>
				<hr />
				<Text align='center'>
					Verifica tu bandeja de entrada y haz clic en el enlace para completar el registro. <br />
					Si no lo encuentras, mira en la carpeta
					<span style={{ fontWeight: "bold", color: "#0258FF" }}> spam</span> o
					<span style={{ fontWeight: "bold", color: "#0258FF" }}> correo no deseado</span>
				</Text>
				<hr />
				<Text align='center'>
					Una vez activado, estarás listo para simplificar la gestión de tu taller y llevar el negocio al
					siguiente nivel.
				</Text>
			</>
		);
	}
	return (
		<>
			<Flex direction='column' gap='0.5em' mb='1em' w='100%'>
				{!isMobile ? (
					<Heading fontSize='24px' align='center'>
						Empezar en <span style={{ fontWeight: "bold", color: "#001234" }}>another</span>
						<span style={{ fontWeight: "bold", color: "#0258FF" }}>tool</span> es gratis
					</Heading>
				) : (
					<Flex align='center' alignSelf='center' gap='0.5em'>
						<Heading fontSize='22px' ml='0.4em'>
							Empieza
						</Heading>
						<Heading fontSize='28px' color='blue'>
							GRATIS
						</Heading>
					</Flex>
				)}
				<Text fontSize='14px' fontWeight='300' color='#6b7280'>
					Sin ataduras, y sin tarjeta de crédito
				</Text>
			</Flex>
			<GoogleLogin
				clientId={clientId}
				text='signup_with'
				onSuccess={onGSuccess}
				onFailure={console.log("Fallo haciendo noseque")}
				width={!isMobile ? "600px" : "100%"}
				theme='filled_blue'
				logo_alignment='left'
				context='signup'
				type='standard'
			/>
			<Text my='1em' fontSize='14px' fontWeight='400' alignSelf='center' color='#6b7280'>
				o regístrate con
			</Text>

			<Formik
				initialValues={{
					email: "",
					name: "",
					password: ""
				}}
				validationSchema={validationSchema}
				onSubmit={(values) => handleRegister(values)}
			>
				{(formik) => (
					<Flex
						as='form'
						direction='column'
						justify='space-around'
						gap='3'
						w='100%'
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								formik.handleSubmit();
							}
						}}
					>
						<InputField placeholder='Nombre' name='name' />
						<InputField placeholder='Correo electrónico' name='email' />
						{error?.email && (
							<Text color='red' fontSize='xs'>
								{error?.email}
							</Text>
						)}
						<InputGroup>
							<InputField
								type={show ? "text" : "password"}
								name='password'
								placeholder='Contraseña'
								error={error?.response?.data?.detail}
							/>
							<InputRightElement mt='7px'>
								<IconButton
									bg='transparent'
									color='blackAlpha.600'
									onClick={() => setShow(!show)}
									icon={show ? <AiFillEyeInvisible size='20px' /> : <AiFillEye size='20px' />}
								/>
							</InputRightElement>
						</InputGroup>
						<Flex direction={"column"} align='start'>
							<Text color='gray' fontSize='xs' fontWeight='hairline'>
								Debe tener al menos 8 caracteres
							</Text>
							<Text color='gray' fontSize='xs' fontWeight='hairline'>
								No puede ser similar a tu otra información personal
							</Text>
							<Text color='gray' fontSize='xs' fontWeight='hairline'>
								No puede ser enteramente numérica
							</Text>
						</Flex>

						<Button
							variant='primary'
							size='md'
							mt='8'
							w='100%'
							alignSelf='center'
							isDisabled={
								JSON.stringify(formik.errors) !== "{}" || JSON.stringify(formik.touched) === "{}"
							}
							onClick={formik.handleSubmit}
							isLoading={isLoading}
							loadingText='Iniciando...'
						>
							Registrarse
						</Button>
					</Flex>
				)}
			</Formik>
		</>
	);
}
