import { useState, useMemo, useCallback } from "react";
import { Flex, Text, Image, TableContainer, Table, Thead, Th, Tbody, Tr, Td, useToast, Button } from "@chakra-ui/react";
import moment from "moment";
// comps
import MessageStatusView from "./MessageStatusView";
import PaginationMenu from "../../common/Tables/PaginationMenu";
import LoadingSpinner from "../../common/LoadingSpinner";
//fns
import { formatOrderIncrementalID } from "../orders/helpers";
// img
import emptyStock from "../../../img/stock/emptyStock.png";
// api
import { useQueryClient } from "@tanstack/react-query";
import useAuthFetch from "../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../myHooks/useAuthMutation";
import { getAllProgrammedWhatsappPag, cancelProgrammedWhatsapp } from "../../../api/communicationApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function ProgrammedWhatsappTable() {
	const [page, setPage] = useState(1);
	const QueryClient = useQueryClient();
	const authHeader = useAuthHeader();
	const toast = useToast();
	const { data, isLoading } = useAuthFetch(
		["programmedWhatsapps", page],
		getAllProgrammedWhatsappPag,
		page,
		null,
		true,
		null,
		{ refetchInterval: 10000 }
	);

	const { isLoading: loadingCancel, mutate } = useAuthMutation(
		["cancelProgrammedWhatsapps"],
		cancelProgrammedWhatsapp,
		{
			onSuccess: () => {
				toast({ title: "Cancelado con exito!", status: "success" });
				QueryClient.invalidateQueries(["programmedWhatsapps", "planlimits"]);
				QueryClient.refetchQueries(["programmedWhatsapps", "planlimits"], { force: true });
			},
			onError: (error) => {
				toast({
					title: error.response.data,
					description: error.code,
					status: "error"
				});
			}
		}
	);
	const cancelWhatsapp = useCallback(
		(sid) => {
			const payload = {
				data: {
					id: sid
				},
				token: authHeader()
			};
			mutate(payload);
		},
		[mutate, authHeader]
	);
	const tableData = useMemo(
		() =>
			data?.results?.map((whatsapp) => (
				<Tr key={whatsapp.id}>
					<Td>{whatsapp.client}</Td>
					<Td>{formatOrderIncrementalID(whatsapp.order_id)}</Td>
					<Td>{whatsapp.type_display}</Td>
					<Td>{moment(whatsapp.send_at).format("lll")}</Td>
					<Td>
						<MessageStatusView status={whatsapp?.status} />
					</Td>
					<Td>
						<Button
							size='sm'
							colorScheme='red'
							isLoading={loadingCancel}
							isDisabled={whatsapp.status !== "scheduled"}
							onClick={() => cancelWhatsapp(whatsapp.sid)}
						>
							Cancelar
						</Button>
					</Td>
				</Tr>
			)),
		[data, loadingCancel, cancelWhatsapp]
	);
	const isEmpty = data?.count < 1;
	const headers = [
		// { key: "sid", label: "SID", },
		// { key: "from", label: "De" },
		{ key: "client", label: "Para", roundedLeft: "lg" },
		{ key: "order", label: "Orden" },
		{ key: "type_display", label: "Tipo" },
		{ key: "send_at", label: "Fecha" },
		{ key: "status", label: "Estado" },
		{ key: "actions", label: "", roundedRight: "lg" }
	];
	return (
		<>
			<TableContainer mt='1em' w='100%'>
				<Table variant='unstyled' size='sm' rounded='xl'>
					<Thead>
						<Tr bg='lightgrey'>
							{headers.map((header) => (
								<Th
									key={header.key}
									py='1.5em'
									roundedLeft={header.roundedLeft}
									roundedRight={header.roundedRight}
								>
									{header.label}
								</Th>
							))}
						</Tr>
					</Thead>
					{!isEmpty && <Tbody>{tableData}</Tbody>}
				</Table>
				{isLoading && <LoadingSpinner />}
				{isEmpty && (
					<Flex minW='800px' align='center' py='4em' direction='column'>
						<Image src={emptyStock} />
						<Text mt='2em'>Aún no hay ningún mensaje programado</Text>
						<Flex flexWrap='wrap' justify='center'>
							<Text>Añade una</Text>
							<Text color='blue' fontWeight='bold' mx='0.2em'>
								Plantilla de Mensaje
							</Text>
							<Text>a una orden, para crear uno nuevo</Text>
						</Flex>
					</Flex>
				)}
				<PaginationMenu
					total={data?.count}
					next={data?.next}
					prev={data?.previous}
					page={page}
					setPage={setPage}
				/>
			</TableContainer>
		</>
	);
}
