import { Flex, Text, Divider } from "@chakra-ui/react";
import moment from "moment";
//icons
import { CiClock2 } from "react-icons/ci";
//fs
import { formatDecimals, parseDuration } from "../../../../common/functions";
//types
import type { BusinessService } from "../../../../../types/business";

interface SelectedServicesDisplayProps {
	services: BusinessService[];
	totalPrice: number;
	dateIn: Date;
}

export default function SelectedServicesDisplay({ services, totalPrice, dateIn }: SelectedServicesDisplayProps) {
	return (
		<Flex direction='column' minW='40%'>
			{services.map((service) => (
				<Flex key={service.id} direction='column' align='start' gap='1rem' mt='1.5rem'>
					<Text fontSize='20px' fontWeight='bold'>
						{service.name}
					</Text>
					<Text fontSize='14px' align='start'>
						{service.description}
					</Text>
					<Flex align='center'>
						<CiClock2 size='20px' color='black' />
						<Text fontSize='14px' ml='0.2em' mr='1em'>
							{parseDuration(service.duration, "long")}
						</Text>
						<Text fontSize='14px' fontWeight='bold'>
							{formatDecimals(service.price)} €
						</Text>
					</Flex>
					<Divider />
				</Flex>
			))}
			<Flex w='100%' justify='space-between' mt='1.5rem' fontSize='18px'>
				<Text>Cita:</Text>
				<Text fontWeight='bold'>{moment(dateIn).format("LLL")}</Text>
			</Flex>
			<Flex w='100%' justify='space-between' mt='1.5rem' fontSize='18px'>
				<Text>Total:</Text>
				<Text fontWeight='bold'>{totalPrice}€</Text>
			</Flex>
		</Flex>
	);
}
