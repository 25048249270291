import React, { Fragment, useEffect } from 'react'
import { Text, Flex, Button, Accordion } from '@chakra-ui/react'
import moment from 'moment'
// comps
import InfoTooltip from '../../../common/InfoTooltip'
import MultiInvoiceItem from './MultiInvoiceItem'
// fs
import { formatDecimals } from '../../../common/functions'
// forms and validation
import { Formik } from 'formik'
import * as Yup from 'yup'
import InputField from '../../../common/forms/InputField'
import SelectField from '../../../common/forms/SelectField'
import SelectFieldFilter from '../../../common/forms/SelectFieldFilter'
import MultiDiscountField from './MultiDiscountField'

export default function MultiInvoiceForm (props) {
    const {
        submit,
        nextInvoiceID,
        clients,
        invoice,
        options,
        isLoading,
        orders,
        total,
        setTotal,
        taxes,
        subtotal,
        discount,
        setDiscount,
        error
    } = props

    const taxChoices = options?.actions?.POST?.items?.child?.children?.tax?.choices
    const payMethodChoices = options?.actions?.POST?.pay_method?.choices
    const invoiceStatusChoices = options?.actions?.POST?.status?.choices
    const parseOrderServicesToInvoiceItems = (order) => {
        const services = order.services
        return services.map((service) => {
            return {
                concept: service.name,
                price: parseFloat(service.price).toFixed(2),
                quantity: 1,
                tax: 'twenty',
                order: order.id
            }
        })
    }
    const parseOrderStockUsedToInvoiceItems = (order) => {
        const usedStock = order.stock_used
        return usedStock.map((stock) => {
            return {
                concept: stock.name,
                price: parseFloat(stock.total).toFixed(2),
                quantity: parseFloat(stock.quantity),
                tax: 'twenty',
                order: order.id
            }
        })
    }
    const getInitialItems = (orders) => {
        let invoiceItems = []
        orders?.forEach((order) => {
            if (order && order.services) {
                const parsedServiceItems = parseOrderServicesToInvoiceItems(order)
                invoiceItems = [...invoiceItems, ...parsedServiceItems]
            } if (order && order.stock_used) {
                const parsedStockItems = parseOrderStockUsedToInvoiceItems(order)
                invoiceItems = [...invoiceItems, ...parsedStockItems]
            } else {
                invoiceItems.push(
                    {
                        concept: '',
                        price: '',
                        quantity: '',
                        tax: 'twenty',
                        order: order.id
                    }
                )
            }
        })
        return invoiceItems
    }

    const initialValues = {
        date: invoice ? moment(invoice.date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        expiring_date: invoice?.expiring_date
            ? moment(invoice.expiring_date).format('YYYY-MM-DD')
            : moment().add(5, 'days').format('YYYY-MM-DD'),
        client: '',
        invoice_number: invoice ? invoice.invoice_number : nextInvoiceID && nextInvoiceID,
        status: invoice?.status || 'pending',
        pay_method: invoice?.pay_method || 'card',
        total: invoice ? invoice.total : 0,
        taxes: invoice ? invoice.taxes : 0,
        discount: invoice?.discount || 0,
        items: getInitialItems(orders)
    }
    const validationSchema = Yup.object({
        invoice_number: Yup.string().required('Debes añadir numero de factura'),
        date: Yup.date().required(),
        expiring_date: Yup.date(),
        client: Yup.number().required('Debes seleccionar un cliente'),
        status: Yup.string(),
        discount: Yup.number('Debe ser un número'),
        items: Yup.array()
            .required('La factura debe contener al menos un item')
            .of(
                Yup.object().shape({
                    concept: Yup.string().required('Concepto obligatorio'),
                    price: Yup.number().required('Introduce precio'),
                    quantity: Yup.number().min(1, 'Minima cantidad es 1').required('Debes introducir cantidad'),
                    tax: Yup.string().required('Selecciona un impuesto asociado'),
                    order: Yup.number()
                })
            )
    })
    const accumulateTotal = (number) => {
        setTotal(parseFloat(total) + number)
    }
    const handleSubmit = (values) => {
        submit(values)
    }
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            {(formik) => (
                <Flex w="100%" gap="25px" h="100%">
                    <Flex direction="column" gap="1em" w='75%' maxH="70vh" overflowY="scroll" pr='1em'
                        sx={{
                            '&::-webkit-scrollbar': { width: '5px' },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: '#f5f5f5'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#cfcfcf'
                            }
                        }} >
                        {/* Basic data section */}
                        <Flex gap="1em">
                            <InputField
                                name="invoice_number"
                                error={error?.response?.data?.invoice_number}
                                label={
                                    <InfoTooltip
                                        label={'Nº Factura'}
                                        desc={'Puedes configurarlo desde panel de ajustes'}
                                    />
                                }
                            />
                            <InputField name="date" label="Fecha" type="date" error={error?.response?.data?.date} />
                            <InputField
                                name="expiring_date"
                                label="Fecha de Expiración"
                                type="date"
                                error={error?.response?.data?.expiring_date}
                            />
                        </Flex>
                        <Accordion defaultIndex={[0]} allowMultiple >
                            {orders?.map((order) => (
                                <Fragment key={order.id}>
                                    <MultiInvoiceItem
                                        order={order}
                                        setTotal={accumulateTotal}
                                    />
                                </Fragment>
                            ))}
                        </Accordion>

                    </Flex>
                    <Flex direction="column" h="100%" w='25%' justify="space-between">
                        <Flex direction="column" gap="1em">
                            <SelectFieldFilter
                                required
                                label="Cliente"
                                options={clients?.results}
                                name="client"
                                error={error?.response?.data?.clients}
                            />
                            {clients?.count === 0 && (
                                <Flex mt="1em" align="center" gap="1em">
                                    <Text fontSize="14px" color="red">
                                        Aún no hay clientes, crea uno desde el panel de datos
                                    </Text>
                                </Flex>
                            )}
                            <SelectField label="Método de pago" name="pay_method" choices={payMethodChoices} />

                            <SelectField label="Estado" name="status" choices={invoiceStatusChoices} />

                            <MultiDiscountField discount={discount} setDiscount={setDiscount}/>

                        </Flex>
                        <Flex direction="column" gap="2em">
                            <Flex w="100%" justify="space-between">
                                <Flex direction="column" gap="1em">
                                    <Text fontSize="20px">Subtotal</Text>
                                    <Text fontSize="20px">IVA</Text>
                                    {Number(discount) > 0 && <Text fontSize="20px">Descuento</Text>}
                                    <Text fontSize="20px" fontWeight="bold">
                                        Total
                                    </Text>
                                </Flex>
                                <Flex direction="column" gap="1em">
                                    <Text fontSize="20px" align="end">
                                        {formatDecimals(subtotal)}€
                                    </Text>
                                    <Text fontSize="20px" align="end">
                                        {formatDecimals(taxes)}€
                                    </Text>
                                    {Number(discount) > 0 && (
                                        <Text fontSize="20px" align="end">
                                            -{formatDecimals(discount * total / 100)}€
                                        </Text>
                                    )}
                                    <Text fontSize="20px" fontWeight="bold" align="end">
                                        {formatDecimals(total)}€
                                    </Text>
                                </Flex>
                            </Flex>
                            <Button
                                variant="primary"
                                justifySelf="end"
                                alignSelf="end"
                                w="150px"
                                onClick={formik.handleSubmit}
                                isLoading={isLoading}
                            >
                                Guardar
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </Formik>
    )
}
