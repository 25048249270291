import { Text, View, Image } from "@react-pdf/renderer";
import moment from "moment";
import { styles } from "../PDFStyle";

export default function InvoicePDFHeader({ invoice, invoiceSettings, businessProfile }) {
	return (
		<>
			{/* Header */}
			<View style={styles.documentHeader}>
				<View
					style={{
						flexDirection: "column",
						gap: "10px",
						alignItems: "center"
					}}
				>
					{businessProfile?.banner_image && (
						<Image
							style={{ maxWidth: "120px", maxHeight: "120px", objectFit: "cover", borderRadius: "5px" }}
							cache={false}
							crossorigin='anonymous'
							src={businessProfile.banner_image}
						/>
					)}
					<Text style={{ fontSize: "12px" }}>{businessProfile?.name || "Nombre de tu centro"}</Text>
				</View>
				<View style={{ alignItems: "flex-end", gap: "5px" }}>
					<Text style={{ fontSize: "16px", marginBottom: "5px" }}>FACTURA</Text>
					<Text style={{ fontSize: "12px" }}>Nº Factura: {invoice?.invoice_number}</Text>
					<Text style={{ color: "#737A87", fontSize: "10px" }}>
						Fecha: {moment(invoice?.date).format("L")}
					</Text>
				</View>
			</View>
			<hr />
			<View
				style={{
					flexDirection: "row",
					gap: "20px",
					paddingVertical: "10px",
					justifyContent: "flex-end",
					width: "100%",
					fontSize: "10px",
					marginVertical: "10px",
					borderTop: "1px solid #F4F4F9",
					borderBottom: "1px solid #F4F4F9"
				}}
			>
				<Text>Fecha: {moment(invoice?.date).format("L")}</Text>
				<Text>Expiración: {moment(invoice?.expiring_date).format("L")}</Text>
			</View>
			<hr />
			<View style={{ flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "5px" }}>
				<View style={{ flexDirection: "column", gap: "5px", fontSize: "10px", maxWidth: "45%" }}>
					<Text style={{ fontSize: "12px", color: "blue", marginBottom: "3px" }}>DE:</Text>
					<Text style={{ fontSize: "12px", fontWeight: "medium" }}>
						{invoiceSettings?.name || "Nombre de tu centro"}
					</Text>
					<Text>{invoiceSettings?.address || "Dirección de facturación"}</Text>
					<Text>{invoiceSettings?.CIF || "CIF de tu centro"}</Text>
					<Text>{businessProfile?.email || "Email de tu centro"}</Text>
					<Text>{businessProfile?.phone || "Teléfono del centro"}</Text>
				</View>
				<View
					style={{
						flexDirection: "column",
						gap: "5px",
						alignItems: "flex-end",
						fontSize: "10px",
						maxWidth: "45%"
					}}
				>
					<Text style={{ fontSize: "12px", color: "blue", marginBottom: "3px" }}>PARA:</Text>
					<Text style={{ fontSize: "12px", fontWeight: "medium" }}>{invoice?.client?.name}</Text>
					{invoice?.client?.address && <Text style={{ textAlign: "right" }}>{invoice.client.address}</Text>}
					{invoice?.client?.nif_cif && <Text>{invoice.client.nif_cif} </Text>}
					{invoice?.client?.email && <Text>{invoice.client.email} </Text>}
					{invoice?.client?.phone && (
						<Text>{invoice.client.phone?.split("+34")[1] || invoice.client.phone}</Text>
					)}
				</View>
			</View>
		</>
	);
}
