import { Flex, Text, Accordion, useMediaQuery } from "@chakra-ui/react";
// comps
import ExpensesBreakdown from "./ExpensesBreakdown";
import ExpensesPieChart from "./ExpensesPieChart";
import ComparisonPeriodDisplay from "../ComparisonPeriodDisplay";
//icons
import { BsBag } from "react-icons/bs";

export default function ExpensesBox({ expenses, period, isLoading }) {
	const [isLittle] = useMediaQuery("(max-width: 1280px)");

	return (
		<Flex direction='column' align='center' w='100%' h='100%' bg='white' px='2em' py='1em' rounded='xl'>
			<Flex w='100%' justify='space-between' mb='1rem' h='100%' direction={isLittle && "column"}>
				<Flex direction='column'>
					<Flex gap='0.2rem' h='32px' align='center'>
						<BsBag size='18px' />
						<Text fontSize='22px' fontWeight='bold'>
							Gastos
						</Text>
					</Flex>
					{expenses.now > 0 ? (
						<>
							<Flex
								direction='column'
								pl='1rem'
								gap='1rem'
								mt='2rem'
								maxH='100%'
								overflowY='scroll'
								sx={{
									paddingRight: "0.2rem",
									"&::-webkit-scrollbar": { width: "5px", height: "8px" },
									"&::-webkit-scrollbar-track": {
										backgroundColor: "#f5f5f5"
									},
									"&::-webkit-scrollbar-thumb": {
										backgroundColor: "#cfcfcf"
									}
								}}
							>
								<Accordion allowMultiple>
									<ExpensesBreakdown
										title={"Gastos variables"}
										color='#F9CA94'
										data={expenses?.single_expenses_breakdown}
									/>
									<ExpensesBreakdown
										title={"Gastos fijos"}
										data={expenses?.recurring_expenses_breakdown}
										color='#F6E8D3'
									/>
								</Accordion>
							</Flex>
						</>
					) : (
						<Flex w='100%' mt='2em' h='100%' justify='center' align='center'>
							<Text>
								No hay ningún gasto registrado para este periodo, añádelos en la sección de stock
							</Text>
						</Flex>
					)}
				</Flex>

				<Flex direction='column' align='end' w='50%'>
					<Flex align='center' gap='1rem'>
						<Text color='gray'>TOTAL:</Text>
						<Text fontSize='26px' color='darkblue' fontWeight='600'>
							{expenses?.now}€
						</Text>
					</Flex>
					<ComparisonPeriodDisplay
						lastPeriod={expenses?.last_period}
						period={period}
						isFinancial
						isReverse
						isLoading={isLoading}
					/>
					<Flex align='center' gap='0.5rem' mt='0.4rem'>
						<Text color='gray' fontSize='14px'>
							Neto:
						</Text>
						<Text color='darkblue' fontWeight='semibold'>
							{expenses?.net}€
						</Text>
					</Flex>
					<Flex align='center' gap='0.5rem'>
						<Text color='gray' fontSize='14px'>
							IVA:
						</Text>
						<Text color='darkblue' fontWeight='semibold'>
							{expenses?.taxes}€
						</Text>
					</Flex>
					{/* 
					<Flex w='100%' h='280px'>
						<ExpensesPieChart data={expenses?.pie} />
					</Flex>
					*/}
				</Flex>
			</Flex>
		</Flex>
	);
}
