import { Box, Badge, Tooltip } from '@chakra-ui/react'

export default function OrderCardServiceTags ({ services }) {
    if (services) {
        return (
            <Box align="start" gap="10px" maxW="100%" overflowWrap="break-word" overflow="hidden">
                {services.map((service) => (
                    <Tooltip
                        key={service.id}
                        label={service.name}
                        bg={service.color}
                        color="black"
                        rounded="lg"
                        hasArrow
                    >
                        <Badge
                            bg={service.color}
                            maxW="220px"
                            cursor={'pointer'}
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            color="black.900"
                            px="5px"
                            rounded="full"
                            mb="10px"
                        >
                            {service.name}
                        </Badge>
                    </Tooltip>
                ))}
            </Box>
        )
    }
}
