import { useMemo, useState } from "react";
import { useDisclosure, useMediaQuery, Flex } from "@chakra-ui/react";

import moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./custom-calendar.css";
// comps
import OrderForm from "../../orders/forms/OrderForm";
// api
import useAuthQuery from "../../../../myHooks/useAuthQuery";
import { getProgrammedOrders } from "../../../../api/ordersApi";
// auth
import { useAuthHeader } from "react-auth-kit";

const localizer = momentLocalizer(moment);
const STATUS_COLORS = {
	pending: "#F7F7F7",
	waiting: "#BEE3F8",
	started: "#FEEBC8",
	completed: "#42C74F1A"
};

export default function MyCalendar() {
	const authHeader = useAuthHeader();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [dateIn, setDateIn] = useState();
	const [dateOut, setDateOut] = useState();
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const [height, setHeight] = useState(isMobile ? "65dvh" : "100%");

	const { data: orders, isLoading } = useAuthQuery({
		queryKey: ["programmedOrders"],
		queryFn: () => getProgrammedOrders({ auth: authHeader() })
	});
	const getOrderId = (order) => {
		const id = order.incremental_id >= 0 ? order.incremental_id : order.id;
		return id.toString().padStart(5, "0");
	};
	const getOrderColor = (order) => {
		if (order?.workers && order.workers?.length > 0) {
			return order.workers[0].color;
		} else if (order?.services && order.services?.length > 0) {
			return order.services[0].color;
		}
		return STATUS_COLORS[order.status] || "grey";
	};
	const getOrderTitle = (order) => {
		/*
        let title = `Orden #${getOrderId(order)}`
        if (order?.workers && order.workers?.length > 0){
            const workerName = order.workers[0].name;
            const initials = workerName.split(' ').map(word => word[0]).join('.');
            title = `${title} ${initials}`;
        }
        */
		let title = `${order.car.brand_display}`;
		if (order.client && order.client.name) {
			title = `${title} ${order.client.name}`;
		}
		return title;
	};

	const mapOrdersToEvents = (orders) => {
		return orders.map((order) => ({
			id: order.id,
			title: getOrderTitle(order),
			start: new Date(order.date_in),
			end: new Date(order.date_out),
			color: getOrderColor(order)
		}));
	};
	const events = useMemo(() => {
		if (!isLoading && orders) {
			return mapOrdersToEvents(orders);
		}
		return [];
	}, [orders, isLoading]);

	function eventPropGetter(event) {
		const style = {
			backgroundColor: event.color,
			borderColor: event.color,
			borderRadius: "8px",
			color: "black"
		};

		if (isMobile && views.includes(Views.MONTH)) {
			style.fontSize = "8px"; // Adjust font size for more events in month view
			style.lineHeight = "12px"; // Adjust line height for better stacking
		}

		return { style };
	}
	const { defaultDate, views } = useMemo(
		() => ({
			defaultDate: new Date(),
			views: [Views.DAY, Views.WEEK, Views.MONTH]
		}),
		[]
	);
	const formatDay = (date) => {
		const dayNumber = date.getDate();
		const dayName = date.toLocaleDateString("es", { weekday: "short" }).charAt(0).toUpperCase();
		return `${dayName} ${dayNumber}`;
	};
	const handleChangeView = () => {
		if (isMobile) {
			setHeight("65dvh");
		} else if (!isMobile) {
			setHeight("100%");
		}
	};

	const style = !isMobile ? { height, width: "100%" } : { height, width: "100%" };
	const messages = !isMobile
		? {
				next: ">>",
				previous: "<<",
				today: "Hoy",
				month: "Mes",
				week: "Semana",
				day: "Día",
				showMore: (count) => "+" + count + " más"
			}
		: {
				next: ">>",
				previous: "<<",
				today: "H",
				month: "M",
				week: "S",
				day: "D",
				showMore: (count) => "+" + count + " más"
			};
	const formats = !isMobile
		? {
				dayFormat: "ddd DD",
				weekdayFormat: "ddd",
				monthHeaderFormat: "MMMM YYYY"
			}
		: {
				dayFormat: (date) => formatDay(date),
				weekdayFormat: "ddd",
				monthHeaderFormat: "M"
			};
	const handleSelectSlot = (slotInfo) => {
		const startDate = slotInfo.start;
		const dateIn = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 9, 0, 0);
		const dateOut = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 12, 0, 0);
		setDateIn(dateIn);
		setDateOut(dateOut);
		onOpen();
	};
	const handleSelectEvent = (e) => {
		const selectedOrder = orders.find((order) => order.id === e.id);
		if (selectedOrder) {
			setSelectedOrder(selectedOrder);
			onOpen();
		} else {
			console.log("No se encontró ninguna orden con el ID:", e.id);
		}
	};
	const handleClose = () => {
		setSelectedOrder();
		onClose();
	};

	return (
		<>
			<Flex h='100%'>
				<Calendar
					defaultDate={defaultDate}
					localizer={localizer}
					showMultiDayTimes // muestra los eventos que duran mas de un dia en el calendario de dia
					// dayLayoutAlgorithm={'no-overlap'} //algoritmo no overlappin util para showmultiday ttimes
					selectable // para selecionar un rango y crear fecha como antiguamente
					// showAllEvents={true} //muestra todos los eventos en vez de show more
					allDayMaxRows={2}
					popup={true} // muestra los eventos con show more
					eventPropGetter={eventPropGetter}
					events={events}
					onSelectSlot={handleSelectSlot}
					onSelectEvent={handleSelectEvent}
					startAccessor='start'
					endAccessor='end'
					min={new Date(1995, 0, 1, 7, 0, 0)}
					max={new Date(2026, 10, 0, 23, 0, 0)}
					views={views}
					defaultView={Views.WEEK}
					style={style}
					messages={messages}
					onView={handleChangeView}
					culture='es-ES'
					formats={formats}
				/>
			</Flex>
			<OrderForm isOpen={isOpen} item={selectedOrder} onClose={handleClose} dateIn={dateIn} dateOut={dateOut} />
		</>
	);
}
