import { useEffect, useContext, useState } from 'react'
import { Flex, Progress } from '@chakra-ui/react'
import { PlanContext } from './context/PlanContext'
// comps
import InfoTooltip from './InfoTooltip'

export default function PlanLimitsProgressPro () {
    const { limits } = useContext(PlanContext)
    const [parsed, setParsed] = useState()

    const sent = limits?.whatsapp_used || 0
    const limit = limits?.whatsapp_limit || 60
    const available = limits?.whatsapp_enabled || false
    useEffect(() => {
        const whatsapp_p = (sent / limit) * 100
        let whatsapp_color = 'green'
        if (whatsapp_p < 70) {
            whatsapp_color = 'green'
        }
        if ((whatsapp_p > 70) & (whatsapp_p < 90)) {
            whatsapp_color = 'orange'
        }
        if (whatsapp_p > 90) {
            whatsapp_color = 'red'
        }
        setParsed({
            whatsapp_p,
            whatsapp_color
        })
    }, [limits])
    if (available) {
        return (
            <Flex direction="column" gap="0.2em">
                <Progress value={parsed?.whatsapp_p} colorScheme={parsed?.whatsapp_color} h="5px" rounded="md" />
                <InfoTooltip
                    label={'Mensajes'}
                    desc={
                        'Llevas un ' +
                    Math.floor(parsed?.whatsapp_p) +
                    '% de Whatsapp enviados (' +
                    sent +
                    '/' +
                    limit +
                    ')'
                    }
                />
            </Flex>
        )
    }
}
