import { useEffect, useState } from "react";
import {
	Button,
	useToast,
	Flex,
	Text,
	Divider,
	Avatar,
	DrawerBody,
	DrawerFooter,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	useMediaQuery
} from "@chakra-ui/react";

import moment from "moment";
// comps
import CarForm from "../../data/forms/CarForm";
import StockUsed from "./StockUsed";
import OrderDates from "./OrderDates";
import ClientDescription from "./ClientDescription";
// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import SelectField from "../../../common/forms/SelectField";
import SelectFieldFilter from "../../../common/forms/SelectFieldFilter";
import MultiSelectFieldFilter from "../../../common/forms/MultiSelectFieldFilter";
// icons
import { BiCar } from "react-icons/bi";
import { MdCleaningServices } from "react-icons/md";
import { GrUserWorker } from "react-icons/gr";
import { LuGhost } from "react-icons/lu";
// auth
import { useAuthHeader, useAuthUser } from "react-auth-kit";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateOrderPut, getOrderOptions } from "../../../../api/ordersApi";
import { getAllCars } from "../../../../api/carsApi";
import { getAllStock } from "../../../../api/stockApi";
import { getAllServices } from "../../../../api/servicesApi";
import { getWorkers } from "../../../../api/businessesApi";

export default function OrderForm({ isOpen, onClose, item: order, dateIn, dateOut }) {
	const { isOpen: isOpenCar, onOpen: onOpenCar, onClose: onCloseCar } = useDisclosure();
	const toast = useToast();
	const auth = useAuthUser();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const [absence, setAbsence] = useState(order?.absence | false);

	const { data: options } = useAuthFetch(["orderOptions"], getOrderOptions, { enabled: !!isOpen });
	const { data: cars } = useAuthFetch(["cars"], getAllCars, { enabled: !!isOpen });
	const { data: services } = useAuthFetch(["services"], getAllServices, { enabled: !!isOpen });
	const { data: items } = useAuthFetch(["allItems"], getAllStock, { enabled: !!isOpen });
	const { data: workers } = useAuthFetch(["workers"], getWorkers, { enabled: !!isOpen });

	const carChoices = cars?.results.map((car) => ({
		label: (
			<Flex align='center' gap='0.5rem'>
				<Text>
					{car.brand} {car.model} ({car?.plate || car?.client?.name})
				</Text>
				{car?.client?.is_ghost && <LuGhost size='18px' color='gray' />}
			</Flex>
		),
		value: car.id,
		has_client: !!car.client,
		has_phone: !!car.client?.phone,
		has_signed_consent: car.client?.has_signed_consent
	}));
	const serviceChoices = services?.results?.map((service) => ({
		label: `${service.name}  ${service.price} €`,
		value: service.id,
		color: service.color
	}));
	const workerChoices = workers?.results?.map((worker) => ({
		label: (
			<Flex align='center' gap='1em'>
				<Avatar name={worker.name} size='sm' bg={worker.color} />
				{worker.name}
			</Flex>
		),
		value: worker.id,
		color: worker.color
	}));

	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateOrderPut,
		onSuccess: () => {
			toast({ title: "Creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["orders"]);
			QueryClient.refetchQueries("orders", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const parseDate = (date) => {
		if (date) {
			return moment(date).format("YYYY-MM-DDTHH:mm");
		}
		return undefined;
	};
	const initialValues = {
		date_in: parseDate(order ? order.date_in : dateIn || undefined),
		date_out: parseDate(order ? order.date_out : dateOut || undefined),
		client_desc: order?.client_desc || "",
		status: order ? order.status : "pending",
		car: order ? order.car.id : "",
		services: order ? order.services : [],
		workers: order ? order.workers : [],
		stock_used: order ? order.stock_used : []
	};

	const validationSchema = Yup.object({
		car: Yup.number().required("Debes asociarlo a un coche"),
		date_out: Yup.date().when(
			"date_in",
			(date_in, schema) => date_in && schema.min(date_in, "La fecha de salida debe ser posterior a la de entrada")
		),
		stock_used: Yup.array().of(
			Yup.object().shape({
				item: Yup.string().required("Selecciona un item"),
				price: Yup.number().required("Introduce precio"),
				quantity: Yup.number()
					.required("Debes introducir cantidad")
					.test("is-decimal", "Solo se permiten decimales de hasta dos cifras", (value) =>
						/^\d+(\.\d{1,2})?$/.test(value)
					)
			})
		)
	});
	const calculateTotal = (formData, services) => {
		let total = 0;
		const stockUsed = formData?.stock_used;
		const servicesProvided = formData?.services;
		if (stockUsed) {
			total += stockUsed?.reduce((prev, curr) => prev + curr.price * curr.quantity, 0);
		}
		if (services && servicesProvided) {
			servicesProvided.forEach((serviceIdOrObject) => {
				if (typeof serviceIdOrObject === "number") {
					const service = services.find((s) => s.id === serviceIdOrObject);
					if (service) {
						total += Number(service.price);
					}
				} else if (typeof serviceIdOrObject === "object") {
					total += Number(serviceIdOrObject.price);
				}
			});
		}
		return total ? "Total: " + total.toFixed(2) + "€" : "";
	};

	const disableSubmit = (formik) => {
		if (!formik.values.car || (formik.errors && formik.errors.stock_used)) {
			return true;
		} else return false;
	};
	const mapServicesToIds = (services) => services.map((service) => service.id);
	const submit = (values) => {
		if (Array.isArray(values.services) && values.services.length > 0 && typeof values.services[0] === "object") {
			values.services = mapServicesToIds(values.services);
		}
		if (Array.isArray(values.workers) && values.workers.length > 0 && typeof values.workers[0] === "object") {
			values.workers = mapServicesToIds(values.workers);
		}
		if (Array.isArray(values.sms) && values.sms.length > 0 && typeof values.sms[0] === "object") {
			values.sms = mapServicesToIds(values.sms);
		}
		const payload = order
			? { data: values, slug: order.id, token: authHeader() }
			: { data: values, token: authHeader() };
		payload.data.absence = absence;
		mutate(payload);
	};
	useEffect(() => {
		calculateTotal(items, services);
	}, [items, services]);
	const isFree = auth().plan === "FREE";
	const getOrderId = () => {
		const id = order.incremental_id === 0 ? order.id : order.incremental_id;
		return id.toString().padStart(5, "0");
	};
	return (
		<>
			<CarForm isOpen={isOpenCar} onClose={onCloseCar} />
			<Drawer size='lg' isOpen={isOpen} placement='right' onClose={onClose}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader py='2em'>
						{order ? "Editar" : "Crear"} orden de trabajo {order && "#" + getOrderId()}
					</DrawerHeader>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values) => submit(values)}
					>
						{(formik) => (
							<>
								<DrawerBody>
									<Flex direction='column' as='form' gap='1.5em' pb='2rem'>
										{/* Si no hay order el status se pone por defecto en pending */}
										{order && (
											<SelectField
												label='Estado'
												name='status'
												choices={options?.actions?.POST?.status?.choices}
												error={error?.response?.data?.status}
											/>
										)}
										<Flex align='end' justify='space-around' gap='4em'>
											<SelectFieldFilter
												required
												w={!isMobile ? "300px" : "65%"}
												label='Coche'
												icon={<BiCar size='20px' color='black' />}
												options={carChoices}
												name='car'
												error={error?.response?.data?.car || formik.errors?.car}
											/>
											<Button variant='primary' px='2em' size='sm' onClick={() => onOpenCar()}>
												+ Coche
											</Button>
										</Flex>
										{cars?.count === 0 && (
											<Text fontSize='14px' color='red'>
												Aún no hay coches
											</Text>
										)}
										<MultiSelectFieldFilter
											w={!isMobile ? "300px" : "65%"}
											label='Asignar detailer'
											icon={<GrUserWorker size='20px' />}
											options={workerChoices}
											name='workers'
											error={error?.response?.data?.workers || formik.errors?.workers}
										/>
										<MultiSelectFieldFilter
											w={!isMobile ? "300px" : "65%"}
											label='Servicios'
											icon={<MdCleaningServices size='20px' />}
											options={serviceChoices}
											name='services'
											error={error?.response?.data?.services || formik.errors?.services}
										/>
										<OrderDates
											absence={absence}
											absences={order?.client_absences}
											order={order}
											setAbsence={setAbsence}
											error={error}
											isMobile={isMobile}
										/>
										<ClientDescription client_desc={order?.client_desc} />
										<Divider mt='1em' color='gray' borderWidth='1px' borderRadius='10px' />
										<StockUsed
											isFree={isFree}
											formik={formik}
											items={items}
											error={error}
											order={order}
										/>
										{/*
                                        <Reminders
                                            carChoices={carChoices}
                                            reminders={order?.reminders}
                                            isFree={isFree}
                                            httperror={error?.response?.data?.reminder}
                                            formik={formik}
                                            isMobile={isMobile}
                                        />
                                        */}
									</Flex>
								</DrawerBody>
								<DrawerFooter>
									<Flex justify='space-between' align='center' w='100%'>
										<Text fontSize='24px' justifySelf='start'>
											{calculateTotal(formik.values, services?.results)}
										</Text>
										<Flex gap='1em'>
											<Button variant='white' size='md' onClick={onClose}>
												Cancelar
											</Button>
											<Button
												size='md'
												variant='primary'
												isDisabled={disableSubmit(formik)}
												onClick={formik.handleSubmit}
												isLoading={isLoading}
											>
												Guardar
											</Button>
										</Flex>
									</Flex>
								</DrawerFooter>
							</>
						)}
					</Formik>
				</DrawerContent>
			</Drawer>
		</>
	);
}
