import { useState, useEffect } from "react";
import { Button, useMediaQuery } from "@chakra-ui/react"
//types
import { BusinessService } from "../../../../../types/business";
//icons
import { FaCheck } from "react-icons/fa";

interface AddServiceButtonProps {
    service: BusinessService;
    selectedServices: BusinessService[];
    toggleService: (service:BusinessService) => void;
}

export default function AddServiceButton ({service, selectedServices, toggleService}: AddServiceButtonProps) {
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const [selected, setSelected] = useState<boolean>(selectedServices.some(s => s.id === service.id));

    useEffect(() => {
        setSelected(selectedServices.some(s => s.id === service.id));
    }, [selectedServices, service.id]);

    return(
        <Button w='80px' fontSize={!isMobile?'14px':'12px'}
            variant={selected?'lightGreen' : 'primary'} 
            onClick={()=>toggleService(service)} 
        >
            {selected?
                <FaCheck color='#42C74F' />
                :'Reservar'}
        </Button>
    )
}