import { Flex, Text, Highlight, useMediaQuery } from "@chakra-ui/react";
// comps
import PlanCard from "../../components/payments/PlanCard";
import MultiplePlanCard from "../../components/payments/MultiplePlanCard";

export default function PlanSelection() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const commonFeatures = [
		"Gestión de clientes",
		"Órdenes de trabajo y agenda",
		"Facturación y ventas",
		"Stock de productos",
		"Análisis de datos",
		"Historiales de vehículos",
		"Perfil de negocio con reservas online"
	];

	if (!isMobile) {
		return (
			<Flex mt='1em' w='100%' justify='center' gap='2em'>
				<MultiplePlanCard
					features={[...commonFeatures, "Recordatorios por WhatsApp (180 conversaciones al mes)"]}
				/>
				{/* 
				<PlanCard
					planName={
						<Text fontSize='22px' fontWeight='bold'>
							<Highlight query={"another"} styles={{ color: "darkblue" }}>
								another
							</Highlight>
							<Highlight query={"tool"} styles={{ color: "blue.500" }}>
								tool
							</Highlight>
							<span> </span>PRO
						</Text>
					}
					planId={"pro_monthly"}
					features={[...commonFeatures]}
					planDescription={
						"Exprime el potencial de tu centro de detailing. Deja de perder el tiempo en lo que no te gusta y genera más ingresos."
					}
					price={29}
				/>
				
				<PlanCard
					planName={
						<Text fontSize='22px' fontWeight='bold'>
							<Highlight query={"another"} styles={{ color: "darkblue" }}>
								another
							</Highlight>
							<Highlight query={"tool"} styles={{ color: "blue.500" }}>
								tool
							</Highlight>
							<span> </span>anual + WhatsApp
						</Text>
					}
					planId={"pro_whatsapp_annual"}
					features={[
						...commonFeatures,
						"Recordatorios por WhatsApp (120 conversaciones al mes)",
						"Videollamada con experto, onboarding + primeros pasos"
					]}
					extraFeatures={"¡1 Mensualidad Gratis!"}
					planDescription={
						"¿Estás disfrutando de gestionar tu negocio de manera tan simple e intuitiva? Pasate al plan anual y ahorra aún más."
					}
					price={450}
				/>
				<PlanCard
					planName={
						<Text fontSize='22px' fontWeight='bold'>
							<Highlight query={"another"} styles={{ color: "darkblue" }}>
								another
							</Highlight>
							<Highlight query={"tool"} styles={{ color: "blue.500" }}>
								tool
							</Highlight>
							<span> </span>anual
						</Text>
					}
					planId={"pro_annual"}
					features={[...commonFeatures, "Videollamada con experto, onboarding + primeros pasos"]}
					extraFeatures={"¡1 Mensualidad Gratis!"}
					planDescription={
						"¿Estás disfrutando de gestionar tu negocio de manera tan simple e intuitiva? Pasate al plan anual y ahorra aún más."
					}
					price={319}
				/>
				*/}
			</Flex>
		);
	}
	return (
		<Flex mt='1rem' pb='2rem' w='100%' direction='column' justify='center' align='center' gap='2rem'>
			<MultiplePlanCard
				features={[...commonFeatures, "Recordatorios por WhatsApp (180 conversaciones al mes)"]}
			/>
		</Flex>
	);
}
