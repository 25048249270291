import { useState } from "react";
import { Flex, useDisclosure, Text, Button, useToast, useMediaQuery } from "@chakra-ui/react";
// comps
import OrderForm from "../../components/pages/orders/forms/OrderForm";
import OrderCardList from "../../components/pages/orders/OrderCardList";
import OrderListFilter from "../../components/pages/orders/OrderListFilter";
import OrderWorkerFilter from "../../components/pages/orders/OrderWorkerFilter";
import OrderSortBySelect from "../../components/pages/orders/OrderSortBySelect";
import HelperModal from "../../components/tutorial/HelperModal";
// api
import { updateOrderStatus } from "../../api/ordersApi";
import useAuthQuery from "../../myHooks/useAuthQuery";
import { useQueryClient } from "@tanstack/react-query";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function Orders() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [period, setPeriod] = useState("all");
	const [sortBy, setSortBy] = useState("created_at");
	const [ordering, setOrdering] = useState("desc");
	const [selectedWorkers, setSelectedWorkers] = useState([]);
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();
	const toast = useToast();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	useAuthQuery({
		queryKey: ["updateOrderStatus"],
		queryFn: () => updateOrderStatus(authHeader()),
		onSuccess: (data) => {
			if (data.started) {
				toast({ title: data.started, status: "success" });
				QueryClient.invalidateQueries(["startedorders"]);
				QueryClient.refetchQueries("startedorders", { force: true });
			}
			if (data.completed) {
				QueryClient.invalidateQueries(["completedorders"]);
				QueryClient.refetchQueries("completedorders", { force: true });
				toast({ title: data.completed, status: "success" });
			}
			if (data.waiting) {
				QueryClient.invalidateQueries(["waitingorders"]);
				QueryClient.refetchQueries("waitingorders", { force: true });
				toast({ title: data.waiting, status: "success" });
			}
		}
	});

	if (!isMobile) {
		return (
			<>
				<Flex p='2em' w='100%' direction='column'>
					<Flex align='center' justify='space-between'>
						<Flex align='center'>
							<Text fontSize='24px' fontWeight='bold' mr='2em'>
								Órdenes
							</Text>
							<Button variant='primary' onClick={() => onOpen()}>
								+ Orden de trabajo
							</Button>
						</Flex>
						<Flex align='center' gap='1em'>
							<OrderSortBySelect
								sortBy={sortBy}
								setSortBy={setSortBy}
								ordering={ordering}
								setOrdering={setOrdering}
							/>
							<OrderWorkerFilter setSelectedWorkers={setSelectedWorkers} />
							<OrderListFilter period={period} setPeriod={setPeriod} justifySelf='end' />
						</Flex>
					</Flex>
					<Flex w='100%' justify='space-evenly' gap='1em' align='top' maxH='80vh'>
						<OrderCardList
							period={period}
							sortBy={sortBy}
							ordering={ordering}
							workers={selectedWorkers}
							type='pending'
						/>
						<OrderCardList
							period={period}
							sortBy={sortBy}
							ordering={ordering}
							workers={selectedWorkers}
							type='waiting'
						/>
						<OrderCardList
							period={period}
							sortBy={sortBy}
							ordering={ordering}
							workers={selectedWorkers}
							type='started'
						/>
						<OrderCardList
							period={period}
							sortBy={sortBy}
							ordering={ordering}
							workers={selectedWorkers}
							type='completed'
						/>
					</Flex>
				</Flex>
				<HelperModal />
				<OrderForm isOpen={isOpen} onClose={onClose} />
			</>
		);
	}
	return (
		<>
			<Flex align='center' my='2em' justify='space-evenly'>
				<Text fontSize='24px' fontWeight='bold'>
					Órdenes
				</Text>
				<Button variant='primary' onClick={() => onOpen()}>
					+ Orden de trabajo
				</Button>
			</Flex>
			<Flex w='100%' direction='column' gap='1em' px='1em'>
				<OrderCardList period={period} type='pending' />
				<OrderCardList period={period} type='waiting' />
				<OrderCardList period={period} type='started' />
				<OrderCardList period={period} type='completed' />
			</Flex>
			<OrderForm isOpen={isOpen} onClose={onClose} />
		</>
	);
}
