import { useState, useRef } from "react";
import {
	useDisclosure,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	Flex
} from "@chakra-ui/react";
import SignatureCanvas from "react-signature-canvas";

export default function BusinessSignatureModal({ formik, setSignatureData }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const canvasRef = useRef(null); // Reference to the canvas element

	const dataURLtoFile = (dataurl, filename) => {
		const [mimePart, dataPart] = dataurl.split(",");
		const mime = mimePart.match(/:(.*?);/)[1];
		const bstr = atob(dataPart);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	};

	const clear = () => {
		setSignatureData(null);
		canvasRef.current.clear();
	};

	const save = () => {
		try {
			const signatureImage = canvasRef.current.toDataURL("image/png");
			setSignatureData(canvasRef.current.getTrimmedCanvas().toDataURL("image/png"));
			const signatureFile = dataURLtoFile(signatureImage, "signature.png");
			formik.setFieldValue("signature", signatureFile);
			onClose();
		} catch (error) {
			console.log("Error guardando", error);
		}
	};

	return (
		<>
			<Button variant='primary' onClick={onOpen}>
				FIRMAR
			</Button>
			<Modal isOpen={isOpen} onClose={onClose} size='xl'>
				<ModalOverlay />
				<ModalContent bg='white'>
					<ModalHeader>Firmar de tu negocio</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Flex mt='1rem' w='380px' p='0.2rem' h='200px' borderRadius='8px' border='1px solid black'>
							<SignatureCanvas
								ref={canvasRef}
								penColor='black'
								backgroundColor='white'
								canvasProps={{
									width: "350px",
									height: "200px",
									className: "sigCanvas"
								}}
							/>
						</Flex>
					</ModalBody>
					<ModalFooter justifyContent='center'>
						<Button onClick={save} isDisabled={formik.values.signature}>
							Guardar
						</Button>
						<Button onClick={clear} isDisabled={!formik.values.signature}>
							Borrar
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
