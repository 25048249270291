import { Fragment } from "react";
import {
	Flex,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	useMediaQuery,
	useDisclosure,
	IconButton
} from "@chakra-ui/react";
// comps
import LoadingSpinner from "../../../common/LoadingSpinner";
import PurchaseCard from "./PurchaseCard";
// icons
import { FaHistory } from "react-icons/fa";
import { MdOutlineMail, MdOutlinePhone } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
// api
import useAuthQuery from "../../../../myHooks/useAuthQuery";
import { getSupplierPurchases } from "../../../../api/stockApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function SupplierPurchasesModal({ supplier }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const authHeader = useAuthHeader();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const { data, isLoading } = useAuthQuery({
		queryKey: ["supplierPurchases", supplier?.id],
		queryFn: () => getSupplierPurchases({ token: authHeader(), supplierId: supplier?.id }),
		enabled: !!supplier && isOpen
	});
	const isEmpty = data?.length < 1;
	return (
		<>
			<IconButton bg='transparent' icon={<FaHistory color='#0258FF' />} onClick={onOpen} />
			<Modal isOpen={isOpen} bg='white' onClose={onClose} size={isMobile ? "full" : "xl"}>
				<ModalOverlay />
				<ModalContent maxH='90vh'>
					<ModalHeader fontSize='18px'>
						<span style={{ color: "#001234" }}>Historial del proveedor</span> {supplier?.name}
					</ModalHeader>
					<ModalCloseButton />

					<ModalBody p={isMobile ? "1em" : "2em"}>
						<Flex justify='space-between' mb='1.5rem'>
							<Flex gap='0.5rem' align='center'>
								<MdOutlineMail size='22px' />
								<Text>{supplier.email || "-- --"}</Text>
							</Flex>
							<Flex gap='0.5rem' align='center'>
								<MdOutlinePhone size='22px' />
								<Text>{supplier.phone || "-- --"}</Text>
							</Flex>
							<Flex gap='0.5rem' align='center'>
								<IoLocationOutline size='22px' />
								<Text maxW='250px' overflow={"hidden"} textOverflow={"ellipsis"}>
									{supplier.address || "-- --"}
								</Text>
							</Flex>
						</Flex>
						{!isEmpty ? (
							<Flex
								direction='column'
								gap='1rem'
								maxH='60vh'
								overflowY='scroll'
								sx={{
									paddingRight: "0.2rem",
									"&::-webkit-scrollbar": { width: "5px", height: "8px" },
									"&::-webkit-scrollbar-track": {
										backgroundColor: "#f5f5f5"
									},
									"&::-webkit-scrollbar-thumb": {
										backgroundColor: "#cfcfcf"
									}
								}}
							>
								{data?.map((purchase) => (
									<Fragment key={purchase.id}>
										<PurchaseCard purchase={purchase} />
									</Fragment>
								))}
							</Flex>
						) : (
							<Text>No le has comprado nada a éste proveedor</Text>
						)}
						{isMobile && (
							<Flex pb='1em' direction='column' gap='0.5em'>
								<Text>Formato movil</Text>
							</Flex>
						)}
						{isLoading && <LoadingSpinner />}
					</ModalBody>
					<ModalFooter>
						<Text> TOTAL GASTADO:</Text>
						<Text color='darkblue' ml='0.5rem' fontSize='18px' fontWeight='semibold'>
							{supplier?.total_spent || 0}€{" "}
						</Text>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
