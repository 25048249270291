import { useState, useMemo, useCallback } from "react";
import {
	useToast,
	useDisclosure,
	Flex,
	Text,
	Box,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon
} from "@chakra-ui/react";
import moment from "moment";
// comps
import TableMobile from "../../../../common/Tables/Mobile/TableMobile";
import OrderForm from "../../../orders/forms/OrderForm";
import PopoverDelete from "../../../../common/PopoverDelete";
import OrderStatusView from "../../../orders/OrderStatusView";
import EditItemButton from "../../../../common/Tables/EditItemButton";
// icons
import { LuUser } from "react-icons/lu";
import { PiCarProfile } from "react-icons/pi";
// img
import emptyOrders from "../../../../../img/data/emptyOrders.png";
// api
import useAuthMutation from "../../../../../myHooks/useAuthMutation";
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import { useQueryClient } from "@tanstack/react-query";
import { getAllOrdersPag, deleteOrder } from "../../../../../api/ordersApi";
// auth

export default function OrdersTableMobile2() {
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const [order, setOrder] = useState();
	const QueryClient = useQueryClient();

	const { data, isLoading } = useAuthFetch(["orders", page], getAllOrdersPag, page);

	const { isLoading: ld, mutate } = useAuthMutation({
		mutationFn: deleteOrder,
		onSuccess: () => {
			toast({ title: "Borrado con exito!", status: "success" });
			QueryClient.invalidateQueries(["orders"]);
			QueryClient.refetchQueries("orders", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});

	const openEdit = (item) => {
		setOrder(item);
		onOpen();
	};
	const getOrderId = (order) => {
		const id = order.incremental_id >= 0 ? order.incremental_id : order.id;
		return id.toString().padStart(5, "0");
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((order) => (
				<AccordionItem key={order.id} bg='white' rounded='xl' border='none' mb='0.5em'>
					<AccordionButton bg='white' rounded='xl' w='100%' _hover={{ bg: "white" }}>
						<Box as='span' flex='1' textAlign='left' fontSize='14px' color='darkblue' fontWeight='bold'>
							Orden #{getOrderId(order)}
						</Box>
						<Box as='span' flex='1'>
							<OrderStatusView status={order.status} fontSize='14px' />
						</Box>
						<AccordionIcon boxSize='25px' />
					</AccordionButton>
					<AccordionPanel pb='0em'>
						<Flex
							w='100%'
							h='100%'
							direction='column'
							gap='1em'
							justify='space-between'
							fontSize='14px'
							p='0.5em'
						>
							<Flex gap='0.5em' align='center'>
								<LuUser size='15px' color='black' />
								<Text>{order.client?.name}</Text>
							</Flex>
							<Flex gap='0.5em' align='center'>
								<PiCarProfile size='18px' color='black' />
								<Text>
									{order.car?.brand} {order.car?.model}
								</Text>
							</Flex>
							<Text>Entrada {order.date_in ? moment(order.date_in).format("lll") : " -- --"}</Text>
							<Text>Salida {order.date_out ? moment(order.date_out).format("lll") : " -- --"}</Text>
							<Flex w='100%' justify='end' mt='-3em'>
								<EditItemButton editFn={openEdit} item={order} />
								<PopoverDelete
									deleteKey={["deleteOrder"]}
									deleteFn={deleteOrder}
									slug={order?.id}
									refetchKey={["orders"]}
								/>
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			)),
		[data, ld]
	);
	const openForm = () => {
		setOrder();
		onOpen();
	};
	return (
		<>
			<TableMobile
				data={data}
				tableData={tableData}
				openFormFn={openForm}
				isLoading={isLoading}
				emptyImg={emptyOrders}
				label='Órden'
				page={page}
				setPage={setPage}
			/>
			<OrderForm isOpen={isOpen} item={order} onClose={onClose} />
		</>
	);
}
