import { Flex, Text, useMediaQuery } from "@chakra-ui/react";
// comps
import Plan from "../../components/pages/settings/plan/Plan";

export default function Settings() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	if (!isMobile) {
		return (
			<Flex
				direction='column'
				mt='2rem'
				width={{ base: "100%", md: "650px", lg: "850px", xl: "1200px", "2xl": "1350px" }}
			>
				<Text fontSize='32px' fontWeight='bold'>
					Ajustes
				</Text>
				<Plan />
			</Flex>
		);
	}
	return <Flex>Seettings mobile</Flex>;
}
