import axios from "axios";

export const createApi = (endpoint) => {
	const api = axios.create({
		baseURL: process.env.REACT_APP_API_URL + endpoint
	});
	return api;
};

export const getAll = async (api, payload) => {
	const slug = "/?page_size=1000";
	const response = await api.get(slug, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const getAllPag = async (api, payload) => {
	let slug = "/";
	if (payload.page) {
		slug = `/?page=${payload.page}`;
	}
	if (payload.searchQuery) {
		slug = `/?search=${payload.searchQuery}`;
	}
	if (payload.filter) {
		slug = `${slug}${payload.filter}`;
	}
	const response = await api.get(slug, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const createUpdate = async (api, payload) => {
	let response = {};

	if (payload.slug) {
		response = await api.put(`/${payload.slug}/`, payload.data, {
			headers: { Authorization: payload.token }
		});
	} else {
		response = await api.post("/", payload.data, {
			headers: { Authorization: payload.token }
		});
	}
	return response.data;
};
export const deleteItem = async (api, payload) => {
	const response = await api.delete(`/${payload.slug}/`, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const getOptions = async (api, payload) => {
	const response = await api.options("/", {
		headers: { Authorization: payload.token }
	});
	return response.data;
};
