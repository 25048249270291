import { useState } from "react";
import { useToast, useDisclosure, Button, IconButton, Flex } from "@chakra-ui/react";
import moment from "moment/moment";
// comps
import LoadingSpinner from "../../common/LoadingSpinner";
import PaginationMenu from "../../common/Tables/PaginationMenu";
import PopoverDelete from "../../common/PopoverDelete";
import UserCardMobile from "./UserCardMobile";
import UserCard from "./UserCardMobile.jsx";
// icons
import { AiOutlineEye } from "react-icons/ai";
// api
import useAuthMutation from "../../../myHooks/useAuthMutation";
import useAuthQuery from "../../../myHooks/useAuthQuery";
import { useQueryClient } from "@tanstack/react-query";
import { getAllUsers, deleteUser, updateUser, getAllPlans, updateUserPlan } from "../../../api/adminApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function UsersTableMobile() {
	const toast = useToast();
	const [user, setUser] = useState("");
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data, isLoading } = useAuthQuery({
		queryKey: ["users", page],
		queryFn: () => getAllUsers({ auth: authHeader(), page })
	});
	const { isLoading: ld, mutate: removeUser } = useAuthMutation({
		mutationFn: deleteUser,
		onSuccess: () => {
			toast({ title: "Borrado con exito!", status: "success" });
			QueryClient.invalidateQueries(["users"]);
			QueryClient.refetchQueries("users", { force: true });
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});

	return (
		<>
			<Flex direction='column' gap='1em' mb='1em'>
				{data?.results?.map((user) => (
					<UserCard user={user} key={user.id} />
				))}
			</Flex>
			{isLoading && <LoadingSpinner />}
			<PaginationMenu total={data?.count} next={data?.next} prev={data?.previous} page={page} setPage={setPage} />
			<UserDetail isOpen={isOpen} onClose={onClose} user={user} />
		</>
	);
}
