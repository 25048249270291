import { PDFViewer, Page, Text, View, Document } from "@react-pdf/renderer";
import { styles } from "../PDFStyle";
import moment from "moment/moment";
// comps
import InvoicePDFHeader from "./InvoicePDFHeader";
// fs
import { formatDecimals } from "../../../../common/functions";

export default function InvoicePDF({ invoice, invoiceSettings, businessProfile }) {
	const discount = invoice?.discount > 0 ? (invoice.discount / 100) * invoice.subtotal : 0;
	return (
		<PDFViewer width='100%' height='100%'>
			<Document title={`Factura ${invoice?.invoice_number}`}>
				<Page size='A4' style={styles.page}>
					<InvoicePDFHeader
						invoiceSettings={invoiceSettings}
						businessProfile={businessProfile}
						invoice={invoice}
					/>
					{/* Invoice description */}
					<View
						style={{
							borderTop: "1px solid #F4F4F9",
							borderBottom: "1px solid #F4F4F9",
							paddingHorizontal: "10",
							paddingVertical: "5px",
							marginTop: "30px",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							color: "#737A87",
							fontSize: "10px"
						}}
					>
						<View style={{ width: "40%" }}>
							<Text>ITEM</Text>
						</View>
						<View
							style={{
								width: "10%",
								textAlign: "right"
							}}
						>
							<Text>CANT.</Text>
						</View>
						<View
							style={{
								width: "15%",
								textAlign: "right"
							}}
						>
							<Text>PRECIO</Text>
						</View>
						<View
							style={{
								width: "20%",
								textAlign: "right"
							}}
						>
							<Text>IVA(€)</Text>
						</View>
						<View
							style={{
								width: "15%",
								textAlign: "right"
							}}
						>
							<Text>TOTAL(€)</Text>
						</View>
					</View>
					{invoice?.items?.map((item, index) => (
						<View style={[styles.invoiceRow, index % 2 !== 0 && styles.evenRow]} key={item.id}>
							<View style={{ width: "40%" }}>
								<Text>{item?.concept}</Text>
							</View>
							<View
								style={{
									width: "10%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.quantity)}</Text>
							</View>
							<View
								style={{
									width: "15%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.price)}</Text>
							</View>
							<View
								style={{
									width: "20%",
									textAlign: "right"
								}}
							>
								<Text>
									{formatDecimals(item.taxes)} {invoice.taxes > 0 && `(${invoice.tax_display})`}
								</Text>
							</View>
							<View
								style={{
									width: "15%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.total)}</Text>
							</View>
						</View>
					))}
					{invoice?.stock_used?.map((item, index) => (
						<View style={[styles.invoiceRow, index % 2 !== 0 && styles.evenRow]} key={item.id}>
							<View style={{ width: "40%" }}>
								<Text>{item?.name}</Text>
							</View>
							<View
								style={{
									width: "10%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.quantity)}</Text>
							</View>
							<View
								style={{
									width: "15%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.price)}</Text>
							</View>
							<View
								style={{
									width: "20%",
									textAlign: "right"
								}}
							>
								<Text>21%</Text>
							</View>
							<View
								style={{
									width: "15%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.total)}</Text>
							</View>
						</View>
					))}

					{/* Resumen */}
					<View
						style={{
							flexDirection: "row",
							justifyContent: "flex-end",
							marginTop: "50px"
						}}
					>
						<View
							style={{
								flexDirection: "column",
								gap: "10px",
								alignItems: "flex-end"
							}}
						>
							{invoice?.discount > 0 && (
								<>
									<View style={styles.resumeRow}>
										<Text>Subtotal</Text>
										<Text>{formatDecimals(invoice?.subtotal)}€</Text>
									</View>

									<View style={styles.resumeRow}>
										<Text>Descuento {invoice?.discount}%</Text>
										<Text>{discount.toFixed(2)}€</Text>
									</View>
								</>
							)}
							<View style={styles.resumeRow}>
								<Text>Base Imponible</Text>
								<Text>{formatDecimals(invoice?.subtotal - discount)}€</Text>
							</View>
							<View style={styles.resumeRow}>
								<Text>IVA</Text>
								<Text>{formatDecimals(invoice?.taxes)}€</Text>
							</View>
							<View style={styles.resumeRow}>
								<Text>Total</Text>
								<Text style={{ fontWeight: "semibold" }}>{formatDecimals(invoice?.total)}€</Text>
							</View>
						</View>
					</View>

					{/* Forma de pago etc */}
					<View
						style={{
							flexDirection: "column",
							position: "absolute",
							bottom: "35px",
							left: "35px",
							gap: "10px"
						}}
					>
						<Text style={{ color: "#0258FF", fontWeight: "medium" }}>Detalles Pago</Text>
						{invoiceSettings?.invoice_expiration && (
							<Text>
								El pago se realizará en un plazo de {invoiceSettings?.invoice_expiration} días (
								{moment(invoice?.expiring_date).format("L")}){" "}
							</Text>
						)}
						{invoiceSettings?.IBAN && <Text>IBAN {invoiceSettings?.IBAN}</Text>}
					</View>
				</Page>
			</Document>
		</PDFViewer>
	);
}
