import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton } from "@chakra-ui/react";
import { PDFViewer, Page, Text, View, Document, Image } from "@react-pdf/renderer";

import moment from "moment/moment";
// comps
import InvoicePDFHeader from "./invoice/InvoicePDFHeader";
// fs
import { formatDecimals } from "../../../common/functions";
import { styles } from "./PDFStyle";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getInvoiceSettings } from "../../../../api/invoicesApi";
import { getBusinessProfile } from "../../../../api/businessesApi";
// img
import logo from "../../../../img/logos/logo_navbar.png";
// auth
import { useAuthUser } from "react-auth-kit";

export default function MultiInvoicePDFModal({ invoice, isOpen, onClose }) {
	const auth = useAuthUser();

	const { data: invoiceSettings } = useAuthFetch(["invoiceSettings"], getInvoiceSettings, null, null, !!isOpen);
	const { data: businessProfile } = useAuthFetch(["businessProfile"], getBusinessProfile, null, null, !!isOpen);

	const getTaxValue = (item) => {
		let tax = 0;
		if (item?.tax === "twenty") {
			tax = (item.price * item.quantity * 0.21).toFixed(2);
			return `${tax} (21%)`;
		} else if (item?.tax === "ten") {
			tax = (item.price * item.quantity * 0.1).toFixed(2);
			return `${tax} (10%)`;
		} else if (item?.tax === "exent") {
			return "Exento";
		} else if (item?.tax === "no") {
			return "No sujeto";
		}
	};
	const groupedItems = invoice?.items.reduce((acc, item) => {
		const orderId = item.order?.id;
		if (!acc[orderId]) {
			acc[orderId] = [];
		}
		acc[orderId].push(item);
		return acc;
	}, {});
	const isFree = auth().plan === "FREE";
	const littleColsWidth = "10%";
	const taxColWidth = "15%";
	const orderColWidth = "25%";
	const itemColWidth = "25%";
	return (
		<Modal isOpen={isOpen} onClose={onClose} size='5xl'>
			<ModalOverlay />
			<ModalContent h='90vh'>
				<ModalHeader>Visor de Factura {invoice?.invoice_number}</ModalHeader>
				<ModalCloseButton />
				<PDFViewer width='100%' height='100%'>
					<Document title={"Factura" + invoice?.invoice_number}>
						<Page size='A4' style={styles.page}>
							<InvoicePDFHeader invoiceSettings={invoiceSettings} businessProfile={businessProfile} />
							{/* Invoice description */}
							<View style={styles.invoiceHeader}>
								<View style={{ width: orderColWidth }}>
									<Text>Orden</Text>
								</View>
								<View style={{ width: itemColWidth }}>
									<Text>Item</Text>
								</View>
								<View
									style={{
										width: littleColsWidth,
										textAlign: "right"
									}}
								>
									<Text>Cant.</Text>
								</View>
								<View
									style={{
										width: littleColsWidth,
										textAlign: "right"
									}}
								>
									<Text>Precio</Text>
								</View>
								<View
									style={{
										width: taxColWidth,
										textAlign: "right"
									}}
								>
									<Text>IVA</Text>
								</View>
								<View
									style={{
										width: littleColsWidth,
										textAlign: "right"
									}}
								>
									<Text>Total</Text>
								</View>
							</View>
							{groupedItems &&
								Object.keys(groupedItems)?.map((orderId) => {
									const items = groupedItems[orderId];
									return (
										<View
											style={[
												styles.invoiceRow,
												{ borderBottomWidth: 2, borderColor: "#F7F7F7", paddingBottom: "10px" }
											]}
										>
											<View
												style={{ width: orderColWidth, flexDirection: "col", flexWrap: "wrap" }}
											>
												<Text style={{ width: "90%" }}>
													{items[0]?.order?.car?.brand} {items[0]?.order?.car?.model}{" "}
												</Text>
												<Text>{items[0]?.order?.car?.plate}</Text>
												<Text style={{ marginTop: "5px" }}>
													{moment(items[0]?.order?.date_in).format("l")}
												</Text>
											</View>
											<View style={{ width: itemColWidth }}>
												{items.map((item, index) => (
													<Text key={index} style={index !== 0 && { marginTop: "10px" }}>
														{item.concept}
													</Text>
												))}
											</View>
											<View style={{ width: littleColsWidth, textAlign: "right" }}>
												{items.map((item, index) => (
													<Text key={index} style={index !== 0 && { marginTop: "10px" }}>
														{formatDecimals(item.quantity)}
													</Text>
												))}
											</View>
											<View style={{ width: littleColsWidth, textAlign: "right" }}>
												{items.map((item, index) => (
													<Text key={index} style={index !== 0 && { marginTop: "10px" }}>
														{formatDecimals(item.price)}
													</Text>
												))}
											</View>
											<View style={{ width: taxColWidth, textAlign: "right" }}>
												{items.map((item, index) => (
													<Text
														key={index}
														style={[
															{ textAlign: "right" },
															index !== 0 && { marginTop: "10px" }
														]}
													>
														{getTaxValue(item)}
													</Text>
												))}
											</View>
											<View style={{ width: littleColsWidth, textAlign: "right" }}>
												{items.map((item, index) => (
													<Text key={index} style={index !== 0 && { marginTop: "10px" }}>
														{item.price * item.quantity}
													</Text>
												))}
											</View>
										</View>
									);
								})}
							{/* Resumen */}
							<View
								style={{
									flexDirection: "row",
									justifyContent: "flex-end",
									paddingHorizontal: 20,
									marginTop: "20px",
									gap: "40px"
								}}
							>
								<View
									style={{
										flexDirection: "column",
										gap: "5px",
										alignItems: "flex-end"
									}}
								>
									{invoice?.discount > 0 && (
										<View
											style={{
												flexDirection: "row",
												justifyContent: "space-between",
												width: "250px",
												padding: "10px",
												fontSize: "12px"
											}}
										>
											<Text>DESCUENTO {invoice?.discount}%</Text>
											<Text>{((invoice?.discount / 100) * invoice?.total).toFixed(2)}€</Text>
										</View>
									)}
									<View
										style={{
											flexDirection: "row",
											justifyContent: "space-between",
											width: "250px",
											padding: "10px",
											fontSize: "12px"
										}}
									>
										<Text>SUBTOTAL</Text>
										<Text>{(invoice?.total - invoice?.taxes).toFixed(2)}€</Text>
									</View>
									<View
										style={{
											flexDirection: "row",
											justifyContent: "space-between",
											width: "250px",
											padding: "10px",
											fontSize: "12px"
										}}
									>
										<Text>IVA</Text>
										<Text>{invoice?.taxes}€</Text>
									</View>
									<View
										style={{
											flexDirection: "row",
											justifyContent: "space-between",
											width: "250px",
											color: "black",
											backgroundColor: "#F7F7F7",
											padding: "10px",
											fontSize: "12px",
											borderRadius: "5px"
										}}
									>
										<Text>TOTAL</Text>
										<Text>{invoice?.total}€</Text>
									</View>
								</View>
							</View>

							{isFree && (
								<View
									style={{
										flexDirection: "column",
										position: "absolute",
										bottom: "35px",
										right: "35px",
										gap: "10px"
									}}
								>
									<Text>Factura generada con</Text>
									<Image style={{ width: "100px" }} src={logo} />
								</View>
							)}
							{/* Forma de pago etc */}
							<View
								style={{
									flexDirection: "column",
									position: "absolute",
									bottom: "35px",
									left: "35px",
									gap: "10px"
								}}
							>
								<Text style={{ color: "#0258FF", fontWeight: 900 }}>Detalles Pago</Text>
								{invoiceSettings?.invoice_expiration && (
									<Text>
										El pago se realizará en un plazo de {invoiceSettings?.invoice_expiration} días (
										{moment(invoice?.expiring_date).format("L")}){" "}
									</Text>
								)}
								{invoiceSettings?.IBAN && <Text>IBAN {invoiceSettings?.IBAN}</Text>}
							</View>
						</Page>
					</Document>
				</PDFViewer>
			</ModalContent>
		</Modal>
	);
}
