import { useState, useMemo, useCallback } from "react";
import {
	useToast,
	useDisclosure,
	Flex,
	Text,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon
} from "@chakra-ui/react";
import moment from "moment";
// comps
import InvoiceStatusBadge from "../InvoiceStatusBadge";
import InvoiceModal from "../../invoicing/forms/InvoiceModal";
import TableMobile from "../../../common/Tables/Mobile/TableMobile";
import PopoverDelete from "../../../common/PopoverDelete";
import EditItemButton from "../../../common/Tables/EditItemButton";
// icons
import { LuUser } from "react-icons/lu";
// img
import emptyInvoices from "../../../../img/invoicing/emptyInvoices.png";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { getAllInvoicesPag, deleteInvoice } from "../../../../api/invoicesApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function InvoicesTableMobile() {
	const toast = useToast();
	const [invoice, setInvoice] = useState();
	const { isOpen: isOpenForm, onOpen: onOpenForm, onClose: onCloseForm } = useDisclosure();
	const { isOpen: isOpenPDF, onOpen: onOpenPDF, onClose: onClosePDF } = useDisclosure();

	const [page, setPage] = useState(1);
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data, isLoading } = useAuthFetch(["invoices", page], getAllInvoicesPag, page);

	const { mutate, isLoading: ld } = useAuthMutation({
		queryFn: deleteInvoice,
		onSuccess: () => {
			toast({ title: "Borrado con exito!", status: "success" });
			QueryClient.invalidateQueries(["invoices"]);
			QueryClient.refetchQueries("invoices", { force: true });
			onCloseForm();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const deleteItem = useCallback(
		(id) => {
			mutate({ slug: id, token: authHeader() });
		},
		[authHeader, mutate]
	);
	const openEdit = (item) => {
		setInvoice(item);
		onOpenForm();
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((invoice) => (
				<AccordionItem key={invoice.id} bg='white' rounded='xl' border='none' mb='0.5em'>
					<AccordionButton bg='white' rounded='xl' w='100%' _hover={{ bg: "white" }}>
						<Flex w='100%' align='center' justify='space-between' fontSize='14px' pr='1em'>
							<Text>{invoice.invoice_number}</Text>
							<Text>{invoice.total}€</Text>
							<InvoiceStatusBadge status={invoice.status} />
						</Flex>
						<AccordionIcon boxSize='25px' />
					</AccordionButton>
					<AccordionPanel pb='0em'>
						<Flex
							w='100%'
							h='100%'
							direction='column'
							gap='1em'
							justify='space-between'
							fontSize='14px'
							p='0.5em'
						>
							<Flex gap='0.5em' align='center'>
								<LuUser size='15px' color='black' />
								<Text>{invoice.client.name}</Text>
							</Flex>
							<Text>Creada {invoice.date ? moment(invoice.date).format("lll") : " -- --"}</Text>
							<Text>
								Expira {invoice.expiring_date ? moment(invoice.expiring_date).format("lll") : " -- --"}
							</Text>
							<Text>IVA: {invoice.taxes}</Text>
							<Text>
								<span style={{ fontWeight: "bold" }}>Total:</span> {invoice.total}€
							</Text>
							<Flex w='100%' justify='end' mt='-2em'>
								{/*
                                <IconButton
                                    icon={<AiOutlineFilePdf size="20px" color="black" />}
                                    bg="transparent"
                                    onClick={()=>handleOpenPDF(invoice)}
                                />
                                */}
								{!invoice.is_multi && <EditItemButton editFn={openEdit} item={invoice} />}
								<PopoverDelete deleteFn={deleteItem} slug={invoice.id} isLoading={ld} />
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			)),
		[data, onOpenForm, deleteItem, ld]
	);
	const openForm = () => {
		setInvoice();
		onOpenForm();
	};

	return (
		<>
			<InvoiceModal item={invoice} onClose={onCloseForm} isOpen={isOpenForm} />
			<TableMobile
				data={data}
				tableData={tableData}
				openFormFn={openForm}
				isLoading={isLoading}
				emptyImg={emptyInvoices}
				label='Factura'
				page={page}
				setPage={setPage}
			/>
		</>
	);
}
