import { Flex, Text } from '@chakra-ui/react'
// comps
import ExtraPlanCard from './ExtraPlanCard'
import PlanCard from './PlanCard'
// api
import useAuthFetch from '../../../../myHooks/useAuthFetch'
import { getUserPlan, getExtraPlans } from '../../../../api/plansApi'

export default function Plan () {
    const { data: userplan } = useAuthFetch(['userplan'], getUserPlan)
    const { data: extraplans } = useAuthFetch(['extraplans'], getExtraPlans)
    const planName = userplan?.plan?.name
    const hasExtraPlans = extraplans?.length > 0

    return (
        <Flex direction='column' w='100%' bg='white' rounded='lg' mt='2rem' p='2rem'>
            <Flex direction='column'>
                <Text fontWeight='bold' mb='1rem' fontSize='18px' >Tu plan actual</Text>
                <PlanCard
                    userplan={userplan}
                />
            </Flex>
            <Flex w='100%' mt='2rem' >
                {extraplans?.map((extraPlan) => (
                    <ExtraPlanCard
                        key={extraPlan.id}
                        extraPlan={extraPlan}
                    />
                ))}
            </Flex>
        </Flex>
    )
}
