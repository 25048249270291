import { Flex, Text } from "@chakra-ui/react";
import moment from "moment";

export default function ExtraPlanCard({ extraPlan }) {
	return (
		<Flex p='2rem' bg='lightgrey' direction='column' align='start' border='0.5px solid #D9D7D7' rounded='lg'>
			<Text fontWeight='bold'>Paquete {extraPlan.plan.name}</Text>
			<Text fontSize='14px' align='center'>
				Proximo pago el {moment(extraPlan.expires_at).format("L")}
			</Text>
		</Flex>
	);
}
