import { useMemo } from "react";
import {
	Flex,
	Image,
	IconButton,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useMediaQuery,
	useDisclosure
} from "@chakra-ui/react";

// comps
import NavItem from "./NavItem";
// img
import logo from "../../img/brand/at_white.png";
// icons
import { AiOutlineDashboard } from "react-icons/ai";
import {
	MdOutlineAdminPanelSettings,
	MdOutlineInventory2,
	MdOutlinePendingActions,
	MdOutlineNotificationsActive
} from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
import { LuFile } from "react-icons/lu";

import { RxHamburgerMenu } from "react-icons/rx";
import { FaChartLine } from "react-icons/fa6";
import { PiCarProfile, PiUserListBold } from "react-icons/pi";
import { BsShop } from "react-icons/bs";
// auth
import { useAuthUser } from "react-auth-kit";

export default function Navbar({ userplan }) {
	const auth = useAuthUser();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const isStaff = auth().is_staff;

	const planExpired = userplan?.has_expired && !isStaff && userplan?.plan?.name !== "BETA";
	const disabledStyle = useMemo(
		() => ({
			cursor: planExpired ? "not-allowed" : "auto",
			filter: planExpired ? "grayscale(70%)" : "none"
		}),
		[planExpired]
	);

	if (!isMobile) {
		return (
			<Flex
				w='100px'
				direction='column'
				bg='darkblue'
				minH='100vh'
				h='100%'
				{...disabledStyle}
				position='sticky'
				top='0'
			>
				<Image src={logo} boxSize='30px' alignSelf='center' my='2em' />
				<Flex direction='column' gap='1em'>
					{isStaff && (
						<>
							<NavItem icon={MdOutlinePendingActions} title='Actions' slash='/admin/user-actions' />
							<NavItem icon={MdOutlineAdminPanelSettings} title='Users' slash='/admin/users-management' />
							<NavItem icon={MdOutlineAdminPanelSettings} title='Mapa' slash='/admin/businesses-map' />
							<hr />
						</>
					)}
					<NavItem
						icon={AiOutlineDashboard}
						title='Panel'
						slash='/dashboard'
						pointerEvents={planExpired ? "none" : "auto"}
					/>
					<NavItem
						icon={LuFile}
						title='Ordenes'
						slash='/ordenes'
						pointerEvents={planExpired ? "none" : "auto"}
					/>
					<NavItem
						icon={PiUserListBold}
						title='Datos'
						slash='/datos'
						pointerEvents={planExpired ? "none" : "auto"}
					/>
					<NavItem
						icon={TbFileInvoice}
						title='Facturas'
						slash='/facturas'
						pointerEvents={planExpired ? "none" : "auto"}
					/>
					<NavItem
						icon={MdOutlineInventory2}
						title='Stock'
						slash='/stock'
						pointerEvents={planExpired ? "none" : "auto"}
					/>

					<NavItem
						icon={BsShop}
						title='Negocio'
						slash='/negocio'
						pointerEvents={planExpired ? "none" : "auto"}
					/>
					<NavItem
						icon={MdOutlineNotificationsActive}
						title='Msgs'
						slash='/comunicacion'
						pointerEvents={planExpired ? "none" : "auto"}
					/>
					<NavItem
						icon={FaChartLine}
						title='Métricas'
						slash='/estadisticas'
						pointerEvents={planExpired ? "none" : "auto"}
					/>
					{/*
                    <NavItem icon={FiSettings} title="Ajustes" slash="/ajustes"  pointerEvents={planExpired ? 'none' : 'auto'} />
                    */}
					<NavItem
						icon={PiCarProfile}
						title='Inspección'
						slash='/inspecciones'
						comingSoon
						pointerEvents={planExpired ? "none" : "auto"}
					/>
				</Flex>
			</Flex>
		);
	} else if (isMobile) {
		return (
			<>
				<IconButton bg='transparent' color='darkblue' icon={<RxHamburgerMenu size='25px' />} onClick={onOpen} />
				<Drawer variant='mobile' isOpen={isOpen} placement='left' onClose={onClose}>
					<DrawerOverlay />
					<DrawerContent bg='darkblue'>
						<DrawerCloseButton />
						<Flex direction='column' gap='1em' p='0'>
							{isStaff && (
								<>
									<NavItem
										icon={MdOutlinePendingActions}
										title='Actions'
										slash='/admin/user-actions'
									/>
									<NavItem
										icon={MdOutlineAdminPanelSettings}
										title='Users'
										slash='/admin/users-management'
									/>
									<NavItem
										icon={MdOutlineAdminPanelSettings}
										title='Planes'
										slash='/admin/plans-management'
									/>
									<hr />
								</>
							)}
							<NavItem
								icon={AiOutlineDashboard}
								title='Panel'
								slash='/dashboard'
								pointerEvents={planExpired ? "none" : "auto"}
								onClose={onClose}
							/>
							<NavItem
								icon={LuFile}
								title='Ordenes'
								slash='/ordenes'
								pointerEvents={planExpired ? "none" : "auto"}
								onClose={onClose}
							/>
							<NavItem
								icon={PiUserListBold}
								title='Datos'
								slash='/datos'
								pointerEvents={planExpired ? "none" : "auto"}
								onClose={onClose}
							/>
							<NavItem
								icon={TbFileInvoice}
								title='Facturas'
								slash='/facturas'
								pointerEvents={planExpired ? "none" : "auto"}
								onClose={onClose}
							/>
							<NavItem
								icon={MdOutlineInventory2}
								title='Stock'
								slash='/stock'
								onClose={onClose}
								pointerEvents={planExpired ? "none" : "auto"}
							/>
							<NavItem
								icon={BsShop}
								title='Negocio'
								slash='/negocio'
								pointerEvents={planExpired ? "none" : "auto"}
							/>
							<NavItem
								icon={MdOutlineNotificationsActive}
								title='Msgs'
								slash='/comunicacion'
								onClose={onClose}
								pointerEvents={planExpired ? "none" : "auto"}
							/>
							<NavItem
								icon={FaChartLine}
								title='Stats'
								slash='/estadisticas'
								onClose={onClose}
								pointerEvents={planExpired ? "none" : "auto"}
							/>
							{/*
                            <NavItem icon={FiSettings} title="Ajustes" slash="/ajustes"  pointerEvents={planExpired ? 'none' : 'auto'} onClose={onClose}/>
                            */}
							<NavItem
								icon={PiCarProfile}
								title='Inspección'
								slash='/inspecciones'
								onClose={onClose}
								comingSoon
								pointerEvents={planExpired ? "none" : "auto"}
							/>
						</Flex>
					</DrawerContent>
				</Drawer>
			</>
		);
	}
}
