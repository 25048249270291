import moment from 'moment'
import jwt_decode from 'jwt-decode'

export const calculateExpire = (unix) => {
    const date1 = moment.unix(unix)
    const date2 = moment()
    const diff = date1.diff(date2, 'minutes')
    return diff
}

export const userSignIn = (token, toast, signIn, navigate) => {
    const access_decoded = jwt_decode(token.access)
    const refresh_decoded = jwt_decode(token.refresh)
    toast({ title: 'Login exitoso!', status: 'success' })
    signIn({
        // Acces token duration (minutes)
        token: token.access,
        expiresIn: calculateExpire(access_decoded.exp),
        tokenType: 'Bearer',
        // Refresh token data
        refreshToken: token.refresh,
        refreshTokenExpireIn: calculateExpire(refresh_decoded.exp),
        authState: {
            email: access_decoded.email,
            name: access_decoded.name,
            has_seen_tutorial: access_decoded.has_seen_tutorial,
            is_staff: access_decoded.is_staff,
            plan: access_decoded.plan,
            profile_image: access_decoded.profile_image
        }
    })
    if (access_decoded.is_staff) {
        navigate('/admin/users-management')
    } else {
        navigate('/dashboard')
    }
}
