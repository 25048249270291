import { useState, useMemo } from 'react'
import { useDisclosure, Flex, IconButton, Tr, Td } from '@chakra-ui/react'
// comps
import PopoverDelete from '../../../common/PopoverDelete'
import ServiceForm from '../forms/ServiceForm'
import ResponsiveTable from '../../../common/Tables/ResponsiveTable'
// fs
import { parseDuration } from '../../../common/functions'
// icons
import { AiOutlineEdit } from 'react-icons/ai'
// img
import emptyCars from '../../../../img/data/emptyClients.png'
// api
import useAuthFetch from '../../../../myHooks/useAuthFetch'
import { getAllServicesPag, deleteService } from '../../../../api/servicesApi'

export default function ServicesTable () {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [page, setPage] = useState(1)
    const [service, setService] = useState()

    const { data, isLoading } = useAuthFetch(['services', page], getAllServicesPag, page)

    const tableData = useMemo(
        () =>
            data?.results?.map((service) => (
                <Tr key={service.id}>
                    <Td maxW={['300px', '350px']} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                        {service.name}
                    </Td>
                    <Td> {service.price} </Td>
                    <Td> {parseDuration(service.duration)} </Td>
                    <Td>
                        <Flex bg={service.color} boxSize="25px" rounded="md" />
                    </Td>
                    <Td>
                        <Flex gap="1em" key={service.id}>
                            <IconButton
                                icon={<AiOutlineEdit size="20px" color="#0258FF" />}
                                bg="transparent"
                                onClick={() => {
                                    setService(service)
                                    onOpen()
                                }}
                            />
                            <PopoverDelete
                                deleteKey={['deleteService']}
                                deleteFn={deleteService}
                                slug={service?.id}
                                refetchKey={['services']}
                            />
                        </Flex>
                    </Td>
                </Tr>
            )),
        [data, onOpen]
    )
    const isEmpty = data?.count < 1
    return (
        <ResponsiveTable
            paginated
            data={data}
            tableData={tableData}
            isEmpty={isEmpty}
            onOpen={() => {
                setService()
                onOpen()
            }}
            onClose={onClose}
            page={page}
            setPage={setPage}
            isLoading={isLoading}
            item={{
                label: 'Servicio',
                headers: [
                    { key: 'name', label: 'Nombre', roundedLeft: 'lg' },
                    { key: 'price', label: 'Precio(€)' },
                    { key: 'duration', label: 'Duración' },
                    { key: 'color', label: 'Color' }
                ],
                formItem: service,
                isOpen
            }}
            FormComponent={ServiceForm}
            emptyImage={emptyCars}
        />
    )
}
