import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Flex, Text, Button, Image, Skeleton } from "@chakra-ui/react";
// comps
import PaginationMenu from "./PaginationMenu";
import LoadingOverlay from "../LoadingOverlay";

export default function ResponsiveTable({
	data,
	tableData,
	isEmpty,
	onOpen,
	onClose,
	page,
	setPage,
	isLoading,
	item,
	FormComponent,
	emptyImage,
	paginated,
	searchQuery,
	withoutButton,
	withoutLoading,
	...props
}) {
	const handleOpenForm = () => {
		if (paginated) {
			setPage();
		}
		onOpen();
	};
	const cols = item?.headers?.length + 1;
	const generateSkeletonRows = (numRows, numColumns) => {
		const rows = [];
		for (let i = 0; i < numRows; i++) {
			const cells = [];
			for (let j = 0; j < numColumns; j++) {
				cells.push(
					<Td key={j}>
						<Skeleton h='20px' rounded='md' />
					</Td>
				);
			}
			rows.push(<Tr key={i}>{cells}</Tr>);
		}
		return rows;
	};
	const shouldPaginate = data?.count > 10 || data?.length > 10;

	return (
		<>
			<TableContainer
				mt='1em'
				w='100%'
				{...props}
				pb='1em'
				sx={{
					paddingRight: "0.2rem",
					"&::-webkit-scrollbar": { width: "5px", height: "8px" },
					"&::-webkit-scrollbar-track": {
						backgroundColor: "#f5f5f5"
					},
					"&::-webkit-scrollbar-thumb": {
						backgroundColor: "#cfcfcf"
					}
				}}
			>
				<Table variant='unstyled' size='sm' rounded='xl'>
					<Thead>
						<Tr bg='lightgrey'>
							{item.headers.map((header) => (
								<Th key={header.key} py='1.5em' roundedLeft={header.roundedLeft}>
									{header.label}
								</Th>
							))}

							<Th roundedRight='lg' key='actions'>
								{!withoutButton && (
									<Button variant='primary' size='sm' onClick={handleOpenForm}>
										+ {item.label}
									</Button>
								)}
							</Th>
						</Tr>
					</Thead>
					{!isEmpty && !isLoading && <Tbody>{tableData}</Tbody>}
					{isLoading && <Tbody>{generateSkeletonRows(10, cols)}</Tbody>}
				</Table>
				{isEmpty && !searchQuery && (
					<Flex minW='800px' align='center' py='4em' direction='column'>
						<Image src={emptyImage} />
						<Text mt='2em'>Aún no hay ningún {item.label} creado</Text>
						<Flex flexWrap='wrap' justify='center'>
							<Text>Haz click en</Text>
							<Text color='blue.500' fontWeight='bold' mx='0.2em'>
								+ {item.label}
							</Text>
							<Text>para crear uno nuevo</Text>
						</Flex>
					</Flex>
				)}
				{isEmpty && searchQuery && (
					<Flex minW='800px' align='center' py='4em' direction='column'>
						<Image src={emptyImage} />
						<Text mt='2em'>
							No se ha encontrado ningun {item.label} para la búsqueda
							<span style={{ fontWeight: "bold" }}> "{searchQuery}" </span>
							prueba con otra.
						</Text>
					</Flex>
				)}
				{paginated && shouldPaginate && (
					<PaginationMenu
						total={data?.count}
						next={data?.next}
						prev={data?.previous}
						page={page}
						setPage={setPage}
					/>
				)}
			</TableContainer>
			{FormComponent && <FormComponent item={item.formItem} onClose={onClose} isOpen={item.isOpen} />}
			{!withoutLoading && <LoadingOverlay isLoading={isLoading} />}
		</>
	);
}
