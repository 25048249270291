import { useState, useMemo } from 'react'
import { TableContainer, Table, Badge, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react'
import moment from 'moment/moment'
// comps
import LoadingSpinner from '../../common/LoadingSpinner'
import PaginationMenu from '../../common/Tables/PaginationMenu'
// api
import useAuthFetch from '../../../myHooks/useAuthFetch'
import { getLoginRegisterLog } from '../../../api/adminApi'

export default function LoginRegisterLogTable ({ device }) {
    const [page, setPage] = useState(1)

    const { data, isLoading } = useAuthFetch(['logs', page], getLoginRegisterLog, page)

    const tableData = useMemo(
        () =>
            data?.results?.map((log) => (
                <Tr key={log.id}>
                    <Td> {log.action} </Td>
                    <Td>
                        {log.device}
                    </Td>
                    <Td>
                        {log.user_name || '-- --'}
                    </Td>
                    <Td>
                        {log.user_email}
                    </Td>
                    <Td> {moment(log.timestamp).format('lll')} </Td>
                </Tr>
            )),
        [data]
    )
    return (
        <>
            <TableContainer mt="1em">
                <Table variant="unstyled" size="sm">
                    <Thead>
                        <Tr bg="lightgrey" color="darkblue">
                            <Th roundedLeft="lg" >Action</Th>
                            <Th>Device</Th>
                            <Th>Name</Th>
                            <Th>Email</Th>
                            <Th roundedRight="lg">Timestamp</Th>
                        </Tr>
                    </Thead>
                    <Tbody>{tableData}</Tbody>
                    {isLoading && <LoadingSpinner />}
                </Table>
                <PaginationMenu
                    total={data?.count}
                    next={data?.next}
                    prev={data?.previous}
                    page={page}
                    setPage={setPage}
                    device={device}
                />
            </TableContainer>
        </>
    )
}
