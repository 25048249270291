import { useState, useMemo } from "react";
import { useDisclosure, Flex, IconButton, Tr, Td } from "@chakra-ui/react";
// comps
import ResponsiveTable from "../../../common/Tables/ResponsiveTable";
import PopoverDelete from "../../../common/PopoverDelete";
import ClientForm from "../forms/ClientForm";
import WhatsappConsentModal from "../clients/WhatsappConsentModal";
import SearchInput from "../../../common/Tables/SearchInput";
// icons
import { AiOutlineEdit } from "react-icons/ai";
import { LuGhost } from "react-icons/lu";
// img
import emptyCars from "../../../../img/data/emptyClients.png";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getAllClientsPag, deleteClient } from "../../../../api/clientsApi";

export default function ClientsTablePag() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const [client, setClient] = useState();
	const [searchQuery, setSearchQuery] = useState("");

	const { data, isLoading } = useAuthFetch(
		["clients", page, searchQuery],
		getAllClientsPag,
		page,
		null,
		true,
		searchQuery
	);
	const shortCol = {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		maxW: "250px"
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((client) => (
				<Tr key={client.id}>
					<Td {...shortCol}>
						<Flex gap='5px'>
							{client.name}
							{client.is_ghost && <LuGhost size='15px' color='gray' />}
						</Flex>
					</Td>
					<Td>{client.nif_cif || "-- --"}</Td>
					<Td> {client.phone || "-- --"} </Td>
					<Td> {client.email || "-- --"} </Td>
					<Td {...shortCol}> {client.address || "-- --"} </Td>
					<Td>
						<WhatsappConsentModal client={client} isFromTable />
					</Td>
					<Td>
						<Flex gap='1em' key={client.id}>
							<IconButton
								icon={<AiOutlineEdit size='20px' color='#0258FF' />}
								bg='transparent'
								onClick={() => {
									setClient(client);
									onOpen();
								}}
							/>
							<PopoverDelete
								deleteKey={["deleteClient"]}
								deleteFn={deleteClient}
								slug={client?.id}
								refetchKey={["clients"]}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data, onOpen]
	);
	const isEmpty = data?.count < 1;
	return (
		<>
			<SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

			<ResponsiveTable
				paginated
				searchQuery={searchQuery}
				data={data}
				tableData={tableData}
				isEmpty={isEmpty}
				onOpen={() => {
					setClient();
					onOpen();
				}}
				onClose={onClose}
				page={page}
				setPage={setPage}
				isLoading={isLoading}
				item={{
					label: "Cliente",
					headers: [
						{ key: "name", label: "Nombre", roundedLeft: "lg" },
						{ key: "nif_cif", label: "NIF/CIF" },
						{ key: "phone", label: "Teléfono" },
						{ key: "email", label: "Email" },
						{ key: "address", label: "Dirección" },
						{ key: "consent", label: "Consentimiento" }
					],
					formItem: client,
					isOpen
				}}
				FormComponent={ClientForm}
				emptyImage={emptyCars}
			/>
		</>
	);
}
