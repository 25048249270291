import { useState, useMemo, useEffect } from "react";
import { useDisclosure, Flex, IconButton, Tr, Td } from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
// comps
import ResponsiveTable from "../../../common/Tables/ResponsiveTable";
import OrderForm from "../../orders/forms/OrderForm";
import PopoverDelete from "../../../common/PopoverDelete";
import OrderStatusView from "../../orders/OrderStatusView";
import MultiInvoiceModal from "../../invoicing/multi/MultiInvoiceModal";
import OrderInvoiceTag from "../../orders/card/OrderInvoiceTag";
import SearchInput from "../../../common/Tables/SearchInput";
import OrderWorkerFilter from "../../orders/OrderWorkerFilter";
import BooleanFilter from "../../../common/Tables/BooleanFilter";
// icons
import { AiOutlineEdit } from "react-icons/ai";
// img
import emptyOrders from "../../../../img/data/emptyOrders.png";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getAllOrdersPag, deleteOrder } from "../../../../api/ordersApi";

export default function OrdersTablePag() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenInvoiceModal, onOpen: onOpenInvoiceModal, onClose: onCloseInvoiceModal } = useDisclosure();

	const [page, setPage] = useState(1);
	const [order, setOrder] = useState();
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedWorkers, setSelectedWorkers] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [filter, setFilter] = useState("");
	const availableFilters = [
		{ label: "R.Depósito", value: "hasDepositReceipt" },
		{ label: "Factura", value: "hasInvoice" },
		{ label: "Presupuesto", value: "hasBudget" }
	];
	useEffect(() => {
		const workersFilter = selectedWorkers.length > 0 ? `&workers=${selectedWorkers.join(",")}` : "";
		setFilter(workersFilter);
	}, [selectedWorkers]);

	const { data, isLoading } = useAuthFetch(
		["orders", page, searchQuery, selectedWorkers, filter],
		getAllOrdersPag,
		page,
		filter,
		true,
		searchQuery
	);
	const tableData = useMemo(
		() =>
			data?.results?.map((order) => (
				<Tr key={order.id}>
					<Td> {order.client ? order.client.name : "-- --"} </Td>
					<Td> {order.car.plate || order.car.brand_display} </Td>
					<Td>{order.date_in ? moment(order.date_in).format("l") : "-- --"}</Td>
					<Td> {order.date_out ? moment(order.date_out).format("l") : "-- --"} </Td>
					<Td>
						<OrderStatusView status={order.status} />
					</Td>
					<Td>
						<OrderInvoiceTag order={order} />
					</Td>
					<Td>
						<Flex gap='1em'>
							<IconButton
								icon={<AiOutlineEdit size='20px' color='#0258FF' />}
								bg='transparent'
								onClick={() => {
									setOrder(order);
									onOpen();
								}}
							/>
							<PopoverDelete
								deleteKey={["deleteOrder"]}
								deleteFn={deleteOrder}
								slug={order?.id}
								refetchKey={["orders"]}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data]
	);
	const isEmpty = data?.count < 1;
	return (
		<>
			<Flex
				w='100%'
				gap='1rem'
				justify={selectedItems?.length > 0 ? "space-between" : "end"}
				align='center'
				flexWrap='wrap'
			>
				<OrderWorkerFilter w='100px' setSelectedWorkers={setSelectedWorkers} />
				<BooleanFilter availableFilters={availableFilters} setFilter={setFilter} />
				<SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
			</Flex>
			<ResponsiveTable
				paginated
				searchQuery={searchQuery}
				data={data}
				tableData={tableData}
				isEmpty={isEmpty}
				onOpen={() => {
					setOrder();
					onOpen();
				}}
				onClose={onClose}
				page={page}
				setPage={setPage}
				isLoading={isLoading}
				item={{
					label: "Orden",
					headers: [
						{ key: "client", label: "Cliente", roundedLeft: "lg" },
						{ key: "car.plate", label: "Coche" },
						{ key: "date_in", label: "Fecha entrada" },
						{ key: "date_out", label: "Fecha salida" },
						{ key: "status", label: "Estado" },
						{ key: "invoice", label: "Factura" }
					],
					formItem: order,
					isOpen
				}}
				FormComponent={OrderForm}
				emptyImage={emptyOrders}
			/>
			<MultiInvoiceModal orders={selectedItems} isOpen={isOpenInvoiceModal} onClose={onCloseInvoiceModal} />
		</>
	);
}
