import { useState, useEffect } from "react";
import { Flex, Text, Select, Highlight } from "@chakra-ui/react";
// comps
import CheckoutButton from "./CheckoutButton";
// icons
import { AiOutlineCheck } from "react-icons/ai";

export default function MultiplePlanCard({ features }) {
	const [selectedPlan, setSelectedPlan] = useState("biannual");
	const [price, setPrice] = useState(112, 5);

	useEffect(() => {
		if (selectedPlan === "monthly") {
			setPrice(60);
		}
		if (selectedPlan === "biannual") {
			setPrice(55);
		}
		if (selectedPlan === "annual") {
			setPrice(50);
		}
	}, [selectedPlan]);

	return (
		<Flex rounded='lg' py='1.5em' px='2em' bg='white' direction='column' gap='1em' maxW='350px'>
			<Flex gap='1rem' align='center'>
				<Text fontSize='22px' fontWeight='bold'>
					<Highlight query={"another"} styles={{ color: "darkblue" }}>
						another
					</Highlight>
					<Highlight query={"tool"} styles={{ color: "blue.500" }}>
						tool
					</Highlight>
					<span> </span>PRO
				</Text>
				<Select value={selectedPlan} onChange={(e) => setSelectedPlan(e.target.value)}>
					<option value='monthly'>Mensual</option>
					<option value='biannual'>Semestral</option>
					<option value='annual'>Anual</option>
				</Select>
			</Flex>
			<Text my='0.5em' fontSize='16px'>
				Exprime el potencial de tu negocio. Deja de perder el tiempo en lo que no te gusta y genera más
				ingresos.
			</Text>
			{features?.map((feature, index) => (
				<Flex align='center' key={index}>
					<AiOutlineCheck color={"#0258FF"} size='18px' />
					<Text fontSize='12px' ml='10px'>
						{feature}
					</Text>
				</Flex>
			))}
			<Flex gap='0.2rem' align='center'>
				<Text color='blue.500' fontWeight='bold' fontSize='32px'>
					{price}€
				</Text>
				<Text fontSize='12px'>/mes</Text>
			</Flex>
			<CheckoutButton mt='1em' label='Actualizar' subscription={selectedPlan} />
		</Flex>
	);
}
