import { Fragment } from 'react'
import { Flex, Text, Button } from '@chakra-ui/react'
// icons
import { BiWrench } from 'react-icons/bi'
// comps
import StripeModalButton from '../../../payments/StripeModalButton'
import OrderItemRow from '../../data/forms/OrderItemRow'
// forms
import { FieldArray } from 'formik'

export default function StockUsed ({ isFree, formik, items, error, order }) {
    return (
        <FieldArray
            name="stock_used"
            render={(arrayHelpers) => (
                <>
                    {isFree
                        ? (
                            <Flex direction="column" bg="lightgrey" rounded="lg" pt='0.5em' px="1em">
                                <StripeModalButton type="blue" mt="-1em" />
                                <Flex align="center">
                                    <BiWrench size="20px" color="black" />
                                    <Text fontWeight="bold" ml="0.5em" mr="2em">
                                    Recambios utilizados
                                    </Text>
                                    <Button variant="primary" color="lightgrey" size="xs" isDisabled>
                                    +
                                    </Button>
                                </Flex>
                            </Flex>
                        )
                        : (
                            <Flex align="center">
                                <BiWrench size="20px" color="black" />
                                <Text fontWeight="bold" ml="0.5em" mr="1em">
                                Recambios utilizados
                                </Text>
                                <Button
                                    variant="primary"
                                    size="xs"
                                    onClick={() =>
                                        arrayHelpers.push({
                                            item: 0,
                                            quantity: 0,
                                            price: 0
                                        })
                                    }
                                    isDisabled={formik.errors.stock_used || JSON.stringify(formik.touched.items) === '{}'}
                                >
                                +
                                </Button>
                            </Flex>
                        )}
                    {formik.values.stock_used &&
                        formik.values.stock_used.length > 0 &&
                        formik.values.stock_used.map((item, index) => (
                            <Fragment key={index}>
                                <OrderItemRow
                                    formik={formik}
                                    index={index}
                                    arrayHelpers={arrayHelpers}
                                    items={items}
                                    error={error}
                                    order={order}
                                />
                            </Fragment>
                        ))}
                </>
            )}
        />
    )
}
