import { useState } from "react";
import { Button, useToast } from "@chakra-ui/react";
// api
import useAuthMutation from "../../myHooks/useAuthMutation";
import { createCheckoutSession } from "../../api/paymentsApi";
// icons
import { BsArrowUpCircle } from "react-icons/bs";
// api
import useAuthFetch from "../../myHooks/useAuthFetch";
import { getUserPlan } from "../../api/plansApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function CheckoutButton({ label, type, subscription, ...props }) {
	const [error, setError] = useState("");
	const toast = useToast();
	const authHeader = useAuthHeader();

	const { data: userplan } = useAuthFetch(["userplan"], getUserPlan);

	const env_settings = process.env.REACT_APP_ENV_SETTINGS;
	const { isLoading, mutate } = useAuthMutation({
		mutationFn: createCheckoutSession,
		onSuccess: (response) => {
			window.location.href = response.redirect_url;
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});

	const disableSubmit = () => {
		if (userplan?.plan?.name === "TRIAL" && subscription === "whatsapp") {
			return true;
		}
		return false;
	};

	const handleCheckout = () => {
		const payload = {
			token: authHeader(),
			data: {
				subscribe_to: subscription,
				env_settings
			}
		};
		mutate(payload);
	};

	if (type === "green") {
		return (
			<Button
				my='1em'
				w='100%'
				size='md'
				colorScheme='green'
				{...props}
				isLoading={isLoading}
				leftIcon={<BsArrowUpCircle color='white' size='20px' />}
				alignSelf='center'
				onClick={handleCheckout}
				isDisabled={disableSubmit()}
			>
				{label || "Actualizar"}
			</Button>
		);
	}
	return (
		<Button
			variant='primary'
			{...props}
			onClick={handleCheckout}
			isLoading={isLoading}
			isDisabled={disableSubmit()}
			title='Necesitas una suscripción PRO para acceder a este plan'
		>
			{label || "Comprar"}
		</Button>
	);
}
