import { Flex, Text, Image, Divider, Badge, useMediaQuery } from "@chakra-ui/react";
// comps
import LoadingSpinner from "../../common/LoadingSpinner";
import OrderCard from "./card/OrderCard";
import { STATUS_COLORS, STATUS_TITLES, getEmptyImage, getEmptyText } from "./helpers";
// api
import useAuthFetch from "../../../myHooks/useAuthFetch.jsx";
import { getAllOrders } from "../../../api/ordersApi";
// auth

export default function OrderCardList({ type, period, workers, sortBy, ordering }) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const workersFilter = workers?.length > 0 ? "&workers=" + workers?.join(",") : "";
	const sortByFilter = "&sortBy=" + sortBy + "&ordering=" + ordering;
	const ordersMeta = {
		key: type + "orders",
		title: STATUS_TITLES[type],
		color: STATUS_COLORS[type],
		image: getEmptyImage(type),
		filter: type + "&period=" + period + sortByFilter + workersFilter,
		emptyText: getEmptyText(type)
	};
	const { data: orders, isLoading } = useAuthFetch(
		[ordersMeta.key, period, sortBy, ordering, workers],
		getAllOrders,
		null,
		ordersMeta.filter
	);
	const ordersCount = orders?.count;
	return (
		<>
			<Flex w={isMobile ? "100%" : "25%"} direction='column' align='center'>
				<Flex w='100%' align='center' justify='space-between' mt='1.5em' mb='0.5em'>
					<Text fontSize='20px' fontWeight='bold'>
						{ordersMeta.title}
					</Text>
					<Badge colorScheme={ordersMeta.color} px='15px' py='3px' rounded='lg' fontSize='18px'>
						{ordersCount}
					</Badge>
				</Flex>
				<Divider borderColor='#D9D9D9' mb='1em' borderWidth='2px' borderRadius='10' />
				{ordersCount !== 0 ? (
					<Flex
						direction='column'
						pr='5px'
						w={isMobile ? "100%" : { base: "100%", md: "250px", lg: "210px", xl: "300px", "2xl": "100%" }}
						sx={{
							flexGrow: 1,
							overflowY: "auto",
							maxH: "75vh",
							"&::-webkit-scrollbar": { width: "5px" },
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#f5f5f5"
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#cfcfcf"
							}
						}}
					>
						{orders?.results.map((order) => (
							<OrderCard key={order.id} order={order} />
						))}
					</Flex>
				) : (
					<Flex
						direction='column'
						minH='70vh'
						bg='white'
						rounded='xl'
						px='1em'
						gap='5vh'
						align='center'
						justify='center'
						py='4em'
					>
						<Image src={ordersMeta.image} />
						{ordersMeta.emptyText}
					</Flex>
				)}
				{isLoading && <LoadingSpinner />}
			</Flex>
		</>
	);
}
