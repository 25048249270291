import { Text, Flex, Button, Switch } from "@chakra-ui/react";
import moment from "moment";
// comps
import DiscountField from "./DiscountField";
import UsedStock from "./UsedStock";
import InfoTooltip from "../../../common/InfoTooltip";
import InvoiceItems from "./InvoiceItems";
// fs
import { formatDecimals } from "../../../common/functions";
import {
	getQuoteInitialValues,
	getInvoiceInitialValues,
	getQuoteValidationSchema,
	getInvoiceValidationSchema
} from "./utils";
// forms and validation
import { Formik } from "formik";
import InputField from "../../../common/forms/InputField";
import SelectField from "../../../common/forms/SelectField";
import SelectFieldFilter from "../../../common/forms/SelectFieldFilter";

export default function InvoiceForm(props) {
	const {
		submit,
		nextInvoiceID,
		nextQuoteID,
		clients,
		invoice,
		quote,
		options,
		isLoading,
		order,
		calculateTotals,
		total,
		taxes,
		subtotal,
		impositiveBase,
		discount,
		error,
		isQuote
	} = props;

	const taxChoices = options?.actions?.POST?.tax?.choices;
	const payMethodChoices = options?.actions?.POST?.pay_method?.choices;
	const invoiceStatusChoices = options?.actions?.POST?.status?.choices;
	const quoteStatusChoices = options?.actions?.POST?.status?.choices;

	const initialValues = isQuote
		? getQuoteInitialValues(quote, order, nextQuoteID, taxChoices)
		: getInvoiceInitialValues(invoice, order, nextInvoiceID, taxChoices);

	const validationSchema = isQuote ? getQuoteValidationSchema() : getInvoiceValidationSchema();
	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			validationSchema={validationSchema}
			onSubmit={(values) => submit(values)}
		>
			{(formik) => (
				<Flex w='100%' gap='25px' h='100%'>
					<Flex
						direction='column'
						gap='1em'
						maxH='75dvh'
						overflowY='scroll'
						sx={{
							paddingRight: "0.2rem",
							"&::-webkit-scrollbar": { width: "5px", height: "8px" },
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#f5f5f5"
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#cfcfcf"
							}
						}}
					>
						{/* Basic data section */}
						<Flex gap='1em'>
							{!isQuote ? (
								<InputField
									name='invoice_number'
									error={error?.response?.data?.invoice_number}
									label={
										<InfoTooltip
											label={"Nº Factura"}
											desc={"Puedes configurarlo desde panel de ajustes"}
										/>
									}
								/>
							) : (
								<InputField
									name='quote_number'
									error={error?.response?.data?.quote_number}
									label={<InfoTooltip label={"Nº Presupuesto"} />}
								/>
							)}
							<InputField name='date' label='Fecha' type='date' error={error?.response?.data?.date} />

							<InputField
								name='expiring_date'
								label='Fecha de Expiración'
								type='date'
								error={error?.response?.data?.expiring_date}
							/>
						</Flex>
						<Flex gap='1rem' align='end'>
							<Flex w='150px'>
								<SelectField name='tax' choices={taxChoices} bg='white' label='Impuestos' />
							</Flex>
							<Flex>
								<InfoTooltip
									label={
										<Flex align='center' gap='0.5em'>
											<Text>Precios con IVA</Text>
											<Switch
												colorScheme='brand'
												isChecked={formik.values.price_includes_tax}
												value={formik.values.price_includes_tax}
												onChange={() =>
													formik.setFieldValue(
														"price_includes_tax",
														!formik.values.price_includes_tax
													)
												}
											/>
										</Flex>
									}
									desc={"Activa la casilla si los precios ya incluyen IVA"}
								/>
							</Flex>
						</Flex>
						<InvoiceItems formik={formik} calculateTotals={calculateTotals} order={order} />

						<UsedStock stock_used={order?.stock_used || invoice?.stock_used} />
					</Flex>
					<Flex direction='column' h='100%' justify='space-between'>
						<Flex direction='column' gap='1em'>
							<SelectFieldFilter
								label='Cliente'
								required
								options={clients?.results}
								name='client'
								error={error?.response?.data?.clients}
							/>
							{clients?.count === 0 && (
								<Flex mt='1em' align='center' gap='1em'>
									<Text fontSize='14px' color='red'>
										Aún no hay clientes, crea uno desde el panel de datos
									</Text>
								</Flex>
							)}
							{!isQuote ? (
								<>
									<SelectField label='Método de pago' name='pay_method' choices={payMethodChoices} />
									<SelectField label='Estado' name='status' choices={invoiceStatusChoices} />
								</>
							) : (
								<SelectField label='Estado' name='status' choices={quoteStatusChoices} />
							)}
							{invoice?.payed_date && <Text> Pagada el {moment(invoice.payed_date).format("L")}</Text>}
							<DiscountField formik={formik} calculateTotals={calculateTotals} order={order} />
						</Flex>
						<Flex direction='column' gap='2em'>
							<Flex w='100%' justify='space-between'>
								<Flex direction='column' gap='1em'>
									{Number(discount) > 0 && <Text fontSize='20px'>Subtotal</Text>}
									{Number(discount) > 0 && <Text fontSize='20px'>Descuento</Text>}
									<Text fontSize='20px'>Base Imponible</Text>
									<Text fontSize='20px'>IVA</Text>
									<Text fontSize='20px' fontWeight='bold'>
										Total
									</Text>
								</Flex>
								<Flex direction='column' gap='1em'>
									{Number(discount) > 0 && (
										<Text fontSize='20px' align='end'>
											{formatDecimals(subtotal)}€
										</Text>
									)}
									{Number(discount) > 0 && (
										<Text fontSize='20px' align='end'>
											-{formatDecimals(discount)}€
										</Text>
									)}
									<Text fontSize='20px' align='end'>
										{formatDecimals(impositiveBase)}€
									</Text>
									<Text fontSize='20px' align='end'>
										{formatDecimals(taxes)}€
									</Text>
									<Text fontSize='20px' fontWeight='bold' align='end'>
										{formatDecimals(total)}€
									</Text>
								</Flex>
							</Flex>
							<Button
								variant='primary'
								justifySelf='end'
								alignSelf='end'
								w='150px'
								isDisabled={
									JSON.stringify(formik.touched) === "{}" && JSON.stringify(formik.values) === "{}"
								}
								onClick={formik.handleSubmit}
								isLoading={isLoading}
							>
								Guardar
							</Button>
						</Flex>
					</Flex>
				</Flex>
			)}
		</Formik>
	);
}
