import { useState, useEffect } from "react";
import { Flex, Tooltip } from "@chakra-ui/react";
//comps
import OrderInvoiceTag from "./OrderInvoiceTag";
//icons
import { FiCalendar } from "react-icons/fi";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { IoReceiptOutline } from "react-icons/io5";

export default function OrderCardProperties({ order }) {
	const [depositReceiptMeta, setDepositReceiptMeta] = useState({
		color: "grey",
		label: "Sin Resguardo"
	});

	useEffect(() => {
		if (order.deposit_receipt) {
			setDepositReceiptMeta({
				color: "orange",
				label: "Resguardo sin firma"
			});
			if (order.deposit_receipt.signature) {
				setDepositReceiptMeta({
					color: "#42C74F",
					label: "Resguardo firmado"
				});
			}
		}
	}, [order]);

	return (
		<Flex justify='space-between'>
			<Flex align='center' gap='0.5em'>
				<Tooltip
					label={depositReceiptMeta.label}
					rounded='lg'
					bg='#D9D9D9'
					color='#001234'
					shouldWrapChildren={true}
				>
					<IoReceiptOutline size='20px' color={depositReceiptMeta.color} />
				</Tooltip>
				<Tooltip label='Agendada' rounded='lg' bg='#D9D9D9' color='#001234' shouldWrapChildren={true}>
					<FiCalendar size='20px' color={order.is_programmed ? "#42C74F" : "grey"} />
				</Tooltip>
				<Tooltip label='Recordatorio' rounded='lg' bg='#D9D9D9' color='#001234' shouldWrapChildren={true}>
					<MdOutlineNotificationsActive size='20px' color={order.has_reminder ? "#42C74F" : "grey"} />
				</Tooltip>
			</Flex>
			<OrderInvoiceTag order={order} />
		</Flex>
	);
}
