import { useState } from "react";
import {
	Button,
	useToast,
	Flex,
	Text,
	DrawerBody,
	DrawerFooter,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton
} from "@chakra-ui/react";

// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import type { FormikHelpers } from "formik";
import InputField from "../../../common/forms/InputField";
// Comps
import WhatsappConsentModal from "../clients/WhatsappConsentModal";
import { PHONE_REG_EXP } from "../../../common/consts";
import ClientAddressField from "../clients/ClientAddressField";
// icons
import { FaRegUser } from "react-icons/fa6";
import { MdOutlinePhone, MdOutlineEmail } from "react-icons/md";
import { FaWhatsapp, FaIdCard } from "react-icons/fa";
//types
import type { Client, ClientPayload, ClientAxiosError, ClientInput } from "../../../../types/client";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateClient } from "../../../../api/clientsApi";

interface ClientFormProps {
	item?: Client;
	isOpen: boolean;
	onClose: () => void;
}

export default function ClientForm({ onClose, isOpen, item: client }: ClientFormProps) {
	const [formSignedAt, setFormSignedAt] = useState();
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { isLoading, mutate, error } = useAuthMutation<Client, ClientPayload, ClientAxiosError>({
		mutationFn: createUpdateClient,
		onSuccess: () => {
			toast({ title: "Creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["clients"]);
			QueryClient.refetchQueries({ queryKey: ["clients"], exact: true });
			onClose();
		},
		onError: () => {
			toast({
				title: "Ups! Ha habido un error",
				status: "error"
			});
		}
	});

	const initialValues = {
		name: client ? client.name : "",
		nif_cif: client ? client.nif_cif : "",
		phone: client ? client.phone : "",
		email: client ? client.email : "",
		address: client ? client.address : ""
	};
	const validationSchema = Yup.object({
		name: Yup.string().required("Nombre es obligatorio"),
		nif_cif: Yup.string().nullable(),
		address: Yup.string().nullable(),
		phone: Yup.string().matches(PHONE_REG_EXP, "Teléfono no válido").min(9).nullable(),
		email: Yup.string().email("El email no tiene un formato válido")
	});
	const submit = (values: ClientInput) => {
		const payload: ClientPayload = client
			? { data: values, slug: client.id, token: authHeader() }
			: { data: values, token: authHeader() };

		mutate(payload);
	};

	return (
		<Drawer isOpen={isOpen} placement='right' onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader>{client ? "Editar" : "Crear"} Cliente</DrawerHeader>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => submit(values)}
				>
					{(formik) => (
						<>
							<DrawerBody>
								<Flex direction='column' as='form' gap='1.5em'>
									<InputField
										icon={<FaRegUser color='black' size='15px' />}
										label='Nombre'
										required
										name='name'
										error={error?.response?.data?.name}
									/>
									<InputField
										icon={<FaIdCard color='black' size='18px' />}
										label='NIF/CIF'
										name='nif_cif'
										error={error?.response?.data?.nif_cif}
									/>
									<InputField
										icon={<MdOutlinePhone color='black' size='20px' />}
										label='Telefono'
										name='phone'
										error={error?.response?.data?.phone}
									/>
									<InputField
										icon={<MdOutlineEmail color='black' size='20px' />}
										label='Email'
										name='email'
										error={error?.response?.data?.email}
									/>
									<ClientAddressField error={error?.response?.data?.address} />
									<Flex align='center' gap='0.5em' justify='start'>
										<FaWhatsapp color='black' size='20px' />
										<Text>Consentimiento WhatsApp</Text>
									</Flex>
									<WhatsappConsentModal
										client={client}
										isFromTable={false}
										size='md'
										formSignedAt={formSignedAt}
										setFormSignedAt={setFormSignedAt}
									/>
								</Flex>
							</DrawerBody>
							<DrawerFooter>
								<Flex justify='right' columnGap='3' mt='3'>
									<Button variant='white' onClick={onClose}>
										Cancelar
									</Button>
									<Button
										variant='primary'
										isDisabled={!formik.dirty}
										onClick={() => formik.handleSubmit()}
										isLoading={isLoading}
									>
										Guardar
									</Button>
								</Flex>
							</DrawerFooter>
						</>
					)}
				</Formik>
			</DrawerContent>
		</Drawer>
	);
}
