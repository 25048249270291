import {
	Button,
	useToast,
	Flex,
	VStack,
	DrawerBody,
	DrawerFooter,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton
} from "@chakra-ui/react";

// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import InputField from "../../../common/forms/InputField";
import SelectFieldFilter from "../../../common/forms/SelectFieldFilter";
// comps
import InfoTooltip from "../../../common/InfoTooltip";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import useAuthQuery from "../../../../myHooks/useAuthQuery";
import { createUpdateStock, getAllSuppliers, getStockOptions } from "../../../../api/stockApi";

export default function StockForm({ onClose, isOpen, item: stock }) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data: suppliers } = useAuthQuery({
		queryKey: ["allSuppliers"],
		queryFn: () => getAllSuppliers({ token: authHeader() })
	});
	const supplierOptions = suppliers?.results;
	const { data: stockOptions } = useAuthQuery({
		queryKey: ["stockOptions"],
		queryFn: () => getStockOptions({ token: authHeader() })
	});
	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateStock,
		onSuccess: () => {
			toast({ title: "Creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["stock"]);
			QueryClient.refetchQueries("stock", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});

	const initialValues = {
		name: stock ? stock.name : "",
		reference: stock ? stock.reference : "",
		description: stock ? stock.description : "",
		available_quantity: stock ? stock.available_quantity : 0,
		min_quantity: stock ? stock.min_quantity : "",
		unit_of_measurement: stock ? stock.unit_of_measurement : "uds",
		supplier: stock ? stock.supplier?.id : ""
	};
	const validationSchema = Yup.object({
		name: Yup.string().required("Nombre es obligatorio"),
		available_quantity: Yup.number(),
		min_quantity: Yup.number(),
		buy_price: Yup.number(),
		sell_price: Yup.number()
	});

	const submit = (values) => {
		let payload = {};
		if (stock) {
			payload = {
				data: values,
				slug: stock.id,
				token: authHeader()
			};
		} else {
			payload = {
				data: values,
				token: authHeader()
			};
		}
		mutate(payload);
	};

	return (
		<Drawer isOpen={isOpen} placement='right' onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader>{stock ? "Editar" : "Crear"} Stock</DrawerHeader>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => submit(values)}
				>
					{(formik) => (
						<>
							<DrawerBody>
								<VStack as='form'>
									<InputField
										label='Nombre'
										name='name'
										required
										error={error?.response?.data?.name}
									/>
									<InputField
										label='Descripcion'
										name='description'
										error={error?.response?.data?.description}
									/>
									<InputField
										label='Referencia'
										name='reference'
										error={error?.response?.data?.reference}
									/>
									<InputField
										label='Cantidad disponible'
										name='available_quantity'
										error={error?.response?.data?.available_quantity}
									/>
									<InputField
										label='Cantidad minima'
										icon={<InfoTooltip desc='La cantidad mínima que se debe tener en stock' />}
										name='min_quantity'
										error={error?.response?.data?.min_quantity}
									/>
									<SelectFieldFilter
										label='Unidad de medida'
										options={stockOptions?.actions?.POST?.unit_of_measurement?.choices}
										name='unit_of_measurement'
										error={error?.response?.data?.unit_of_measurement}
									/>
									<SelectFieldFilter
										label='Proveedor'
										options={supplierOptions}
										name='supplier'
										error={error?.response?.data?.supplier}
									/>
								</VStack>
							</DrawerBody>
							<DrawerFooter>
								<Flex justify='right' columnGap='3' mt='3'>
									<Button variant='white' onClick={onClose}>
										Cancelar
									</Button>
									<Button
										variant='primary'
										isDisabled={
											JSON.stringify(formik.touched) === "{}" &&
											JSON.stringify(formik.values) === "{}"
										}
										onClick={formik.handleSubmit}
										isLoading={isLoading}
									>
										Guardar
									</Button>
								</Flex>
							</DrawerFooter>
						</>
					)}
				</Formik>
			</DrawerContent>
		</Drawer>
	);
}
