import { Fragment } from 'react'
import { Flex, Text, Grid, GridItem } from '@chakra-ui/react'
// fs
import { formatDecimals } from '../../../common/functions'
// icons
import { BiWrench } from 'react-icons/bi'

export default function MultiUsedStock ({ stock_used }) {
    return (
        <>
            <Flex align="center" gap="10px" mt='1em'>
                <BiWrench size="20px" color="black" />
                <Text fontSize="20px" fontWeight="bold">
                    Recambios
                </Text>
            </Flex>
            <hr />
            {stock_used?.length > 0
                ? <Grid
                    templateColumns="repeat(5, 1fr)"
                    gap={4}
                    mt="0.5em"
                    rounded="xl"
                >
                    <GridItem rowSpan={1} colSpan={2}>
                        <Text fontSize='17px' fontWeight='bold'>Item</Text>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={1}>
                        <Text fontSize='17px' fontWeight='bold'>Cantidad</Text>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={1}>
                        <Text fontSize='17px' fontWeight='bold'>Precio</Text>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={1}>
                        <Text fontSize='17px' fontWeight='bold'>Total</Text>
                    </GridItem>

                    {stock_used?.map((stock) => (
                        <Fragment key={stock.id}>
                            <GridItem rowSpan={1} colSpan={2}>
                                <Text fontSize="15px"> {stock.name} </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={1}>
                                <Text fontSize="15px">
                                    {formatDecimals(stock.quantity)}
                                    {stock.unit}
                                </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={1}>
                                <Text fontSize="15px">
                                    {formatDecimals(stock.price)}€/{stock.unit}
                                </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={1}>
                                <Text fontSize="15px"> {stock.total} € </Text>
                            </GridItem>
                        </Fragment>
                    ))}
                </Grid>
                : <Text mt='1em'>
                No se han encontrado recambios para esta orden. <br />
                    <span style={{ fontWeight: 'bold' }} >Añade un recambio</span> editando la orden desde la tabla de datos.
                </Text>
            }
        </>
    )
}
