import { Flex, Text, Link, Button, useToast } from "@chakra-ui/react";
import moment from "moment";
// compps
import CheckoutButton from "../../../payments/CheckoutButton";
import AnothertoolColored from "../../../common/AnothertoolColored";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { createCustomerPortal } from "../../../../api/paymentsApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function PlanCard({ userplan }) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const planName = userplan?.plan?.name;

	const { mutate, isLoading } = useAuthMutation({
		mutationFn: createCustomerPortal,
		onSuccess: (response) => {
			window.open(response.url, "_blank");
		},
		onError: () => {
			toast({
				title: "¡Ups! Ha habido un error",
				status: "error"
			});
		}
	});

	const manageSubscription = () => {
		const payload = {
			token: authHeader(),
			data: {
				env_settings: process.env.REACT_APP_ENV_SETTINGS
			}
		};
		mutate(payload);
	};

	return (
		<Flex p='2rem' w='400px' bg='lightgrey' direction='column' rounded='lg'>
			<Flex gap='0.5rem' align='center'>
				<AnothertoolColored fontWeight='bold' fontSize='22px' />
				<Text fontSize='18px' fontWeight='bold'>
					{planName === "TRIAL" ? "PRUEBA GRATIS" : planName}
				</Text>
			</Flex>
			{planName !== "BETA" && (
				<Text fontSize='14px'>
					{planName === "PRO" ? "Proximo pago el " : "Caduca el "}
					{moment(userplan?.expires_at).format("L")}
				</Text>
			)}
			{planName !== "PRO" && <CheckoutButton type='green' subscription='pro' />}
			<Flex justify='space-between' align='center' mt='2rem'>
				<Link
					fontSize='12px'
					textDecor='underline'
					onClick={() => window.open("https://another-tool.com/precios/", "_blank")}
				>
					Ver más info
				</Link>
				{planName === "PRO" && (
					<Button variant='primary' size='sm' isLoading={isLoading} onClick={manageSubscription}>
						Administrar suscripción
					</Button>
				)}
			</Flex>
		</Flex>
	);
}
