import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const _disabled = defineStyle({
    bg: '#749FF2',
    cursor: 'not-allowed',
    opacity: 0.8
})
const _hover = defineStyle({
    bg: '#749FF2',
    boxShadow: '3px 3px 3px rgba(2, 88, 255, 0.3)',
    color: 'white',
    fontWeight: 500,
    _disabled: {
        ..._disabled
    }
})

const primary = defineStyle({
    bg: '#0258FF',
    color: 'white',
    fontFamily: 'Poppins',
    fontWeight: '400',
    _hover: {
        ..._hover
    },
    _disabled: {
        ..._disabled
    },
    _loading: {
        bg: '#749FF2',
        cursor: 'not-allowed',
        opacity: 1,
        _hover: {
            opacity: 1,
            bg: '#749FF2',
            color: 'white',
            fontWeight: 500
        }
    }
})

const grey = defineStyle({
    bg: 'grey',
    color: 'white',
    fontFamily: 'Poppins',
    fontWeight: '400',
    _hover: {
        color: 'white',
        bg: 'gray'
    }
})

const white = defineStyle({
    bg: 'white',
    color: '#0258FF',
    border: '1px solid #0258FF',
    fontFamily: 'Poppins',
    fontWeight: '400',
    _hover: {
        // bg: "#aabcdb",
        color: 'white',
        bg: '#0258FF'
    }
})

const green = defineStyle({
    bg: 'green',
    color: 'white',
    fontFamily: 'Poppins',
    fontWeight: '400',
    _hover: {
        bg: 'hover_green'
    },
    _loading: {
        bg: 'hover_green',
        cursor: 'not-allowed',
        opacity: 1,
        _hover: {
            opacity: 1,
            bg: 'hover_green',
            color: 'white',
            fontWeight: 500
        }
    }
})
const lightGreen = defineStyle({
    bg: '#EDFAEE',
    color: '#42C74F',
    fontFamily: 'Poppins',
    fontWeight: '400',
    _hover: {
        bg: 'hover_green',
        color: 'white'
    },
    _loading: {
        bg: 'hover_green',
        cursor: 'not-allowed',
        opacity: 1,
        _hover: {
            opacity: 1,
            bg: 'hover_green',
            color: 'white',
            fontWeight: 500
        }
    }
})

const darkblue = defineStyle({
    bg: '#001234',
    color: 'whiteAlpha.800',
    fontFamily: 'Poppins',
    fontWeight: '400',
    _hover: {
        // bg: "#aabcdb",
        // color: "#001234",
        bg: '#0258FF'
    }
})
const danger = defineStyle({
    bg: 'brand_red',
    color: 'white',
    border: 'none',
    fontFamily: 'Poppins',
    fontWeight: '400',
    _hover: {
        boxShadow: '3px 3px 3px #ffb3b3',
        color: 'brand_red',
        border: 'none',
        fontWeight: 500
    }
})
const navitem = defineStyle({
    bg: '#001234',
    color: 'whiteAlpha.800',
    fontFamily: 'Poppins',
    fontWeight: '400',
    _hover: {
        // bg: "#aabcdb",
        // color: "#001234",
        bg: '#0258FF'
    },
    _active: {
        bg: '#0258FF'
    }
})
const filter = defineStyle({
    bg: 'transparent',
    p: '0.2em',
    color: 'darkblue',
    fontSize: '14px',
    fontFamily: 'Poppins',
    rounded: 'none',
    fontWeight: '600',
    _active: {
        bg: 'transparent',
        color: 'blue.500'
    }
})

export const buttonTheme = defineStyleConfig({
    variants: {
        _disabled,
        _hover,
        primary,
        darkblue,
        grey,
        white,
        danger,
        navitem,
        filter,
        green,
        lightGreen
    }
})
