import { useState } from "react";
import { Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
// comps
import OrderForm from "../forms/OrderForm";
import InvoiceModal from "../../invoicing/forms/InvoiceModal";
import QuoteModal from "../../invoicing/forms/QuoteModal";
import SendWhatsAppModal from "../../communication/form/SendWhatsAppModal";
import OrderCardDropDown from "./OrderCardDropdown";
import OrderCardServiceTags from "./OrderCardServiceTags";
import OrderReminderStatusView from "./OrderReminderStatusView";
import OrderWorkersView from "./OrderWorkersView";
import DepositReceiptModal from "../../depositReceipt/form/DepositReceiptModal";
import OrderCardProperties from "./OrderCardProperties";
//fns
import { formatOrderIncrementalID } from "../helpers";
// icons
import { BiCar } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { deleteOrder } from "../../../../api/ordersApi";

export default function OrderCard({ order }) {
	const { isOpen: isOpenOrderForm, onOpen: onOpenOrderForm, onClose: onCloseOrderForm } = useDisclosure();
	const { isOpen: isOpenInvoiceModal, onOpen: onOpenInvoiceModal, onClose: onCloseInvoiceModal } = useDisclosure();
	const { isOpen: isOpenQuoteModal, onOpen: onOpenQuoteModal, onClose: onCloseQuoteModal } = useDisclosure();
	const { isOpen: isOpenDepositModal, onOpen: onOpenDepositModal, onClose: onCloseDepositModal } = useDisclosure();
	const { isOpen: isOpenWhatsAppModal, onOpen: onOpenWhatsAppModal, onClose: onCloseWhatsAppModal } = useDisclosure();

	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();
	const toast = useToast();
	const [invoiceFromOrder, setInvoiceFromOrder] = useState(null);
	const [quoteFromOrder, setQuoteFromOrder] = useState(null);

	const { mutate: remove } = useAuthMutation(
		["deleteOrder"],
		() => deleteOrder({ slug: order.id, token: authHeader() }),
		{
			onSuccess: () => {
				toast({ title: "Borrado con exito!", status: "success" });
				QueryClient.invalidateQueries(["startedorders"]);
				QueryClient.refetchQueries("startedorders", { force: true });
				onCloseOrderForm();
			},
			onError: (error) => {
				toast({
					title: error.message,
					description: error.code,
					status: "error"
				});
			}
		}
	);
	const handleOpenInvoice = () => {
		if (order.invoice) {
			setInvoiceFromOrder(order.invoice);
		}
		onOpenInvoiceModal();
	};
	const handleOpenQuote = () => {
		if (order.quote) {
			setQuoteFromOrder(order.quote);
		}
		onOpenQuoteModal();
	};
	return (
		<>
			<Flex
				direction='column'
				maxW='100%'
				my='0.5em'
				boxShadow='2px 2px 2px 2px #F4F4F9'
				gap='1em'
				rounded='xl'
				justify='center'
				p='1em'
				bg='white'
			>
				<OrderReminderStatusView status={order.reminder_status} />
				<Flex maxW='100%' overflowWrap='break-word' justify='space-between' align='center'>
					<Flex align='center' gap='0.5em'>
						<Text fontWeight='bold'> Orden {formatOrderIncrementalID(order?.incremental_id)} </Text>
						<OrderWorkersView workers={order.workers} />
					</Flex>
					<OrderCardDropDown
						onOpenInvoiceModal={handleOpenInvoice}
						onOpenQuoteModal={handleOpenQuote}
						onOpenOrderForm={onOpenOrderForm}
						onOpenDepositModal={onOpenDepositModal}
						onOpenWhatsAppModal={onOpenWhatsAppModal}
						hasInvoice={!!order.invoice}
						hasQuote={!!order.quote}
						depositReceiptId={order.deposit_receipt?.id}
						remove={remove}
					/>
				</Flex>
				<Flex gap='0.5em' align='center' flexWrap='wrap'>
					<BiCar size='20px' color='grey' />
					<Text color='grey' fontWeight='bold'>
						{order.car.brand_display}
					</Text>
					<Text color='grey'>{order.car.model}</Text>
					<Text color='grey'>{order.car.plate}</Text>
				</Flex>
				<Flex gap='0.5em' align='center'>
					<FiUser size='20px' color='grey' />
					<Text color='grey' fontWeight='bold' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
						{order.client ? order.client.name : "-- --"}
					</Text>
				</Flex>
				<OrderCardServiceTags services={order.services} />
				<OrderCardProperties order={order} />
			</Flex>
			<OrderForm isOpen={isOpenOrderForm} item={order} onClose={onCloseOrderForm} />
			<SendWhatsAppModal order={order} isOpen={isOpenWhatsAppModal} onClose={onCloseWhatsAppModal} />
			<DepositReceiptModal order={order} isOpen={isOpenDepositModal} onClose={onCloseDepositModal} />
			<InvoiceModal
				isOpen={isOpenInvoiceModal}
				item={invoiceFromOrder}
				order={order}
				onClose={onCloseInvoiceModal}
			/>
			<QuoteModal isOpen={isOpenQuoteModal} item={quoteFromOrder} order={order} onClose={onCloseQuoteModal} />
		</>
	);
}
