import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalBody, Flex, Text, Button } from "@chakra-ui/react";
//API
import useAuthMutation from "../../myHooks/useAuthMutation";
import { createCustomerPortal } from "../../api/paymentsApi";
import { useAuthHeader, useAuthUser } from "react-auth-kit";

export default function PlanExpired() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const auth = useAuthUser();
	const authHeader = useAuthHeader();

	const planName = auth().plan.name;
	useEffect(() => onOpen(), []);

	const { mutate, isLoading } = useAuthMutation({
		mutationFn: createCustomerPortal,
		onSuccess: (response) => {
			window.open(response.url, "_blank");
		},
		onError: () => {
			toast({
				title: "¡Ups! Ha habido un error",
				status: "error"
			});
		}
	});
	const manageSubscription = () => {
		const payload = {
			token: authHeader(),
			data: {
				env_settings: process.env.REACT_APP_ENV_SETTINGS
			}
		};
		mutate(payload);
	};

	return (
		<Flex w='100%' h='100%' justify='center'>
			<Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
				<ModalOverlay />
				<ModalContent>
					<ModalBody py='2em' px='3em'>
						{planName === "PRO" ? (
							<Flex direction='column' gap='1em' justify='center' align='center'>
								<Text fontWeight='bold' align='center' fontSize='18px'>
									¡Oh Vaya! <br />
									Tu plan PRO ha expirado
								</Text>
								<Text align='center' my='1em'>
									Si estás teniendo un mes de menos trabajo, no te preocupes, es normal. <br />
									En
									<span style={{ fontWeight: "bold" }}> anothertool</span> te esperamos con los brazos
									abiertos cuando vuelvas. <br />
									No lo dejes!
								</Text>
								<Button variant='primary' isLoading={isLoading} onClick={manageSubscription}>
									RENOVAR SUSCRIPCIÓN
								</Button>
							</Flex>
						) : (
							<Flex direction='column' gap='1em' justify='center' align='center'>
								<Text fontWeight='bold' align='center' fontSize='18px'>
									¡Oh Vaya! <br />
									Tu prueba gratis ha expirado
								</Text>
								<Text align='center' my='1em'>
									Para poder seguir usando <span style={{ fontWeight: "bold" }}>anothertool</span> sin
									límites date de alta en uno de nuestros planes. O ponte en contacto con nosotros
									para que te ayudemos con la puesta en marcha y conozcas todas las ventajas que te
									ofrecemos.
								</Text>
								<Flex gap='3em'>
									<Button variant='primary' onClick={() => navigate("/planes")}>
										Quiero empezar
									</Button>
									<Button
										variant='white'
										onClick={() => window.open("https://wa.me/624791064", "_blank")}
									>
										Quiero hablar
									</Button>
								</Flex>
							</Flex>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
