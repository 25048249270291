import axios from "axios";
import { createApi, getAllPag, getOptions, deleteItem } from "./baseApi";

const ordersApi = createApi("orders");

export const updateOrderStatus = async (token) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}update-order-status`, {
		headers: { Authorization: token }
	});
	return response.data;
};

export const getProgrammedOrders = async (payload) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}get-programmed-orders`, {
		headers: { Authorization: payload.auth }
	});
	return response.data;
};

export const getAllOrders = async (payload) => {
	let url = "/";
	if (payload.filter) {
		url = `/?status=${payload.filter}`;
	}
	const response = await ordersApi.get(url, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const getAllOrdersPag = async (payload) => {
	return getAllPag(ordersApi, payload);
};

export const getLatestOrders = async (payload) => {
	const response = await ordersApi.get(`/?latest=${payload.number}`, {
		headers: { Authorization: payload.auth }
	});
	return response.data;
};

export const getOrderOptions = async (payload) => {
	return getOptions(ordersApi, payload);
};
export const deleteOrder = async (payload) => {
	return deleteItem(ordersApi, payload);
};

export const createUpdateOrderPatch = async (payload) => {
	if (payload.data.date_in === "") {
		payload.data.date_in = null;
	}
	if (payload.data.date_out === "") {
		payload.data.date_out = null;
	}
	let response = {};
	if (payload.slug) {
		response = await ordersApi.patch(`/${payload.slug}/`, payload.data, {
			headers: { Authorization: payload.token }
		});
	} else {
		response = await ordersApi.post("/", payload.data, {
			headers: { Authorization: payload.token }
		});
	}
	return response.data;
};
export const createUpdateOrderPut = async (payload) => {
	if (payload.data.date_in === "") {
		payload.data.date_in = null;
	}
	if (payload.data.date_out === "") {
		payload.data.date_out = null;
	}
	let response = {};
	if (payload.slug) {
		response = await ordersApi.put(`/${payload.slug}/`, payload.data, {
			headers: { Authorization: payload.token }
		});
	} else {
		response = await ordersApi.post("/", payload.data, {
			headers: { Authorization: payload.token }
		});
	}
	return response.data;
};
