import { Fragment } from 'react'
import { Flex, Text, Grid, GridItem } from '@chakra-ui/react'
// fs
import { formatDecimals } from '../../../common/functions'
// icons
import { BiWrench } from 'react-icons/bi'

export default function UsedStock ({ stock_used }) {
    if (stock_used && stock_used?.length > 0) {
        return (
            <Flex direction="column" mt="0.5em">
                <Flex align="center" gap="10px">
                    <BiWrench size="20px" color="black" />
                    <Text fontSize="20px" fontWeight="bold">
                        Recambios
                    </Text>
                </Flex>
                <Grid
                    templateColumns="repeat(5, 1fr)"
                    gap={4}
                    mt="0.5em"
                    bg="#F7F7F7"
                    px="1em"
                    py="2em"
                    rounded="xl"
                    border="1px solid #D9D9D9"
                >
                    <GridItem rowSpan={1} colSpan={2}>
                        <Text>Item</Text>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={1}>
                        <Text>Cantidad</Text>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={1}>
                        <Text>Precio</Text>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={1}>
                        <Text>Total</Text>
                    </GridItem>

                    {stock_used?.map((stock) => (
                        <Fragment key={stock.id}>
                            <GridItem rowSpan={1} colSpan={2}>
                                <Text fontSize="18px"> {stock.name} </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={1}>
                                <Text fontSize="18px">
                                    {formatDecimals(stock.quantity)}
                                    {stock.unit }
                                </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={1}>
                                <Text fontSize="18px">
                                    {formatDecimals(stock.price)}€/{stock.unit}
                                </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={1}>
                                <Text fontSize="18px"> {stock.total} € </Text>
                            </GridItem>
                        </Fragment>
                    ))}
                </Grid>
            </Flex>
        )
    } else {
        return (
            <Flex direction="column" mt="1em">
                <Flex align="center" gap="10px">
                    <BiWrench size="20px" color="black" />
                    <Text fontSize="20px" fontWeight="bold">
                        Recambios
                    </Text>
                </Flex>
                <Flex w="100%" mt="0.5em" bg="#F7F7F7" p="1em" rounded="xl" border="1px solid #D9D9D9">
                    <Text mt="15px">
                        No se ha utilizado recambio en ésta orden de trabajo, añádelo desde la propia orden
                    </Text>
                </Flex>
            </Flex>
        )
    }
}
