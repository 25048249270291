import { createApi, getAll, getAllPag, createUpdate, deleteItem, getOptions } from './baseApi'
import axios from 'axios'

const TemplatesApi = createApi('communication/templates')
const CommunicationApi = createApi('communication')

export const getAllTemplates = async (payload) => {
    return getAll(TemplatesApi, payload)
}

export const getAllTemplatesPag = async (payload) => {
    return getAllPag(TemplatesApi, payload)
}

export const createUpdateTemplate = async (payload) => {
    return createUpdate(TemplatesApi, payload)
}

export const deleteTemplate = async (payload) => {
    return deleteItem(TemplatesApi, payload)
}

export const getTemplateOptions = async (payload) => {
    return getOptions(TemplatesApi, payload)
}

const programmedWhatsappApi = createApi('communication/programmed-whatsapp')

export const createUpdateProgrammedWhatsApp = async (payload) => {
    return createUpdate(programmedWhatsappApi, payload)
}

export const getAllProgrammedWhatsappPag = async (payload) => {
    return getAllPag(programmedWhatsappApi, payload)
}
export const deleteProgrammedWhatsapp = async (payload) => {
    return deleteItem(programmedWhatsappApi, payload)
}
export const getProgrammedWhatsappOptions = async (payload) => {
    return getOptions(programmedWhatsappApi, payload)
}

export const cancelProgrammedWhatsapp = async (payload) => {
    const response = await CommunicationApi.post('/cancel-programmed-whatsapp', payload.data, {
        headers: { Authorization: payload.token }
    })
    return response.data
}

export const getOrderProgrammedWhatsapps = async (payload) => {
    if (!payload.orderId) {
        console.error('No se proporcionó un ID de la orden para obtener los whatsapp.')
        return null
    }
    let slug = ''
    if (payload.page) {
        slug = `/order/${payload.orderId}/whatsapps/?page=${payload.page}`
    }
    const response = await CommunicationApi.get(slug, {
        headers: { Authorization: payload.token }
    })

    return response.data
}
