import { Flex, Button, FormHelperText, FormControl } from '@chakra-ui/react'
// forms
import InputField from '../../../common/forms/InputField'
import SelectFieldFilter from '../../../common/forms/SelectFieldFilter'

export default function OrderItemRow ({ formik, index, arrayHelpers, items, error, order }) {
    const formikSelected = formik.values?.stock_used[index]?.item

    const findDetails = (formikSelected) => {
        if (formikSelected) {
            const item = items?.results?.find((item) => item.id === formikSelected)
            // Verifica si el item está definido antes de acceder a sus propiedades
            if (item) {
                let q = 0
                if (Number(order?.stock_used[index]?.quantity) > 0) {
                    q = Number(item.available_quantity) + Number(order.stock_used[index].quantity)
                } else {
                    q = Number(item.available_quantity)
                }
                return {
                    name: item.name,
                    unitOfMeasurement: '(' + item.unit_of_measurement + ')',
                    availableQuantity: q,
                    averageBuyPrice: item.average_buy_price
                }
            }
        }
        return {
            name: '',
            unitOfMeasurement: '',
            availableQuantity: 0,
            averageBuyPrice: 0
        }
    }

    const details = findDetails(formikSelected)

    const validateQuantity = () => {
        const availableQuantity = details.availableQuantity
        const quantity = formik.values?.stock_used[index]?.quantity

        if (quantity > Number(availableQuantity)) {
            const stockUsedErrors = formik.errors.stock_used || []
            stockUsedErrors[index] = { quantity: 'Superas la cantidad disponible' }
            formik.errors.stock_used = stockUsedErrors
            return true
        } else {
            delete formik.errors.stock_used
            return false
        }
    }

    return (
        <Flex align="start" gap="0.5em">
            <SelectFieldFilter
                label="Item"
                options={items?.results}
                name={`stock_used.${index}.item`}
                error={error?.response?.data?.item}
            />
            <FormControl>
                <InputField
                    label={`Cantidad ${details.unitOfMeasurement}`}
                    name={`stock_used.${index}.quantity`}
                    error={error?.response?.data?.quantity}
                />
                <FormHelperText color={validateQuantity() && 'red'}>
                    Máximo disponible {details.availableQuantity}
                </FormHelperText>
            </FormControl>
            <FormControl>
                <InputField
                    label="Precio de venta (€)"
                    name={`stock_used.${index}.price`}
                    error={error?.response?.data?.price}
                />
                <FormHelperText>Promedio de compra {details.averageBuyPrice}€</FormHelperText>
            </FormControl>
            <Button mt="10px" variant="danger" size="xs" onClick={() => arrayHelpers.remove(index)}>
                -
            </Button>
        </Flex>
    )
}
