import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'
import Lottie from 'lottie-react'
// comps
import clock from '../../animations/loadingClock.json'

export default function LoadingOverlay ({ isLoading }) {
    return (
        <Modal isOpen={isLoading} isCentered>
            <ModalOverlay />
            <ModalContent bg='transparent' boxShadow='none' >
                <Lottie
                    ml='100px'
                    animationData={clock}
                    loop={true}
                    style={{ width: '200px', marginLeft: '100px' }}
                />
            </ModalContent>
        </Modal>
    )
}
