import {
	Button,
	useToast,
	Flex,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerBody,
	DrawerFooter,
	useDisclosure
} from "@chakra-ui/react";
// comps
import ClientForm from "./ClientForm";
import SelectFieldWithFilter from "../../../common/forms/SelectFieldWithFilter";
import SelectFieldFilter from "../../../common/forms/SelectFieldFilter";
// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import type { FormikHelpers } from "formik";
import InputField from "../../../common/forms/InputField";
//types
import type { Car, CarInput, CarPayload, CarAxiosError } from "../../../../types/car";
import type { ClientsPaginated } from "../../../../types/client";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateCar, getCarChoices } from "../../../../api/carsApi";
import { getAllClients } from "../../../../api/clientsApi";

interface CarFormProps {
	item?: Car;
	isOpen: boolean;
	onClose: () => void;
}
interface CarChoices {
	fuel: { value: string; label: string }[];
	brands: { value: string; label: string }[];
}

export default function CarForm({ onClose, isOpen, item: car }: CarFormProps) {
	const { isOpen: isOpenClient, onOpen: onOpenClient, onClose: onCloseClient } = useDisclosure();
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data: clients, isLoading: loadingClients } = useAuthFetch(["clients"], getAllClients) as {
		data: ClientsPaginated;
		isLoading: boolean;
	};
	const { data: carChoices, isLoading: loadingCarChoices } = useAuthFetch(["carChoices"], getCarChoices) as {
		data: CarChoices;
		isLoading: boolean;
	};

	const { isLoading, mutate, error } = useAuthMutation<Car, CarPayload, CarAxiosError>({
		mutationFn: createUpdateCar,
		onSuccess: () => {
			toast({ title: "Coche creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["cars"]);
			QueryClient.refetchQueries({ queryKey: ["cars"], exact: true });
			onClose();
		},
		onError: () => {
			toast({
				title: "Ups! Ha habido un error",
				status: "error"
			});
		}
	});
	const initialValues = {
		plate: car?.plate || "",
		kms: car?.kms || 0,
		fuel: car?.fuel || "",
		brand: car?.brand || "",
		model: car?.model || "",
		client: car?.client?.id || ""
	};
	const validationSchema = Yup.object({
		plate: Yup.string().min(6, "Demasiado corta").max(19, "Demasiado larga"),
		brand: Yup.string().required("La marca es obligatoria"),
		model: Yup.string(),
		client: Yup.number().required("Debes asociarlo a un cliente"),
		kms: Yup.number().nullable().min(0, "No puede ser negativo")
	});

	const submit = (values: CarInput, actions: FormikHelpers<CarInput>) => {
		const payload: CarPayload = car
			? { data: values, slug: car.id, token: authHeader() }
			: { data: values, token: authHeader() };

		mutate(payload);
		actions.setSubmitting(false);
	};
	return (
		<>
			<ClientForm isOpen={isOpenClient} onClose={onCloseClient} />
			<Drawer isOpen={isOpen} placement='right' onClose={onClose}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>{car ? "Editar" : "Crear"} Coche</DrawerHeader>
					<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
						{(formik) => (
							<>
								<DrawerBody>
									<Flex direction='column' gap='1em' pb='2em'>
										<SelectFieldFilter
											required
											isloading={loadingClients}
											label='Cliente'
											options={clients?.results}
											name='client'
											error={error?.response?.data?.client}
										/>
										<Button
											size='sm'
											alignSelf='start'
											variant='primary'
											onClick={() => onOpenClient()}
										>
											+ Crea uno
										</Button>
										<SelectFieldFilter
											required
											label='Marca'
											isloading={loadingCarChoices}
											options={carChoices?.brands || []}
											name='brand'
											error={error?.response?.data?.brand}
										/>
										<InputField label='Modelo' name='model' error={error?.response?.data?.model} />
										<InputField
											label='Matrícula o Bastidor'
											name='plate'
											error={error?.response?.data?.plate}
										/>
										<InputField label='Kilómetros' name='kms' error={error?.response?.data?.kms} />
										<SelectFieldWithFilter
											label='Combustible'
											name='fuel'
											choices={carChoices?.fuel || []}
											error={error?.response?.data?.fuel}
										/>
									</Flex>
								</DrawerBody>
								<DrawerFooter>
									<Flex justify='right' columnGap='3' mt='3'>
										<Button variant='white' onClick={onClose}>
											Cancelar
										</Button>
										<Button
											variant='primary'
											onClick={() => formik.handleSubmit()}
											isLoading={isLoading}
										>
											Guardar
										</Button>
									</Flex>
								</DrawerFooter>
							</>
						)}
					</Formik>
				</DrawerContent>
			</Drawer>
		</>
	);
}
