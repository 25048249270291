import axios from "axios";
import { createApi, getAll, getAllPag, createUpdate, deleteItem } from "./baseApi";

const invoicesApi = createApi("invoicing/invoices");

export const getNextInvoiceID = async (payload) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}invoicing/next-invoice-id`, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};
export const getInvoiceDetail = async (uuid) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}invoicing/invoice/${uuid}`);
	return response.data;
};

export const getInvoiceOptions = async (payload) => {
	const response = await invoicesApi.options("/", {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const getAllInvoices = async (payload) => {
	return getAll(invoicesApi, payload);
};
export const getAllInvoicesPag = async (payload) => {
	return getAllPag(invoicesApi, payload);
};
export const createUpdateInvoice = async (payload) => {
	return createUpdate(invoicesApi, payload);
};
export const deleteInvoice = async (payload) => {
	return deleteItem(invoicesApi, payload);
};

const invoiceSettingsApi = createApi("invoicing/settings");

export const getInvoiceSettings = async (payload) => {
	const response = await invoiceSettingsApi.get("", {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const updateInvoiceSettings = async (payload) => {
	const response = await invoiceSettingsApi.patch(`/${payload.slug}/`, payload.data, {
		headers: {
			Authorization: payload.token,
			"Content-Type": "multipart/form-data"
		}
	});

	return response.data;
};
