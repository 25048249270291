import { useState, useEffect, useCallback } from "react";
import {
	Button,
	useToast,
	Flex,
	Text,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerBody,
	DrawerFooter
} from "@chakra-ui/react";
import moment from "moment";
// comps
import NumberStepperField from "../../../common/forms/NumberStepperField";
// forms validation
import { FORM_COLOR_CHOICES } from "../../../common/consts";
import * as Yup from "yup";
import { Formik } from "formik";
import InputField from "../../../common/forms/InputField";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateService } from "../../../../api/servicesApi";

export default function ServiceForm({ onClose, isOpen, item: service }) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();
	const [color, setColor] = useState(service ? service.color : "");
	const [hours, setHours] = useState(0);
	const [mins, setMins] = useState(0);
	const [duration, setDuration] = useState(service ? service.duration : 0);

	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateService,
		onSuccess: () => {
			toast({ title: "Servicio creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["services"]);
			QueryClient.refetchQueries("services", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const initialValues = {
		name: service?.name || "",
		color: service?.color || "grey",
		price: service?.price || 0
	};
	const validationSchema = Yup.object({
		name: Yup.string().required("El nombre es obligatorio"),
		price: Yup.number().required("El precio es obligatorio"),
		color: Yup.string()
	});

	const submit = (values) => {
		const payload = service
			? { data: { ...values, color, duration }, slug: service.id, token: authHeader() }
			: { data: { ...values, color, duration }, token: authHeader() };
		mutate(payload);
	};
	const calculateTotalDuration = useCallback(() => {
		setDuration(Number(hours * 60) + Number(mins));
	}, [hours, mins]);

	const handleChangeHours = (value) => setHours(value);
	const handleChangeMins = (value) => setMins(value);
	const parseDuration = useCallback(() => {
		if (service) {
			const formattedMinutes = moment.duration(service.duration, "minutes");
			const hours = formattedMinutes.hours();
			const days = formattedMinutes.days();
			setMins(formattedMinutes.minutes());
			if (days > 0) {
				setHours(hours + days * 24);
			} else {
				setHours(hours);
			}
		}
	}, [service]);
	useEffect(() => {
		calculateTotalDuration();
	}, [mins, hours, calculateTotalDuration]);

	useEffect(() => {
		parseDuration();
	}, [parseDuration]);

	const isDisabled = (formik) => {
		return (
			JSON.stringify(formik.errors) !== "{}" ||
			(JSON.stringify(formik.touched) === "{}" && JSON.stringify(formik.values) === "{}")
		);
	};
	const closeForm = () => {
		setColor("");
		setHours(0);
		setMins(0);
		onClose();
	};

	return (
		<>
			<Drawer isOpen={isOpen} placement='right' onClose={closeForm}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>{service ? "Editar" : "Crear"} Servicio</DrawerHeader>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values) => submit(values)}
					>
						{(formik) => (
							<>
								<DrawerBody>
									<Flex direction='column' gap='1em'>
										<InputField
											label='Nombre'
											name='name'
											required
											placeholder='Ej: Limpieza integral'
											error={error?.response?.data?.name}
										/>
										<InputField
											label='Precio (€)'
											name='price'
											error={error?.response?.data?.price}
										/>
										<Flex align='center' gap='1em'>
											<Text>Color</Text>
											<Flex bg={color} boxSize='25px' rounded='md' />
										</Flex>
										<Flex align='center' justify='space-between' h='30px'>
											{FORM_COLOR_CHOICES.map((color) => (
												<Flex
													bg={color}
													boxSize='25px'
													rounded='md'
													cursor='pointer'
													key={color}
													_hover={{ boxSize: "30px" }}
													onClick={() => setColor(color)}
												/>
											))}
										</Flex>
										<Text>Duración estimada</Text>
										<NumberStepperField
											label='Horas'
											value={hours}
											min={0}
											handleChange={handleChangeHours}
										/>
										<NumberStepperField
											label='Minutos'
											value={mins}
											max={59}
											min={0}
											step={5}
											handleChange={handleChangeMins}
										/>
									</Flex>
								</DrawerBody>
								<DrawerFooter>
									<Flex justify='right' columnGap='3' mt='3'>
										<Button variant='white' onClick={closeForm}>
											Cancelar
										</Button>
										<Button
											variant='primary'
											isDisabled={isDisabled(formik)}
											onClick={formik.handleSubmit}
											isLoading={isLoading}
										>
											Guardar
										</Button>
									</Flex>
								</DrawerFooter>
							</>
						)}
					</Formik>
				</DrawerContent>
			</Drawer>
		</>
	);
}
