import { useMemo, useState } from "react";
import { useDisclosure, Select, Flex, IconButton, Tr, Td } from "@chakra-ui/react";
import moment from "moment";
// comps
import InvoiceStatusBadge from "../InvoiceStatusBadge";
import InvoiceModal from "../forms/InvoiceModal";
import MultiInvoicePDFModal from "../pdf/MultiInvoicePDFModal";
import InvoiceTicketPDFModal from "../pdf/InvoiceTicketPDFModal";
import PopoverDelete from "../../../common/PopoverDelete";
import ResponsiveTable from "../../../common/Tables/ResponsiveTable";
import SearchInput from "../../../common/Tables/SearchInput";
import BooleanFilter from "../../../common/Tables/BooleanFilter";
// icons
import { AiOutlineEdit } from "react-icons/ai";
import { IoTicketOutline } from "react-icons/io5";
import { AiOutlineFilePdf } from "react-icons/ai";
// img
import emptyInvoices from "../../../../img/invoicing/emptyInvoices.png";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { deleteInvoice, getAllInvoicesPag } from "../../../../api/invoicesApi";

export default function InvoicesTablePag() {
	const [invoice, setInvoice] = useState();
	const [quarter, setQuarter] = useState(undefined);
	const [status, setStatus] = useState(undefined);
	const [searchQuery, setSearchQuery] = useState("");
	const [booleanFilter, setBooleanFilter] = useState("");
	const [page, setPage] = useState(1);

	const { isOpen: isOpenForm, onOpen: onOpenForm, onClose: onCloseForm } = useDisclosure();
	const { isOpen: isOpenTicketPDF, onOpen: onOpenTicketPDF, onClose: onCloseTicketPDF } = useDisclosure();
	const { isOpen: isOpenMultiPDF, onOpen: onOpenMultiPDF, onClose: onCloseMultiPDF } = useDisclosure();

	const filter = `${quarter ? `&quarter=${quarter}` : ""}${status ? `&status=${status}` : ""}${searchQuery ? `&search=${searchQuery}` : ""}${booleanFilter}`;

	const availableFilters = [
		{ label: "Con IVA", value: "taxes" },
		{ label: "Descuento", value: "discount" },
		{ label: "Pago Efectivo", value: "cash" }
	];

	const { data, isLoading } = useAuthFetch(
		["invoices", page, searchQuery, filter],
		getAllInvoicesPag,
		page,
		filter,
		true,
		searchQuery
	);
	const handleOpenTicketPDF = (invoice) => {
		setInvoice(invoice);
		onOpenTicketPDF();
	};
	const handleEditInvoice = (invoice) => {
		setInvoice(invoice);
		onOpenForm();
	};
	const handleOpenInvoice = (invoice) => {
		const url = `/facturas/${invoice.uuid}`;
		window.open(url, "_blank");
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((invoice) => (
				<Tr key={invoice.id}>
					<Td>{invoice.invoice_number}</Td>
					<Td>{invoice.client?.name}</Td>
					<Td> {moment(invoice.date).format("L")} </Td>
					<Td> {invoice.expiring_date ? moment(invoice.expiring_date).format("L") : "Sin Fecha"}</Td>
					<Td>
						{invoice.taxes} {invoice.taxes > 0 && `(${invoice.tax_display})`}
					</Td>
					<Td>
						{invoice.total} {invoice.discount > 0 && `(-${invoice.discount}%)`}{" "}
					</Td>
					<Td>
						<InvoiceStatusBadge status={invoice.status} />
					</Td>
					<Td>
						<Flex>
							<IconButton
								isDisabled={invoice.is_multi}
								icon={<AiOutlineEdit size='20px' color='#0258FF' />}
								bg='transparent'
								onClick={() => handleEditInvoice(invoice)}
							/>

							<PopoverDelete
								deleteKey={["deleteInvoice"]}
								deleteFn={deleteInvoice}
								slug={invoice?.id}
								refetchKey={["invoices"]}
							/>
							<IconButton
								icon={<AiOutlineFilePdf size='20px' color='black' />}
								bg='transparent'
								onClick={() => handleOpenInvoice(invoice)}
							/>
							<IconButton
								icon={<IoTicketOutline size='20px' color='black' />}
								bg='transparent'
								onClick={() => handleOpenTicketPDF(invoice)}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data, onOpenForm]
	);
	const isEmpty = data?.count < 1;
	return (
		<>
			<Flex justify='end' gap='1rem' align='center' w='100%' flexWrap='wrap'>
				<Select
					maxW='180px'
					value={quarter}
					placeholder='Trimestre'
					onChange={(e) => setQuarter(e.target.value)}
				>
					<option value={1}>1 Trimestre</option>
					<option value={2}>2 Trimestre</option>
					<option value={3}>3 Trimestre</option>
					<option value={4}>4 Trimestre</option>
				</Select>
				<Select maxW='180px' placeholder='Estado' value={status} onChange={(e) => setStatus(e.target.value)}>
					<option value='pending'>Pendiente</option>
					<option value='payed'>Pagada</option>
					<option value='expired'>Caducada</option>
				</Select>
				<BooleanFilter availableFilters={availableFilters} setFilter={setBooleanFilter} />
				<SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
			</Flex>
			<ResponsiveTable
				paginated
				searchQuery={searchQuery}
				data={data}
				tableData={tableData}
				isEmpty={isEmpty}
				onOpen={() => {
					setInvoice();
					onOpenForm();
				}}
				onClose={onCloseForm}
				page={page}
				setPage={setPage}
				isLoading={isLoading}
				item={{
					label: "Factura",
					headers: [
						{ key: "invoice_number", label: "Nº Factura", roundedLeft: "lg" },
						{ key: "client", label: "Cliente" },
						{ key: "date", label: "Creada" },
						{ key: "expiring_date", label: "Expira" },
						{ key: "taxes", label: "IVA(€)" },
						{ key: "total", label: "Total(€)" },
						{ key: "status", label: "Estado" }
					],
					formItem: invoice,
					isOpen: isOpenForm
				}}
				FormComponent={InvoiceModal}
				emptyImage={emptyInvoices}
			/>
			<InvoiceTicketPDFModal invoice={invoice} onClose={onCloseTicketPDF} isOpen={isOpenTicketPDF} />
			{invoice?.is_multi && (
				<MultiInvoicePDFModal invoice={invoice} onClose={onCloseMultiPDF} isOpen={isOpenMultiPDF} />
			)}
		</>
	);
}
