import { Flex, Text, Button, Box, Image, IconButton, useMediaQuery, Divider, Link, Avatar } from "@chakra-ui/react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { SkeletonText, Skeleton } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { Map } from "@vis.gl/react-google-maps";
//types
import type { BusinessProfileLive } from "../../types/business";
//compps
import { CustomMarker } from "../../components/pages/businesses/businessSettings/profile/address/CustomMarker";
import ServicesSection from "../../components/pages/businesses/businessProfile/services/ServicesSection";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import ExtraSection from "../../components/pages/businesses/businessProfile/ExtraSection";
import BusinessImageCarousel from "../../components/pages/detailingHub/BusinessImageCarousel";
//api
import { getliveLandingViewData } from "../../api/businessesApi";
import { useQuery } from "@tanstack/react-query";
//icons
import { FaTiktok, FaInstagram, FaYoutube } from "react-icons/fa";
import { TfiWorld } from "react-icons/tfi";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlinePhone, MdOutlineEmail } from "react-icons/md";

export default function BusinessProfile() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const { workshop } = useParams();

	const { data, isLoading } = useQuery<BusinessProfileLive>({
		queryKey: ["landingLiveData"],
		queryFn: () => getliveLandingViewData({ slug: workshop })
	});
	const businessData = data?.business;
	const businessExtras = data?.extras;
	const scheduleData = data?.schedule;
	const planExpired = data?.plan_expired || false;
	const iframeUrl = businessData?.iframe_url;
	const openSocial = (url: string) => {
		window.open(url, "_blank");
	};
	const contentWidth = { base: "100%", md: "700px", lg: "800px", xl: "1080px", "2xl": "1280px" };
	const mapCenter = {
		lat: Number(businessData?.address.latitude) || 40.4010219,
		lng: Number(businessData?.address.longitude) || -3.6861754
	};
	const hasAddress = businessData?.address?.formatted_address !== null;
	const hasBusinessExtras = (obj: any) => {
		return Object.values(obj).some((value) => value === true);
	};
	const navigate = useNavigate();
	if (!isMobile) {
		return (
			<>
				<Flex justify='center' gap='4em' mt='25px' pb='2em' w={contentWidth}>
					<Flex direction='column' w='100%' minW='35%'>
						<Skeleton isLoaded={!isLoading} rounded='lg'>
							<BusinessImageCarousel images={businessData?.work_images} />

							{/* businessData?.banner_image&&
                <Image
                    w='100%'
                    rounded='lg'
                    src={businessData.banner_image}
                    objectFit='cover'
                    maxH='30dvh'
                />
                */}
						</Skeleton>
						{!isLoading && data ? (
							<Flex align='center' gap='1rem' mt='1.5rem'>
								<Avatar
									src={businessData?.banner_image || ""}
									name={businessData?.name || "AT"}
									boxSize='50px'
								/>
								<Flex direction='column'>
									<Text fontSize='18px' fontWeight='bold'>
										{businessData?.name}
									</Text>
									{businessData?.address.formatted_address && (
										<Flex mt='0.5em' align='center'>
											<FaLocationDot color='#0258FF' />
											<Text fontSize='14px' ml='0.3em'>
												{businessData.address.formatted_address}
											</Text>
										</Flex>
									)}
								</Flex>
							</Flex>
						) : (
							<SkeletonText mt='2em' noOfLines={2} spacing='2' skeletonHeight='32px' />
						)}
						{!isLoading && data ? (
							<ServicesSection planExpired={planExpired} />
						) : (
							<SkeletonText mt='2em' noOfLines={6} spacing='4' skeletonHeight='2' />
						)}
						{businessExtras && hasBusinessExtras(businessExtras) && (
							<ExtraSection extras={businessExtras} />
						)}
					</Flex>
					<Skeleton isLoaded={!isLoading}>
						<Flex
							direction='column'
							maxH='85vh'
							top='100px'
							position='sticky'
							p='1.5em'
							minW='350px'
							bg='white'
							rounded='lg'
						>
							{hasAddress && (
								<Map
									style={{ width: "100%", height: "300px" }}
									zoom={14}
									defaultCenter={mapCenter}
									mapId={"bf51a910020fa25a"}
									//disableDefaultUI={true}
								>
									<CustomMarker
										position={mapCenter}
										icon={
											<Avatar
												src={businessData?.banner_image || ""}
												name={businessData?.name || "AT"}
												boxSize='40px'
											/>
										}
									/>
								</Map>
							)}
							{businessData?.phone && (
								<Flex align='center' mt='1.5em' gap='0.4em'>
									<MdOutlinePhone color='#0258FF' size='22px' />
									<Text fontWeight='600'>{businessData.phone}</Text>
								</Flex>
							)}
							{businessData?.email && (
								<Flex align='center' mt='0.5em' gap='0.4em'>
									<MdOutlineEmail color='#0258FF' size='22px' />
									<Text fontWeight='600'>{businessData.email}</Text>
								</Flex>
							)}
							<Divider mt='1em' />
							{scheduleData?.length && scheduleData.length > 0 && (
								<>
									<Flex direction='column' gap='0.5em' py='1em'>
										{scheduleData.map((businessHour) => (
											<Flex key={businessHour.id} align='center' justify='space-between' w='100%'>
												<Flex align='center' gap='0.5em'>
													<Box
														rounded='full'
														boxSize='10px'
														bg={businessHour.is_closed ? "lightgray" : "green.500"}
													/>
													<Text color={businessHour.is_closed ? "lightgray" : "black"}>
														{businessHour.day_display}
													</Text>
												</Flex>
												{businessHour.is_closed ? (
													<Text color='lightgray'>Cerrado</Text>
												) : (
													<Text>
														{businessHour.opening_time?.replace(/:\d{2}$/, "")} -{" "}
														{businessHour.closing_time?.replace(/:\d{2}$/, "")}
													</Text>
												)}
											</Flex>
										))}
									</Flex>
									<Divider />
								</>
							)}
							{(businessData?.tiktok_url || businessData?.instagram_url || businessData?.youtube_url) && (
								<>
									<Text my='1em' fontWeight='bold'>
										Redes sociales
									</Text>
									<Flex align='center' gap='1em'>
										{businessData.tiktok_url && (
											<IconButton
												aria-label='tiktok_url'
												rounded='full'
												icon={<FaTiktok size='20px' />}
												onClick={() =>
													businessData.tiktok_url && openSocial(businessData.tiktok_url)
												}
											/>
										)}
										{businessData.instagram_url && (
											<IconButton
												aria-label='instagram_url'
												rounded='full'
												icon={<FaInstagram size='18px' />}
												onClick={() =>
													businessData.instagram_url && openSocial(businessData.instagram_url)
												}
											/>
										)}
										{businessData.youtube_url && (
											<IconButton
												aria-label='youtube_url'
												rounded='full'
												icon={<FaYoutube size='18px' />}
												onClick={() =>
													businessData.youtube_url && openSocial(businessData.youtube_url)
												}
											/>
										)}
										{businessData?.web_url && (
											<IconButton
												aria-label='web_url'
												rounded='full'
												icon={<TfiWorld size='18px' />}
												onClick={() => businessData.web_url && openSocial(businessData.web_url)}
											/>
										)}
									</Flex>
								</>
							)}
						</Flex>
					</Skeleton>
				</Flex>

				<LoadingOverlay isLoading={isLoading} />
			</>
		);
	}
	return (
		<Flex w='100%' minH='100dvh' direction='column' bg='lightgrey'>
			<Flex direction='column' px='1em' w='100%' pb='1.5em' mt='1rem'>
				<BusinessImageCarousel images={businessData?.work_images} />
				{/*businessData?.banner_image&&
                <Image
                    mt='0.5em'
                    rounded='lg'
                    src={businessData.banner_image}
                    objectFit='cover'
                    maxH='30dvh'
                />
                */}
				<Text mt='1em' fontSize='20px' fontWeight='bold' maxW='100%' whiteSpace='break-spaces'>
					{businessData?.name}
				</Text>
				{businessData?.address && (
					<Flex align='center' my='0.5em'>
						<FaLocationDot color='#0258FF' />
						<Text maxW='90%' whiteSpace='break-spaces' fontSize='12px'>
							{businessData.address.formatted_address}
						</Text>
					</Flex>
				)}
				<Tabs variant='unstyled' mt='0.5em'>
					<TabList>
						<Tab
							p='0.1'
							_selected={{
								color: "#0258FF",
								borderBottom: "2px solid #0258FF"
							}}
						>
							Servicios
						</Tab>
						<Tab
							p='0.1'
							ml='2em'
							_selected={{
								color: "#0258FF",
								borderBottom: "2px solid #0258FF"
							}}
						>
							Detalles
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel p='0em' mt='1em'>
							<ServicesSection planExpired={planExpired} />
						</TabPanel>
						<TabPanel p='0em' mt='1em'>
							{iframeUrl && (
								<iframe
									src={iframeUrl}
									style={{ borderRadius: "8px" }}
									title='GoogleMapsLocation'
									width='100%'
									height='200'
									loading='lazy'
								/>
							)}
							{businessData?.phone && (
								<Flex my='1em' align='center' justify='space-between'>
									<Text fontWeight='bold'>{businessData.phone}</Text>
									<Button variant='white'>Llamar</Button>
								</Flex>
							)}
							<hr />
							{scheduleData?.length && scheduleData?.length > 0 && (
								<>
									<Flex direction='column' gap='0.5em' py='1em'>
										{scheduleData.map((businessHour) => (
											<Flex key={businessHour.id} align='center' justify='space-between' w='100%'>
												<Text>{businessHour.day_display}</Text>
												{businessHour.is_closed ? (
													<Text>Cerrado</Text>
												) : (
													<Text>
														{businessHour.opening_time?.replace(/:\d{2}$/, "")} -{" "}
														{businessHour.closing_time?.replace(/:\d{2}$/, "")}
													</Text>
												)}
											</Flex>
										))}
									</Flex>
									<hr />
								</>
							)}
							{(businessData?.tiktok_url || businessData?.instagram_url || businessData?.youtube_url) && (
								<>
									<Text my='1em' fontWeight='bold'>
										Redes sociales
									</Text>
									<Flex align='center' gap='1em'>
										{businessData.tiktok_url && (
											<IconButton
												aria-label='tiktok_url'
												rounded='full'
												icon={<FaTiktok size='20px' />}
												onClick={() =>
													businessData.tiktok_url && openSocial(businessData.tiktok_url)
												}
											/>
										)}
										{businessData.instagram_url && (
											<IconButton
												aria-label='instagram_url'
												rounded='full'
												icon={<FaInstagram size='18px' />}
												onClick={() =>
													businessData.instagram_url && openSocial(businessData.instagram_url)
												}
											/>
										)}
										{businessData.youtube_url && (
											<IconButton
												aria-label='youtube_url'
												rounded='full'
												icon={<FaYoutube size='18px' />}
												onClick={() =>
													businessData.youtube_url && openSocial(businessData.youtube_url)
												}
											/>
										)}
									</Flex>
								</>
							)}
							{businessExtras && hasBusinessExtras(businessExtras) && (
								<ExtraSection extras={businessExtras} />
							)}
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Flex>
		</Flex>
	);
}
