import { useEffect } from "react";
import { Flex, Text, Select, IconButton } from "@chakra-ui/react";
import moment from "moment";
// icons
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

export default function PeriodSelector({ period, setPeriod, year, setYear, month, setMonth, week, setWeek }) {
	moment.locale("es");
	const maxYear = 2024;
	const minYear = 2022;
	const minMonth = 1;
	const maxMonth = 12;
	const minWeek = 1;
	const maxWeek = 52;

	const handleNextPeriod = () => {
		if (period === "year") {
			if (year < maxYear) {
				setYear(year + 1);
			}
		} else if (period === "month") {
			if (month === maxMonth) {
				setMonth(minMonth);
				setYear(year + 1);
			} else {
				setMonth(month + 1);
			}
		} else if (period === "week") {
			if (week === maxWeek) {
				setWeek(minWeek);
				setYear(year + 1);
				setMonth(1);
			} else {
				setWeek(week + 1);
			}
		}
	};
	const handlePrevPeriod = () => {
		if (period === "year") {
			if (year > minYear) {
				setYear(year - 1);
			}
		} else if (period === "month") {
			if (month === minMonth) {
				setMonth(maxMonth);
				setYear(year - 1);
			} else {
				setMonth(month - 1);
			}
		} else if (period === "week") {
			if (week === minWeek) {
				setWeek(maxWeek);
				setYear(year - 1);
				setMonth(12);
			} else {
				setWeek(week - 1);
			}
		}
	};
	const formatDisplay = () => {
		if (period === "year") {
			return <Text>{year}</Text>;
		}
		if (period === "month") {
			return (
				<Text w='100px' align='center'>
					{moment()
						.month(month - 1)
						.format("MMMM")
						.charAt(0)
						.toUpperCase() +
						moment()
							.month(month - 1)
							.format("MMMM")
							.slice(1)}
				</Text>
			);
		}
		if (period === "week") {
			const startDate = moment().week(week).startOf("week");
			const endDate = moment().week(week).endOf("week");
			return (
				<Text w='160px' align='center'>
					{`${startDate.format("D")} - ${endDate.format("D MMMM")}`}
				</Text>
			);
		}
	};

	const handleChangePeriod = (e) => {
		setPeriod(e.target.value);
		setMonth(moment().month() + 1);
		setWeek(moment().week());
		setYear(moment().year());
	};

	return (
		<Flex align='center' gap='1em'>
			<Flex align='center' justify='space-between' gap='0.5'>
				<IconButton icon={<FaAngleLeft size='20px' />} onClick={handlePrevPeriod} />
				{formatDisplay()}
				<IconButton icon={<FaAngleRight size='20px' />} onClick={handleNextPeriod} />
			</Flex>
			<Select defaultValue={"month"} bg='white' color='#0258FF' onChange={handleChangePeriod}>
				<option value='year'>Año</option>
				<option value='month'>Mes</option>
			</Select>
		</Flex>
	);
}
