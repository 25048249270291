import { useState } from 'react'
import { Flex, Text, Switch, Input } from '@chakra-ui/react'

export default function MultiDiscountField ({ discount, setDiscount }) {
    const [display, setDisplay] = useState(discount > 0)

    const handleSwitch = () => {
        setDisplay(!display)
        if (display) {
            setDiscount(0)
        }
    }

    return (
        <Flex direction="column">
            <Flex align="center" gap="2em" mt="1em" h="50px">
                <Switch onChange={handleSwitch} isChecked={display} />
                <Text minW="200px">Añadir descuento (%)</Text>
            </Flex>
            {display && <Input onChange={(e) => setDiscount(e.target.value)} value={discount} w="150px" placeholder="x%" />}
        </Flex>
    )
}
