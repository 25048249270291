import { useContext } from "react";
import {
	Button,
	Flex,
	Text,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalHeader,
	ModalCloseButton
} from "@chakra-ui/react";
import moment from "moment";
import { PlanContext } from "../../../common/context/PlanContext";
// comps
import WhatsAppTemplatePreview from "./WhatsAppTemplatePreview";
import OrderWhatsappsTable from "./OrderWhatsappsTable";
// form
import InputField from "../../../common/forms/InputField";
import SelectField from "../../../common/forms/SelectField";
import * as Yup from "yup";
import { Formik } from "formik";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getProgrammedWhatsappOptions, createUpdateProgrammedWhatsApp } from "../../../../api/communicationApi";

const SendWhatsAppModal = ({ isOpen, onClose, order }) => {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();
	const { limits } = useContext(PlanContext);
	const whatsappEnabled = limits?.whatsapp_enabled;
	const { data: templates } = useAuthFetch(["whatsappTemplates"], getProgrammedWhatsappOptions);
	const templateOptions = templates?.actions?.POST?.type?.choices;

	const { isLoading, mutate } = useAuthMutation({
		mutationFn: createUpdateProgrammedWhatsApp,
		onSuccess: () => {
			toast({ title: "WhatsApp Programado!", status: "success" });
			QueryClient.invalidateQueries(["orderWhatsapps", order.id]);
			QueryClient.refetchQueries(["orderWhatsapps", order.id], { force: true });
			onClose();
		},
		onError: (error) => {
			console.log(error);
			toast({
				title: "¡Ups! Ha habido un error",
				status: "error"
			});
		}
	});
	const initialValues = {
		order: order?.id || "",
		type: "",
		send_at: undefined
	};
	const validationSchema = Yup.object({
		order: Yup.string().required("Es necesario elegir una orden"),
		send_at: Yup.date().min(
			moment().add(17, "minutes"),
			"El recordatorio no se puede programar antes de 15 minutos desde ahora"
		),
		type: Yup.string().required("Debes elegir un template")
	});

	const submit = (values) => {
		const payload = {
			data: values,
			token: authHeader()
		};
		// console.log(payload)
		mutate(payload);
	};
	const hasDepositReceipt = !!order?.receipt;
	const hasInvoice = !!order?.invoice;
	const hasQuote = !!order?.quote;

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='5xl'>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader px='3em'>
					Envío de WhatsApp a {order?.client?.name} ({order?.client?.phone})
				</ModalHeader>
				<ModalBody py='2em' px='4em' minH='400px'>
					<Flex w='100%' justify='space-between'>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={(values) => submit(values)}
						>
							{(formik) => (
								<Flex direction='column' maxW='40%'>
									<SelectField
										label='Elige el mensaje que quieres enviar'
										placeholder='Selecciona un template'
										name='type'
										choices={templateOptions}
									/>
									<WhatsAppTemplatePreview template={formik.values.type} order={order} />
									{order && !order.date_in && (
										<Text color='red' mt='1em'>
											Esta orden no tiene una fecha de entrada
										</Text>
									)}
									{order && !order?.has_signed_consent && (
										<Text color='red' mt='1em'>
											El cliente no ha firmado el consentimiento
										</Text>
									)}
									{order && !order?.client?.phone && (
										<Text color='red' mt='1em'>
											El cliente no tiene un telefono registrado
										</Text>
									)}
									{order && !hasDepositReceipt && formik.values.type === "deposit_receipt" && (
										<Text color='red' mt='1em'>
											No has creado un resguardo de depósito para esta orden
										</Text>
									)}
									{order && !hasQuote && formik.values.type === "quote" && (
										<Text color='red' mt='1em'>
											No has creado un presupuesto para esta orden
										</Text>
									)}
									{order && !hasInvoice && formik.values.type === "invoice" && (
										<Text color='red' mt='1em'>
											No has creado una factura para esta orden
										</Text>
									)}

									{!whatsappEnabled && (
										<Text color='red' mt='1em'>
											Has llegado a tu límite mensual de mensajes
										</Text>
									)}
									<Flex align='end' justify='space-between' mt='1.5em' gap='2em' w='100%'>
										<Button
											variant='white'
											isDisabled={!formik.dirty || !order?.has_signed_consent || !whatsappEnabled}
											onClick={() => {
												formik.setFieldValue("send_at", undefined);
												formik.handleSubmit();
											}}
											isLoading={isLoading}
										>
											Enviar ahora
										</Button>
										<Flex direction='column' gap='0.5em' maxW='60%'>
											<Text fontSize='12px'>O selecciona una fecha de envío</Text>
											<InputField name='send_at' type='datetime-local' />
											<Button
												variant='primary'
												isLoading={isLoading}
												onClick={formik.handleSubmit}
												isDisabled={
													!formik.dirty ||
													!formik.values.send_at ||
													!order?.has_signed_consent ||
													!whatsappEnabled
												}
											>
												Programar
											</Button>
										</Flex>
									</Flex>
								</Flex>
							)}
						</Formik>
						{order ? (
							<Flex direction='column' gap='1em' maxW='60%'>
								<Text fontSize='18px' fontWeight='bold'>
									Historial de mensajes para esta orden
								</Text>
								<OrderWhatsappsTable order={order} isOpen={isOpen} />
							</Flex>
						) : (
							<Text>Selecciona una orden para ver el historial</Text>
						)}
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default SendWhatsAppModal;
