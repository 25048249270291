import { Flex, Text, Button, Image, Box, useDisclosure } from "@chakra-ui/react";
// comps
import LoadingSpinner from "../../common/LoadingSpinner";
import OrderForm from "./forms/OrderForm";
import OrderCard from "./card/OrderCard";
// img
import helperAT from "../../../img/dashboard/helperAT.png";
// api
import useAuthQuery from "../../../myHooks/useAuthQuery";
import { getLatestOrders } from "../../../api/ordersApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function LatestOrderCardList() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const authHeader = useAuthHeader();

	const { data: orders, isLoading } = useAuthQuery({
		queryKey: ["latestorders"],
		queryFn: () => getLatestOrders({ number: "5", auth: authHeader() })
	});
	const isEmpty = orders?.count < 1;
	return (
		<Flex w='100%' h='100%' direction='column' align='center'>
			{!isEmpty ? (
				<>
					<Flex
						direction='column'
						w='100%'
						pr='0.5em'
						gap='1rem'
						sx={{
							flexGrow: 1,
							overflowY: "auto",
							maxH: "95vh",
							"&::-webkit-scrollbar": { width: "5px" },
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#f5f5f5"
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#cfcfcf"
							}
						}}
					>
						<Flex justify='space-between' align='center' w='100%'>
							<Text fontSize='20px' fontWeight='bold'>
								Últimas órdenes
							</Text>
							<Button variant='primary' size='xs' onClick={() => onOpen()}>
								+
							</Button>
						</Flex>
						{orders?.results.map((order) => (
							<OrderCard key={order.id} order={order} />
						))}
					</Flex>
				</>
			) : (
				<>
					<Flex justify='space-between' align='center' my='0.5em' w='100%'>
						<Text mb='0.5rem' fontSize='20px' fontWeight='bold'>
							Últimas órdenes
						</Text>
						<Button variant='primary' onClick={() => onOpen()}>
							+ Orden
						</Button>
					</Flex>
					<Flex w='100%' h='100%' bg='white' rounded='lg' justify='center' align='center' gap='2em'>
						<Text maxW='70%' align='center'>
							No tienes ninguna solicitud de trabajo nueva.
						</Text>
					</Flex>
				</>
			)}
			{isLoading && <LoadingSpinner />}
			<OrderForm isOpen={isOpen} onClose={onClose} />
		</Flex>
	);
}
