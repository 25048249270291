import { useState, useEffect } from 'react'
import { AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Text, Flex, Button } from '@chakra-ui/react'

// comps
import MultiInvoiceItems from './MultiInvoiceServices'
import StripeModalButton from '../../../payments/StripeModalButton'
import MultiUsedStock from './MultiUsedStock'
// auth
import { useAuthUser } from 'react-auth-kit'

export default function MultiInvoiceItem ({ order }) {
    const auth = useAuthUser()
    const isFree = auth().plan === 'FREE'
    const [orderSubtotal, setOrderSubtotal] = useState(0)

    const calculateOrderSubtotal = () => {
        const hasServices = order.services?.length > 0
        const hasStockUsed = order.stock_used?.length > 0
        let subtotal = 0
        if (hasServices) {
            order.services.forEach((service) => (
                subtotal += parseFloat(service.price)
            ))
        }
        if (hasStockUsed) {
            order.stock_used.forEach((stock) => (
                subtotal += parseFloat(stock.total)
            ))
        }
        setOrderSubtotal(subtotal)
    }
    useEffect(() => {
        if (order) {
            calculateOrderSubtotal()
        }
    }, [order])

    if (order) {
        return (
            <AccordionItem
                bg="#F7F7F7"
                mt='1.5em'
                px="0.5em"
                pt='0.5em'
                rounded="xl"
                border="1px solid #D9D9D9"
                key={order.id}
            >

                <AccordionButton _expanded={{ textColor: '#0258FF' }} >
                    <Box as="span" flex='1' textAlign='left' fontSize='18px' fontWeight='bold'>
                        Orden #{order.id}
                    </Box>
                    <Box as="span" flex='1' textAlign='right' mr='1em' fontSize='18px' fontWeight='bold'>
                        Total: {orderSubtotal}€
                    </Box>
                    <AccordionIcon boxSize='30px' />
                </AccordionButton>
                <AccordionPanel>
                    <MultiInvoiceItems
                        services={order.services}
                    />
                    {isFree
                        ? (
                            <Flex direction="column" bg="lightgrey" rounded="lg" mt="2em" px="1em">
                                <StripeModalButton type="blue" mb="-1em" />
                                <Flex justify="space-between" align="center" mt="1em">
                                    <Text fontWeight="bold" py="1em" color="grey">
                                    Recambios utilizados
                                    </Text>
                                    <Button bg="gray" color="lightgrey" size="sm" isDisabled>
                                    + Recambio
                                    </Button>
                                </Flex>
                            </Flex>
                        )
                        : (
                            <MultiUsedStock stock_used={order?.stock_used}/>
                        )}
                    <MultiInvoiceItem/>
                </AccordionPanel>
            </AccordionItem>
        )
    }
}
