import {
	Button,
	useToast,
	Switch,
	Flex,
	Text,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerBody,
	DrawerFooter
} from "@chakra-ui/react";
// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import InputField from "../../../../common/forms/InputField";
import SelectField from "../../../../common/forms/SelectField";
// api
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../../myHooks/useAuthMutation";
import { getBusinessHoursOptions, createUpdateBussinessHours } from "../../../../../api/businessesApi";
import { useQueryClient } from "@tanstack/react-query";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function BusinessHoursForm({ onClose, isOpen, businessHour }) {
	const authHeader = useAuthHeader();
	const toast = useToast();
	const QueryClient = useQueryClient();

	const { data: businessHoursOptions } = useAuthFetch(["dayChoices"], getBusinessHoursOptions);
	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateBussinessHours,
		onSuccess: () => {
			toast({ title: "Creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["landingBusinessHours"]);
			QueryClient.refetchQueries("landingBusinessHours", { force: true });
			onClose();
		},
		onError: (error) => {
			console.log(error);
			toast({
				title: "¡Ups!, ha habido un error",
				status: "error"
			});
		}
	});

	const dayChoices = businessHoursOptions?.actions?.POST?.day?.choices;

	const initialValues = {
		day: businessHour?.day || "",
		is_closed: businessHour?.is_closed || false,
		opening_time: businessHour?.opening_time || undefined,
		closing_time: businessHour?.closing_time || undefined
	};
	const validationSchema = Yup.object({
		day: Yup.string().required("El día es obligatorio"),
		isClosed: Yup.boolean(),
		opening_time: Yup.string().when("is_closed", {
			is: false,
			then: Yup.string()
				.required("Obligatorio")
				.matches(/^(?:[0-1]\d|2[0-3]):(?:[0-5]\d)(?::[0-5]\d)?$/, "Formato no válido"),
			otherwise: Yup.string().notRequired()
		}),
		closing_time: Yup.string().when("is_closed", {
			is: false,
			then: Yup.string()
				.required("Obligatorio")
				.matches(/^(?:[0-1]\d|2[0-3]):(?:[0-5]\d)(?::[0-5]\d)?$/, "Formato no válido"),
			otherwise: Yup.string().notRequired()
		})
	});

	const handleSubmit = (values) => {
		const payload = businessHour
			? { data: values, slug: businessHour.id, token: authHeader() }
			: { data: values, token: authHeader() };
		mutate(payload);
	};

	return (
		<Drawer isOpen={isOpen} placement='right' onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader>{businessHour ? "Editar" : "Crear"} Servicio</DrawerHeader>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => handleSubmit(values)}
				>
					{(formik) => (
						<>
							<DrawerBody>
								<Flex direction='column' gap='1em'>
									<SelectField label='Día' name='day' choices={dayChoices} />
									<Flex align='center' gap='0.5em'>
										<Text>¿Cerrado?</Text>
										<Switch
											name='is_closed'
											colorScheme='blue'
											isChecked={formik.values.is_closed}
											value={formik.values.is_closed}
											onChange={() => formik.setFieldValue("is_closed", !formik.values.is_closed)}
										/>
									</Flex>
									{!formik.values.is_closed && (
										<>
											<InputField
												label='Hora de apertura'
												name='opening_time'
												type='time'
												error={error?.response?.data?.opening_time}
											/>
											<InputField
												label='Hora de cierre'
												name='closing_time'
												type='time'
												error={error?.response?.data?.closing_time}
											/>
										</>
									)}
								</Flex>
								{error?.response?.data?.non_field_errors && (
									<Text color='red' mt='2em'>
										{error.response?.data?.non_field_errors[0]}
									</Text>
								)}
							</DrawerBody>
							<DrawerFooter>
								<Flex justify='right' columnGap='3' mt='3'>
									<Button variant='white' onClick={onClose}>
										Cancelar
									</Button>
									<Button
										variant='primary'
										isDisabled={!formik.dirty}
										onClick={formik.handleSubmit}
										isLoading={isLoading}
									>
										Guardar
									</Button>
								</Flex>
							</DrawerFooter>
						</>
					)}
				</Formik>
			</DrawerContent>
		</Drawer>
	);
}
