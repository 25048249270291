import { useEffect, useState } from "react";
import {
	useMediaQuery,
	useToast,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton
} from "@chakra-ui/react";

// comps
import MultiInvoiceForm from "./MultiInvoiceForm";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { getAllClients } from "../../../../api/clientsApi";
import { getInvoiceOptions, createUpdateInvoice, getNextInvoiceID } from "../../../../api/invoicesApi";
// auth
import { useAuthHeader, useAuthUser } from "react-auth-kit";

export default function MultiInvoiceModal({ orders, isOpen, onClose }) {
	const authHeader = useAuthHeader();
	const auth = useAuthUser();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const QueryClient = useQueryClient();
	const toast = useToast();
	const [total, setTotal] = useState(0);
	const [taxes, setTaxes] = useState(0);
	const [subtotal, setSubtotal] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [withTaxes, setWithTaxes] = useState(true);
	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateInvoice,
		onSuccess: () => {
			toast({ title: "Factura actualizada!", status: "success" });
			QueryClient.invalidateQueries(["invoices"]);
			QueryClient.refetchQueries("invoices", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const { data: clients } = useAuthFetch(["clients"], getAllClients, null, null, !!isOpen);
	const { data: nextInvoiceID } = useAuthFetch(["invoiceID"], getNextInvoiceID, null, null);
	const { data: options } = useAuthFetch(["invoiceOptions"], getInvoiceOptions, null, null, !!isOpen);
	const createPayload = (values) => {
		return {
			data: values,
			token: authHeader()
		};
	};
	const submit = (values) => {
		const payload = createPayload(values);
		payload.data.total = total;
		payload.data.taxes = taxes;
		payload.data.is_multi = true;
		payload.data.discount = (total * discount) / 100;
		mutate(payload);
	};
	const calculateTotals = () => {
		let totalLocal = 0;
		let discountLocal = 0;
		orders.forEach((order) => {
			const hasServices = order.services?.length > 0;
			const hasStockUsed = order.stock_used?.length > 0;
			let subtotal = 0;
			if (hasServices) {
				order.services.forEach((service) => (subtotal += parseFloat(service.price)));
			}
			if (hasStockUsed) {
				order.stock_used.forEach((stock) => (subtotal += parseFloat(stock.total)));
			}
			totalLocal += subtotal;
		});
		if (discount > 0) {
			discountLocal = (totalLocal * discount) / 100;
		}
		const taxesLocal = (totalLocal * 0.21).toFixed(2);
		const subtotalLocal = (totalLocal - taxesLocal).toFixed(2);
		setTaxes(taxesLocal);
		setSubtotal(subtotalLocal);
		setTotal(totalLocal - discountLocal);
	};
	useEffect(() => {
		calculateTotals();
	}, [discount, orders]);

	const closeModal = () => {
		setTotal(0);
		setDiscount(0);
		onClose();
	};

	const isFree = auth().plan === "FREE";
	return (
		<Modal isOpen={isOpen} onClose={closeModal} size='6xl'>
			<ModalOverlay />
			<ModalContent bg='white' h='80vh'>
				<ModalHeader>
					<Text>Crear Factura Múltiple</Text>
				</ModalHeader>
				<ModalCloseButton />

				<ModalBody>
					<MultiInvoiceForm
						nextInvoiceID={nextInvoiceID}
						submit={submit}
						clients={clients}
						options={options}
						isLoading={isLoading}
						orders={orders}
						withTaxes={withTaxes}
						setWithTaxes={setWithTaxes}
						total={total}
						setTotal={setTotal}
						subtotal={subtotal}
						taxes={taxes}
						discount={discount}
						setDiscount={setDiscount}
						isFree={isFree}
						error={error}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
