import React, {useState} from 'react';
import { Avatar } from '@chakra-ui/react';
import {
    AdvancedMarker,
    InfoWindow,
    useAdvancedMarkerRef
} from '@vis.gl/react-google-maps';

export const CustomMarker = ({position, icon}) => {
    const [infowindowOpen, setInfowindowOpen] = useState(false);
    const [markerRef, marker] = useAdvancedMarkerRef();

    return (
    <>
        <AdvancedMarker
            ref={markerRef}
            onClick={() => setInfowindowOpen(!infowindowOpen)}
            position={position}
        >
            {icon&&
                icon
            }
        </AdvancedMarker>
        {infowindowOpen && (
            <InfoWindow
                anchor={marker}
                maxWidth={200}
                onCloseClick={() => setInfowindowOpen(false)}>
                Anothertool Detailing Center
            </InfoWindow>
        )}
    </>
    );
};