import { FormControl, FormErrorMessage, FormHelperText, Input, FormLabel, Flex } from "@chakra-ui/react";
import { Field, useField } from "formik";

interface InputFieldProps {
	name: string;
	label: string;
	icon?: React.ReactNode;
	hint?: string;
	required?: boolean;
	error?: string;
}

export default function InputField({ name, label, icon, hint, required, error, ...props }: InputFieldProps) {
	// hook de formik para obtener el field data y los errors y touched de cada field
	const [field, meta] = useField(name);
	return (
		<FormControl isInvalid={(meta.error && meta.touched) || !!error} isRequired={required}>
			<Flex gap='0.5em' align='start'>
				{icon}
				<FormLabel color='black'>{label}</FormLabel>
			</Flex>
			<Field
				h='40px'
				as={Input}
				{...field} // aqui van los props de onblur, on change
				{...props} // props que pasamos como placeholder etc
			/>
			<FormHelperText>{hint}</FormHelperText>
			<FormErrorMessage> {meta.error} </FormErrorMessage>
			<FormErrorMessage> {error} </FormErrorMessage>
		</FormControl>
	);
}
