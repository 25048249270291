import {
	Flex,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useMediaQuery
} from "@chakra-ui/react";
// comps
import LoadingSpinner from "../../../common/LoadingSpinner";
import CardHistoryCard from "./CarHistoryCard";
//types
import type { Order } from "../../../../types/order";
import type { Car } from "../../../../types/car";
// icons
import { FiUser } from "react-icons/fi";
import { BiCar } from "react-icons/bi";
import { MdOutlineMail, MdOutlinePhone } from "react-icons/md";
// api
import useAuthQuery from "../../../../myHooks/useAuthQuery";
import { getCarHistory } from "../../../../api/carsApi";
// auth
import { useAuthHeader } from "react-auth-kit";

interface CarHistoryModalProps {
	car: Car;
	isOpen: boolean;
	onClose: () => void;
}

export default function CarHistoryModal({ car, isOpen, onClose }: CarHistoryModalProps) {
	const authHeader = useAuthHeader();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const { data, isLoading } = useAuthQuery<Order[]>({
		queryKey: ["carHistory", car?.id],
		queryFn: () => getCarHistory({ token: authHeader(), carId: car?.id }),
		enabled: !!car && isOpen
	});

	const parsePhone = (phone: string) => {
		return phone.split("+34")[1] || phone;
	};
	return (
		<Modal isOpen={isOpen} onClose={onClose} size={isMobile ? "full" : "4xl"}>
			<ModalOverlay />
			<ModalContent bg='white' maxH='90vh'>
				<ModalHeader>Historial del vehiculo {car?.plate} </ModalHeader>
				<ModalCloseButton />

				<ModalBody p={isMobile ? "1em" : "2em"}>
					{car && !isMobile && (
						<Flex pb='1em' align='end' justify='space-between'>
							<Flex direction='column' gap='0.5em'>
								<Flex gap='0.5em'>
									<BiCar size='20px' color='black' />
									<Text>
										{car.brand} {car.model}
									</Text>
								</Flex>
								<Flex gap='0.5em'>
									<FiUser size='20px' color='black' />
									<Text maxW='200px' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
										{car.client?.name || "-- --"}{" "}
									</Text>
								</Flex>
							</Flex>
							<Flex gap='0.5em'>
								<MdOutlineMail size='20px' color='black' />
								<Text>{car.client?.email || "-- --"}</Text>
							</Flex>
							<Flex gap='0.5em'>
								<MdOutlinePhone size='20px' color='black' />
								<Text>{car?.client?.phone ? parsePhone(car?.client?.phone) : "-- --"}</Text>
							</Flex>
						</Flex>
					)}
					{isMobile && (
						<Flex pb='1em' direction='column' gap='0.5em'>
							<Flex gap='0.5em'>
								<BiCar size='20px' color='black' />
								<Text>
									{car.brand} {car.model}
								</Text>
							</Flex>
							<Flex gap='0.5em'>
								<FiUser size='20px' color='black' />
								<Text maxW='200px' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
									{car.client?.name || "-- --"}
								</Text>
							</Flex>
							<Flex gap='0.5em'>
								<MdOutlineMail size='20px' color='black' />
								<Text>{car.client?.email || "-- --"}</Text>
							</Flex>
							<Flex gap='0.5em'>
								<MdOutlinePhone size='20px' color='black' />
								<Text>{car?.client?.phone ? parsePhone(car?.client?.phone) : "-- --"}</Text>
							</Flex>
						</Flex>
					)}
					{isLoading && <LoadingSpinner />}
					{data && typeof data === "object" && Object.keys(data).length > 0 ? (
						<Flex
							direction='column'
							gap='1em'
							maxH='60vh'
							pr='1em'
							overflowY='scroll'
							sx={{
								paddingRight: "0.2rem",
								"&::-webkit-scrollbar": { width: "5px", height: "8px" },
								"&::-webkit-scrollbar-track": {
									backgroundColor: "#f5f5f5"
								},
								"&::-webkit-scrollbar-thumb": {
									backgroundColor: "#cfcfcf"
								}
							}}
						>
							{data?.map((order) => (
								<CardHistoryCard order={order} key={order.id} />
							))}
						</Flex>
					) : (
						<Text>Éste coche no ha venido al taller</Text>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
