import { Tag, TagLabel } from '@chakra-ui/react'

export default function OrderReminderStatusView ({ status }) {
    const statusMap = {
        cancelled: { bg: 'red.500', display: 'Cancelada' },
        confirmed: { bg: 'green.500', display: 'Confirmada' }
    }

    const { bg, display } = statusMap[status] || {}

    if (status) {
        return (
            <Tag bg={bg} rounded="xl" p="0.3em" maxW="85px" >
                <TagLabel color="white" fontSize="12px">
                    {display}
                </TagLabel>
            </Tag>
        )
    }
}
