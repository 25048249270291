import { Flex, Text } from '@chakra-ui/react'

export default function PanelDescriptionBox () {
    return (
        <Flex w="100%" h="100%" direction="column" gap="1em">
            <Text fontWeight="bold" fontSize="24px">
                Panel de recordatorios por WhatAapp
            </Text>
            <Text>
                Para enviar recordatorios por whatsapp es necesario que los clientes lo consientan explícitamente a
                través del formulario que se encuentra desde la misma orden, o en la ficha del cliente
            </Text>
            <Text fontWeight="bold">A la derecha puedes previsualizar los mensajes que se van a enviar al cliente</Text>
        </Flex>
    )
}
