import { Badge } from "@chakra-ui/react";

export default function UserPlanDisplay({ plan }) {
	const planColor = {
		FREE: {
			bg: "transparent",
			color: "blue.500"
		},
		TRIAL: {
			bg: "orange",
			color: "white"
		},
		PRO: {
			bg: "blue.500",
			color: "white"
		},
		BETA: {
			bg: "purple.500",
			color: "white"
		},
		MASTER: {
			bg: "pink.500",
			color: "white"
		}
	};

	return (
		<Badge rounded='xl' px='0.5em' bg={planColor[plan]?.bg || "grey"} color={planColor[plan]?.color || "gray"}>
			{plan}
		</Badge>
	);
}
