import {
    Text, Flex,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper
} from '@chakra-ui/react'

export default function NumberStepperField ({ value, handleChange, label, ...props }) {
    return (
        <Flex gap="1em" align="center">
            <Text>{label}</Text>
            <NumberInput size="md" maxW="75" value={value} onChange={handleChange} {...props}>
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </Flex>
    )
}
