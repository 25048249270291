import Select from "react-select";
import makeAnimated from "react-select/animated";
// api
import useAuthFetch from "../../../myHooks/useAuthFetch";
import { getWorkers } from "../../../api/businessesApi";

const animatedComponents = makeAnimated();

export default function OrderWorkerFilter({ setSelectedWorkers, ...props }) {
	const { data: workers } = useAuthFetch(["workers"], getWorkers);

	const handleChange = (options) => {
		const selectedValues = options?.map((option) => option.value);
		setSelectedWorkers(selectedValues);
	};
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			//minWidth: "150px",
			background: state.selectProps.value?.color || "white",
			borderRadius: "5px",
			borderColor: state.isFocused ? "black" : provided.borderColor
		}),
		multiValue: (provided, state) => ({
			...provided,
			background: state.data.color || "FAFAFA",
			borderRadius: "5px"
		}),
		borderColor: "red"
	};

	const formatData = workers?.results?.map((worker) => {
		const { id, name, color } = worker;
		return { value: id, label: name, color };
	});
	return (
		<div style={{ minWidth: "220px" }}>
			<Select
				placeholder='Filtra por detailer'
				styles={customStyles}
				components={animatedComponents}
				isMulti
				noOptionsMessage={() => "No hay detailers"}
				options={formatData}
				onChange={handleChange}
				{...props}
			/>
		</div>
	);
}
