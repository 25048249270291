import { Tag, TagLabel } from "@chakra-ui/react";

export default function MessageStatusView({ status }) {
	const statusMap = {
		pending: { color: "#737A87", bg: "#F7F7F7", display: "Pendiente" },
		canceled: { color: "white", bg: "#db8a8a", display: "Cancelado" },
		undelivered: { color: "white", bg: "#db8a8a", display: "No Entregado" },
		failed: { color: "white", bg: "#db8a8a", display: "Fallido" },
		delivered: { color: "#42C74F", bg: "#42C74F1A", display: "Entregado" },
		read: { color: "#42C74F", bg: "#42C74F1A", display: "Leído" },
		scheduled: { color: "#FF7C02", bg: "#FEEBC8", display: "Programado" },
		sent: { color: "#737A87", bg: "#F7F7F7", display: "Enviado" },
		accepted: { color: "#737A87", bg: "#F7F7F7", display: "Aceptado" }
	};

	const { color, bg, display } = statusMap[status] || {};
	return (
		<Tag bg={bg} rounded='xl' p='0.5em' maxW='100px'>
			<TagLabel fontSize='12px' color={color}>
				{display}
			</TagLabel>
		</Tag>
	);
}
